.contact-container {
  @include set-background-neutral;
  color: $white;
  .logo-container {
    padding-top: 5vh;
    display: block;
    .logo {
      max-width: 700px;
    }
  }
  .contact {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 90%;
    margin: 0 auto;
    background-color: $bgiconscolor;
    border-radius: 30px;
    padding: 20px;
    margin-top: 20px;
    border: 2px solid $fontcolor1;
    text-align: center;
    h1 {
      color: $white;
      font-size: 2rem;
      text-align: center;
    }
    .QRCode {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      img {
        max-width: 300px;
        border: 2px solid $fontcolor1;
        border-radius: 10px;
        padding: 10px;
        background-color: $bgiconscolor;
@include respond-to(small) {
          max-width: 250px;
        }
      }
    }
    a {
      display: block;
      color: $white;
      font-size: 1.2rem;
      &:hover {
        color: $fontcolor1;
      }
      i {
        font-size: 2rem;
        transform: translate(0, 5px);
      }
    }
  }
}