.admin-content-city-container {
  @include setup-admin;
  .admin-content {
    h2 {
      font-size: 2rem;
      margin: 20px auto;
      padding: 10px;
      width: fit-content;
      color: $fontcolor1;
      border: 2px solid $white;
      border-radius: 10px;
    }
    .selector-container {
      width: 50%;
      margin: 0 auto;
    }
    form {
      h3 {
        font-size: 1.5rem;
        margin: 20px auto;
        padding: 10px;
        width: fit-content;
        color: $fontcolor1;
        border: 2px solid $white;
        border-radius: 10px;
      }
      .link-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        margin: 20px auto;
        width: 90%;
        label {
          font-size: 1.2rem;
          color: $white;
          margin: 0 10px;
        }
        input {
          grid-column: 2/4;
          width: 90%;
          margin: 0 auto;
          padding: 5px;
          border-radius: 5px;
          background-color: $fontcolor1;
          border: $borderWhite;
          color: $black;
        }
      }
      .label-guided-tour-magazine {
        display: flex;
        .label-btn {
          display: block;
          width: fit-content;
          margin: 20px auto;
          padding: 10px;
          background-color: $fontcolor1;
          color: $black;
          font-size: large;
          border: 2px solid $white;
          border-radius: 10px;
          cursor: pointer;
          transition: 0.2s ease;

          &:hover {
            background-color: $fontcolor1H;
            transform: scale(1.1);
          }
        }
      }
      input[type="submit"] {
        display: block;
        width: 50%;
        margin: 20px auto;
        background-color: $fontcolor1;
        color: $black;
        font-size: large;
        border: 2px solid $white;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
        transition: 0.2s ease;
        &:hover {
          background-color: $fontcolor1H;
          transform: scale(1.1); 
        }
      }
    }
    section.guided-tour-overview {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .guided-tour {
        display: flex;
        align-items: center;
        margin: 15px;
        padding: 10px;
        background-color: $fontcolor1;
        border: $borderWhite;
        border-radius: 10px;
        img {
          width: 200px;
          height: 150px;
          object-fit: cover;
          margin-right: 5px;
        }
      }
    }

    section.magazine-overview {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      .magazine {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 15px 0;
        padding: 10px;
        background-color: $fontcolor1;
        border: $borderWhite;
        border-radius: 10px;
        width: 80%;
        height: 150px;
        background-size: cover;
        background-position: center 60%;
        color: $white;
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 10px;
        }
        .name-mag {
          display:flex;
          flex-direction: column;
          p {
            position: relative;
            z-index: 1;
          }
        }
      }
    }
  }
}