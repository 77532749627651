.logo {
  max-width: 350px;
  max-height: 100%;
  padding-top: 20px;
  margin: 0 auto 20px;
  @include respond-to(small) {
    padding-top: 70px;
  }
  img {
    width: 100%;
    max-width: 100%;
  }
}