.streets-index-container {
  color: $white;
  height: 100vh;
  .streets-index-page-container{
    position:absolute;
    top: 0;
    min-height: 97vh;
    width: 100%;
    .streets-index-content {
      background: $bgiconscolor;
      width: 90%;
      margin: 3vh auto;
      padding: 20px;
      border-radius: 30px;
      border: $borderWhite;
      @include respond-to(small) {
        width: 95%;
        padding: 5px;
      }
      h1 {
        text-align: center;
        font-size: 3rem;
        @include respond-to(small) {
          font-size: 1.5rem;
        }
      }
      form {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 50px;
        margin: 20px 0;
        @include respond-to(small) {
          flex-direction: column;
          gap: 10px;
        }
        label {
          font-size: 1.5rem;
          color: $fontcolor1;
          @include respond-to(small) {
            font-size: 1.2rem;
          }
        }
        input {
          width: 300px;
          height: 50px;
          font-size: 1.5rem;
          padding: 0 10px;
          border: $borderWhite;
          border-radius: 10px;
          background: $fontcolor1;
          color: $white;
          transition: 0.3s ease;
          &::placeholder{
            color: $white;
          }
          &:focus {
            outline: none;
            background: $fontcolor1H;
          }
          @include respond-to(small) {
            width: 85%;
            height: 40px;
            font-size: 1.2rem;
          }
        }
      }
      .results-streets {
        text-align: center;
        h2 {
          font-size: 2rem;
          @include respond-to(small) {
            font-size: 1.5rem;
          }
        }
        ul {
          width: 90%;
          margin: 20px auto;
          display: flex;
          flex-direction: column;
          gap: 20px;
          align-items: center;
          @include respond-to(small) {
            width: 97%;
            gap: 10px;
          }
          li {
            text-align: center;
            width: 50%;
            @include respond-to(small) {
              width: 90%;
            }
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              background: $fontcolor1;
              color: $black;
              height: 100%;
              width: 100%;
              min-height: 60px;
              border: $borderWhite;
              border-radius: 20px;
              transition: 0.2s ease;
              &:hover {
                background: $fontcolor1H;
                transform: scale(1.1);
              }
            }
            p {
              font-size: 1.5rem;
              background: $fontcolor1;
              border: $borderWhite;
              border-radius: 20px;
              color: $black;
              @include respond-to(small) {
                font-size: 1.2rem;
              }
            }
          }
        }
      }
    }
    section.iframe-container {
      text-align: center;
      iframe {
        width: 90%;
        height: 500px;
        border: $borderWhite;
        border-radius: 20px;
      }
    }
  }
}