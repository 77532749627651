#pj-partner {
    padding: 1em 3em 8em 4em;
    .title-form {
        font-size: 2.6em;
        padding: 20px 32px;
    }
    .pj-search {
        form {
            padding: 38px 32px 28px 32px;
            &:after {
                content: "";
                clear: both;
                display: table;
            }
        }

        input[type=text] {
            padding: 10px 20px;
            float: left;
            &.what,
            &.where {
                border-radius: $motorheight 0 0 $motorheight;
            }
            &.what {
                width: calc(40% - 2px);
                margin-bottom: 0;
            }
            &.where {
                width: calc(60% - 2px);
                border-radius: 0 $motorheight $motorheight 0;
            }
        }
        button {
            right: 36px;
            top: 39px;
            bottom: inherit;
            &:hover {
                background-color: darken($blue, 15%);
                transition: .2s;
            }
        }
    }
}