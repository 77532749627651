.home-container {
  min-height: 97vh;
  .logo-container {
    padding-top: 5vh;
    display: block;
    .logo {
      max-width: 700px;
    }
  }
  .nav-container {
    margin-top: 100px;
    @include respond-to(small) {
      margin-top: 8vh;
    }
    nav.nav-categories {
      ul {
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: fit-content;
        @include respond-to(small) {
          grid-template-columns: 1fr 1fr;
        }
        li {
          margin: 20px 80px;
          text-align: center;
          @include respond-to(small) {
            margin: 10px 20px;
          }
          a{
            padding: 10px;
            font-size: 30px;
            font-family: $mainFont;
            height: 100%;
            color: $fontcolor1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background-color: $bgiconscolor;
            border-radius: 30px;
            border: 2px solid $fontcolor1;
            transition: 0.2s ease;
            @include respond-to(small) {
              font-size: 20px;
            }
            &:hover {
              color: $fontcolor1H;
              border-color: $fontcolor1H;
              background-color: $bgiconscolorH;
              transform: scale(1.1);
            }
            i {
              color: $white;
              font-size: 5rem;
              @include respond-to(small) {
                font-size: 3rem;
              }
            }
            .bi {
              font-size: 3.5rem;
              @include respond-to(small) {
                font-size: 2rem;
              }
            }
          }
        }
      }
    }
  }
}