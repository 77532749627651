.connection-container {
  @include set-background-neutral;
  background: url("../assets/images/background-earth.jpg") center/cover;
  .logo-container {
    img {
      width: 100%;
      max-width: 800px;
      margin: 10px auto;
      display: block;
      border-radius: 20px;
      border: 2px solid $white;
      background-color: $bgiconscolor;
    }
  }
  .content-container {
    width: fit-content;
    margin: 0 auto;
    padding: 30px;
    border: 2px solid $white;
    border-radius: 20px;
    background-color: $fontcolor1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
      font-size: 4rem;
    }
    .connect-form-container {
      width: 100%;
      form {
        display: flex;
        flex-direction: column;
        input {
          font-size: large;
          margin: 20px 0;
          padding: 5px;
          width: 100%;
          height: 40px;
          background-color: $white;
          border: 2px solid $fontcolor1H;
          border-radius: 10px;
          &::placeholder {
            color: black;
          }
          &:focus {
            outline: none;
            border: 2px solid $black;
          }
          &[type="submit"] {
            transition: 0.2s ease;
            cursor: pointer;
            &:hover {
              background-color: $fontcolor1H;
              border: 2px solid $black;
              transform: scale(1.05);
            }
          }
        }
      }
    }
  }
}