.header-admin-container {
  h1 {
    position: absolute;
    color: $fontcolor1;
  }
  img {
    width: 100%;
    max-width: 700px;
    margin: 10px auto;
    display: block;
    border-radius: 20px;
    border: 2px solid $white;
    background-color: $bgiconscolor;
  }
}