.guided-tour-list-container {

  header {
    z-index: 10;
    position: relative;
  }

  h1 {
    color: $white;
    font-size: 2rem;
    text-align: center;
    @include respond-to(small) {
      font-size: 1.5rem;
    }
  }
  @include set-background-neutral;
  .guided-tour-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 95%;
    margin: 0 auto;
    @include respond-to(small) {
      grid-template-columns: 1fr;
    }
    .guided-tour-list-entry {
      a {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        border: 2px solid $fontcolor1;
        border-radius: 10px;
        margin: 10px;
        padding: 10px;
        background-color: $bgiconscolor;
        cursor: pointer;
        transition: 0.2s ease;
        position: relative;
        &:hover {
          background-color: $bgiconscolorH;
          transform: scale(1.03);
          .distance {
            bottom: 10px;
            right: 20px;
          }
        }
        h2 {
          color: $white;
          margin-left: 10%;
          @include respond-to(medium) {
            font-size: 1rem;
          }
        }
        img {
          width: 200px;
          height: 75px;
          object-fit: cover;
          border-radius: 10px;
          margin: 0 auto;
          @include respond-to(small) {
            width: 140px;
            height: 60px;
          }
        }
        .distance {
          position: absolute;
          align-self: end;
          justify-self: end;
          right: 15px;

          p {
            font-style: italic;
            color: $white;
            font-size: 1rem;

            @include respond-to(medium) {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }

  .load-more-button {
    display: block;
    width: 200px;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 1rem;
    color: $fontcolor1;
    background-color: $bgiconscolor;
    border: 2px solid $fontcolor1;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    transition: 0.2s ease;

    &:hover {
      background-color: $bgiconscolorH;
      transform: scale(1.05);
    }

    @include respond-to(small) {
      width: 150px;
      font-size: 0.875rem;
    }
  }
}