.install-pwa {

  .install-btn,
  .install-text {
    display: block;
    margin: 0 auto 30px;
    padding: 15px;
    width: 100%;
    font-size: 1.2rem;
    color: $black;
    max-width: 800px;
    background-color: $fontcolor1;
    border: $borderWhite;
    border-radius: 20px;
    text-transform: uppercase;
    text-align: center;

    @include respond-to(small) {
      font-size: 0.5rem;
      padding: 5px;
      width: 80%;
      border-radius: 0 0 20px 20px;
    }
  }

  .install-btn-geo-info{
    background-color: transparent;
    border: none;
    display: contents;

    .geo-info {
      color: white;
      text-align: center;
      margin: 20px auto;
      padding: 10px;
      background-color: red;
      border-radius: 10px;
      border: $borderWhite;
      max-width: 600px;
      @include respond-to(small) {
        padding: 5px;
        width: 80%;
      }
    }
  }

  .install-btn {
    cursor: pointer;
  }

  .install-text {
    //display: flex;
    font-size: 0.6rem;
    @include respond-to(small) {
      font-size: 0.45rem;
    }
  }

  .chrome-btn {
    margin: 15px 0;
    position: relative;
    background-color: $white;
    font-size: 1.2rem;
    padding: 10px;
    border: 2px solid $black;
    border-radius: 20px;
    cursor: pointer;
    width: 50%;

    &:hover {
      background-color: $fontcolor1H;
      transform: scale(1.1);
    }

    @include respond-to(small) {
      font-size: 1rem;
      width: 60%;
    }
  }

  .popup-content {
    position: relative;
    background-color: $black;
    border: 2px solid $red;
    border-radius: 10px;
    padding: 30px;
    width: 60vw;
    height: 60vh;
    max-width: 700px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include respond-to(small) {
      width: 90vw;
      height: 70vh;
      max-width: 500px;
      padding: 20px;
    }

    .apple_choose{
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 20px;
      margin-bottom: 30px;
      gap: 20px;
    }
  }

  .popup-welcome{
    height: auto;
    @include respond-to(small) {
      height: auto;
    }

    h1{
      margin-bottom: 10px;
    }
  }

  .popup-instruction {
    background-color: $white;
    justify-content: flex-start;
    max-height: 1000px;
    height: 80vh;
    overflow-y: auto;
    padding: 30px;
    p {
      margin: 15px 0 25px 0;
      font-size: 1rem;
    }

    #majuscule {
      margin-bottom: 5px;
    }
    #minuscume {
      margin-top: 5px;
      text-transform: none;
    }

    img {
      max-width: 100%;
      max-height: 300px;
      object-fit: contain;
      margin-bottom: 25px;
    }

    .close-button-instruction {
      top: 10px;
      bottom: auto;
      right: 10px;
      font-size: 1.2rem;
    }

    .img-chrome-safari {
      p {
        margin: 5px 0;
      }
    }
  }

  .popup-iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  .close-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 1.5rem;
    color: $red;
    cursor: pointer;

    &:hover {
      color: $red2;
    }
  }
}