.partner-admin-container {
  @include setup-admin;
  .admin-content-container {
    .admin-content {
      h2 {
        font-size: 2rem;
        margin: 20px auto;
        padding: 10px;
        width: fit-content;
        color: $fontcolor1;
        border: 2px solid $white;
        border-radius: 10px;
      }
      section.new-partner {
        margin-bottom: 40px;
        form {
          .new-partner-setup {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 10px;
            h3 {
              text-align: center;
            }
            .info-new-partner {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              label {
                display: flex;
                align-items: flex-end;
                width: 80%;
                min-height: 30px;
                height: 15%;
                margin: 5px auto 0;
              }
              p {
                text-align: center;
              }
              input {
                width: 80%;
                min-height: 30px;
                height: 15%;
                margin: 5px auto;
                padding: 5px;
                background: $fontcolor1;
                border: 1px solid $white;
                border-radius: 5px;
                transition: 0.2s ease;
                &::placeholder {
                  color: $black2;
                }
                &:hover {
                  background: $fontcolor1H;
                }
                &:focus {
                  background: $fontcolor1H;
                }
              }
            }
            .city-new-partner {
              display: flex;
              flex-direction: column;
              align-items: center;
              h3 {
                margin-bottom: 20px;
              }
              section.location-info {
                input {
                  padding: 5px;
                  background: $fontcolor1;
                  border: 1px solid $white;
                  border-radius: 5px;
                  transition: 0.2s ease;
                  &:hover {
                    background: $fontcolor1H;
                  }
                  &:focus {
                    background: $fontcolor1H;
                  }
                }
                .without-coordinates {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-around;
                  height: 300px;
                  #btn-coordinates-activaction {
                    text-align: center;
                    background-color: $fontcolor1;
                    color: $black;
                    font-size: large;
                    border: 2px solid $white;
                    border-radius: 10px;
                    padding: 5px;
                    cursor: pointer;
                    transition: 0.2s ease;
                    &:hover {
                      background-color: $fontcolor1H;
                      transform: scale(0.97); 
                    }
                  }
                }
                .with-coordinates{
                  .coordinates-city {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    row-gap: 10px;
                    input {
                      grid-column: 2/4;
                    }
                  }
                }
              }
            }

            .interview-new-partner {
              display: flex;
              flex-direction: column;
              align-items: center;
              h3 {
                margin: 40px 0 10px 0;
              }
              label {
                display: flex;
                align-items: flex-end;
                width: 80%;
                min-height: 30px;
                height: 15%;
                margin: 5px auto 0;
              }
              label[for="interview-photo-content"] {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 20px auto;
                padding: 7px;
                height: 50px;
                background: $fontcolor1;
                border: 2px solid $white;
                border-radius: 10px;
                color: $black;
                cursor: pointer;
                transition: 0.2s ease;
                &:hover {
                  background: $fontcolor1H;
                  transform: scale(1.1);
                }
              }
              input, textarea {
                width: 80%;
                min-height: 30px;
                height: 15%;
                margin: 5px auto;
                padding: 5px;
                background: $fontcolor1;
                border: 1px solid $white;
                border-radius: 5px;
                transition: 0.2s ease;
                &::placeholder {
                  color: $black2;
                }
                &:hover {
                  background: $fontcolor1H;
                }
                &:focus {
                  background: $fontcolor1H;
                }
              }
              textarea {
                resize: none;
                height: auto;
              }
            }

            .categories-new-partner{
              display: flex;
              flex-direction: column;
              align-items: center;
              h3 {
                margin-bottom: 20px;
              }

              .flash-message {
                background: red;
                padding:5px;
                border-radius: 5px;
                border: 2px solid $white;
              }

              .description-new-partner {
                width: 100%;
                flex: 1;
                display: flex;
                flex-direction: column;
                label {
                  cursor: default;
                  background: none;
                  border: none;
                  color: $white;
                  &:hover {
                    transform: none;
                  }
                }
                textarea {
                  flex: 1;
                  width: 100%;
                  height: 100%;
                  resize: none;
                  padding: 10px;
                  background: $fontcolor1;
                  border: 1px solid $white;
                  border-radius: 5px;
                  transition: 0.2s ease;
                  &::placeholder {
                    color: $black2;
                  }
                  &:hover {
                    background: $fontcolor1H;
                  }
                  &:focus {
                    background: $fontcolor1H;
                  }
                }
              }
            }
            .social-media-new-partner {
              display: grid;
              label {
                display: flex;
                align-items: flex-end;
                width: 90%;
                min-height: 30px;
                height: 15%;
                margin: 5px auto 0;
              }
              input {
                width: 90%;
                min-height: 30px;
                height: 15%;
                margin: 5px auto;
                padding: 5px;
                background: $fontcolor1;
                border: 1px solid $white;
                border-radius: 5px;
                transition: 0.2s ease;
                &::placeholder {
                  color: $black2;
                }
                &:hover {
                  background: $fontcolor1H;
                }
                &:focus {
                  background: $fontcolor1H;
                }
              } 
            }
          }
          .new-partner-content {
            h3 {
              text-align: center;
            }
            .new-partner-content-container{
              width: 95%;
              margin: 0 auto;
              display: flex;
              justify-content: space-around;
              align-items: center;
              label {
                display: flex;
                align-items: center;
                padding: 7px;
                height: 50px;
                background: $fontcolor1;
                border: 2px solid $white;
                border-radius: 10px;
                color: $black;
                cursor: pointer;
                transition: 0.2s ease;
                &:hover {
                  background: $fontcolor1H;
                  transform: scale(1.1);
                }
              }
            }
          }
          .logo-interview {
            display: flex;
            .uploaded-logo {
              margin-left: 35px;

              img {
                border-radius: 10px;
                border: 2px solid $fontcolor1;
                margin: 5px 0;
              }
            }
          }
          .uploaded-files-container {
            width: 95%;
            margin: 0 auto;
            border: 2px solid $white;
            border-radius: 10px;
            legend {
              margin-left: 20px;
              color: $fontcolor1;
              font-size: 1.3rem;
            }
            .uploaded-files {
              display: flex;
              flex-wrap: wrap;
              i {
                color: $red;
                font-size: 2rem;
                cursor: pointer;
                transition: 0.2s ease;
                &:hover {
                  color: $red2;
                  transform: scale(1.1);
                }
              }  
              .picture-new-partner-container {
                display: flex;
                align-items: start;
                margin: 5px 25px 5px 0;
                img {
                  border-radius: 10px;
                  border: 2px solid $fontcolor1;
                  margin: 5px 10px;
                }
              }
              .video-new-partner-container {
                display: flex;
                align-items: start;
                margin: 5px 25px 5px 0;
                video {
                  border-radius: 10px;
                  border: 2px solid $fontcolor1;
                  cursor: pointer;
                } 
              }
            }
          }
          input[type="submit"]#valid {
            display: block;
            width: 50%;
            margin: 20px auto;
            background-color: $fontcolor1;
            color: $black;
            font-size: large;
            border: 2px solid $white;
            border-radius: 10px;
            padding: 5px;
            cursor: pointer;
            transition: 0.2s ease;
            &:hover {
              background-color: $fontcolor1H;
              transform: scale(1.1); 
            }
          }
          input[type="submit"]#invalid {
            display: block;
            width: 50%;
            margin: 20px auto;
            background-color: $black;
            color: $white;
            font-size: large;
            border: 2px solid $white;
            border-radius: 10px;
            padding: 5px;
            cursor: not-allowed;
            transition: 0.2s ease;          
          }
        }
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .modal-content {
          background: $fontcolor1;
          padding: 20px;
          border-radius: 10px;
          position: relative;
          width: 80%;
          max-width: 800px;
          border: 2px solid $white;
          color: $black;

          button{
            padding: 10px;
            background: $fontcolor1;
            border: 2px solid $white;
            border-radius: 10px;
            color: $black;
            cursor: pointer;
            transition: 0.2s ease;
            &:hover {
              background: $fontcolor1H;
            }
          }

          .modal-close {
            position: absolute;
            padding: 5px;
            top: 5px;
            right: 10px;
            border: none;
            font-size: 1.5rem;
            cursor: pointer;
          }

          .modal-button-container {
            display: flex;
            justify-content: center;
          }
        }

      }
      section.category-management {
        table {
          width: 70%;
          margin: 20px auto;
          color: $fontcolor1;
          &, th, td {
            border-collapse: collapse;
            border: 2px solid $white;
            padding: 5px;
            &.contain-btn {
              text-align: center;
              max-width: 100px;

              button:disabled {
                &:hover {
                  color: red;
                }
              }
            }
          }
          button {
            width: 100%;
            max-width: 200px;
            background-color: $fontcolor1;
            border: 1px solid $white;
            border-radius: 10px;
            cursor: pointer;
            &:hover {
              background-color: $fontcolor1H;
              color: $white;
            }
          }
          tr.deleted {
            background-color: $red;
          }
        }
        button.btn-refresh {
          display: flex;
          align-items: center;
          position: absolute;
          right: 20%;
          transform: translate(0, -110%);
          padding: 7px;
          height: 50px;
          background: $fontcolor1;
          border: 2px solid $white;
          border-radius: 10px;
          color: $black;
          cursor: pointer;
          transition: 0.2s ease;
          &:hover {
            background: $fontcolor1H;
          }
          i {
            font-size: 1.5rem;
          }
        }
        div {
          text-align: center;
          input {
            width: 30%;
            min-height: 30px;
            height: 15%;
            margin: 5px auto;
            padding: 5px;
            background: $fontcolor1;
            border: 1px solid $white;
            border-radius: 5px;
            transition: 0.2s ease;
            &::placeholder {
              color: $black2;
            }
            &:hover {
              background: $fontcolor1H;
            }
            &:focus {
              background: $fontcolor1H;
            }
          }
          button {
            padding: 5px;
            margin: 5px;
            color: $black;
            background-color: $fontcolor1;
            border: 1px solid $white;
            border-radius: 10px;
            cursor: pointer;
            &:hover {
              background-color: $fontcolor1H;
              color: $white;
            }
          }
          button#disabledCreateCat{
            cursor: not-allowed;
            background-color: $black;
            color: $white;
          }
        }
      }
      section.partner-management {
        form {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 70%;
          margin: 30px auto;
          padding: 20px;
          border: 2px solid $white;
          border-radius: 20px;
          .name-select-partner {
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            input {
              margin-left: 30px;
              width: 70%;
              height: 30px;
              padding: 5px;
              background: $fontcolor1;
              border: 1px solid $white;
              border-radius: 5px;
              transition: 0.2s ease;
              &::placeholder {
                color: $black2;
              }
              &:hover {
                background: $fontcolor1H;
              }
              &:focus {
                background: $fontcolor1H;
              }
            }
          }
          .others-select-options {
            margin-top: 20px;
            height: 120px;
            width: 90%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            column-gap: 20px;
            select {
              flex: 1;
              margin: 20px 0;
              padding: 5px;
              font-size: 1rem;
              background-color: $fontcolor1;
              border: 2px solid $white;
              border-radius: 5px;
              transition: 0.2s ease;
              color: black;
              &:hover {
                border: 2px solid $white2;
              }
            }
          }
        }
        button.btn-refresh {
          display: flex;
          align-items: center;
          position: absolute;
          right: 8%;
          transform: translate(0, -110%);
          padding: 7px;
          height: 50px;
          background: $fontcolor1;
          border: 2px solid $white;
          border-radius: 10px;
          color: $black;
          cursor: pointer;
          transition: 0.2s ease;
          &:hover {
            background: $fontcolor1H;
          }
          i {
            font-size: 1.5rem;
          }
        }
        p {
          margin: 20px 0;
          text-align: center;
          font-size: 1.5rem;
        }
        table.partner-table {
          width: 95%;
          margin: 20px auto;
          color: $fontcolor1;
          &, th, td {
            border-collapse: collapse;
            border: 2px solid $white;
            padding: 5px;

            .first-category {
              color: red;
            }

            &.contain-btn {
              text-align: center;

              button:disabled {
                &:hover {
                  color: red;
                }
              }
            }
          }
          button {
            width: 100%;
            background-color: $fontcolor1;
            border: 1px solid $white;
            border-radius: 10px;
            cursor: pointer;
            &:hover {
              background-color: $fontcolor1H;
              color: $white;
            }
          }
          tr.deleted {
            background-color: $red;
          }
        }
        div {
          text-align: center;
          button {
            padding: 5px;
            margin: 5px;
            background-color: $fontcolor1;
            border: 1px solid $white;
            border-radius: 10px;
            cursor: pointer;
            &:hover {
              background-color: $fontcolor1H;
              color: $white;
            }
          }
        }
      }
    }
  }
}