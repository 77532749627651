.city-admin-container {
  @include setup-admin;

  .admin-content-container {
    .admin-content {
      h2 {
        font-size: 2rem;
        margin: 20px auto;
        padding: 10px;
        width: fit-content;
        color: $fontcolor1;
        border: 2px solid $white;
        border-radius: 10px;
      }

      section.new-city {
        margin-bottom: 40px;

        form {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 10px;

          h3 {
            text-align: center;
          }

          .info-new-city {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            label {
              display: flex;
              align-items: flex-end;
              width: 80%;
              min-height: 30px;
              height: 15%;
              margin: 5px auto 0;
            }

            input {
              width: 80%;
              min-height: 30px;
              height: 15%;
              margin: 5px auto;
              padding: 5px;
              background: $fontcolor1;
              border: 1px solid $white;
              border-radius: 5px;
              transition: 0.2s ease;

              &::placeholder {
                color: $black2;
              }

              &:hover {
                background: $fontcolor1H;
              }

              &:focus {
                background: $fontcolor1H;
              }
            }
          }

          .files-upload {
            .input-container-uploads {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              height: 50%;

              div {
                display: flex;
                flex-direction: column;

                label {
                  display: flex;
                  align-items: center;
                  padding: 7px;
                  height: 50px;
                  background: $fontcolor1;
                  border: 2px solid $white;
                  border-radius: 10px;
                  color: $black;
                  cursor: pointer;
                  transition: 0.2s ease;

                  &:hover {
                    background: $fontcolor1H;
                    transform: scale(1.1);
                  }
                }
              }
            }

            .render-uploads-new-city {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              height: 50%;

              img {
                max-height: 100px;
                max-width: 200px;
                margin: 5px;
              }

            }
          }

          .social-media-new-city {
            display: grid;

            label {
              display: flex;
              align-items: flex-end;
              width: 90%;
              min-height: 30px;
              height: 15%;
              margin: 5px auto 0;
            }

            input {
              width: 90%;
              min-height: 30px;
              height: 15%;
              margin: 5px auto;
              padding: 5px;
              background: $fontcolor1;
              border: 1px solid $white;
              border-radius: 5px;
              transition: 0.2s ease;

              &::placeholder {
                color: $black2;
              }

              &:hover {
                background: $fontcolor1H;
              }

              &:focus {
                background: $fontcolor1H;
              }
            }
          }

          input[type="submit"] {
            grid-column: 2/3;
            background-color: $fontcolor1;
            color: $black;
            font-size: large;
            border: 2px solid $white;
            border-radius: 10px;
            padding: 5px;
            cursor: pointer;
            transition: 0.2s ease;

            &:hover {
              background-color: $fontcolor1H;
              transform: scale(1.1);
            }
          }
        }
      }

      section.city-management {

        form {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 70%;
          margin: 30px auto;
          padding: 20px;
          border: 2px solid $white;
          border-radius: 20px;

          .name-select-city {
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: center;

            input {
              margin-left: 30px;
              width: 70%;
              height: 30px;
              padding: 5px;
              background: $fontcolor1;
              border: 1px solid $white;
              border-radius: 5px;
              transition: 0.2s ease;

              &::placeholder {
                color: $black2;
              }

              &:hover {
                background: $fontcolor1H;
              }

              &:focus {
                background: $fontcolor1H;
              }
            }
          }

          .position-select {
            display: flex;

            .country-select-city {
              margin-top: 20px;
              height: 120px;
              width: 90%;
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              align-items: center;
              column-gap: 20px;

              select {
                flex: 1;
                margin: 20px 0;
                padding: 5px;
                font-size: 1rem;
                background-color: $fontcolor1;
                border: 2px solid $white;
                border-radius: 5px;
                transition: 0.2s ease;
                color: black;

                &:hover {
                  border: 2px solid $white2;
                }
              }
            }

            .postalcode-select-city {
              margin: 20px;
              width: 90%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              input {
                margin: 30px;
                width: 100%;
                height: 30px;
                padding: 5px;
                background: $fontcolor1;
                border: 1px solid $white;
                border-radius: 5px;
                transition: 0.2s ease;

                &::placeholder {
                  color: $black2;
                }

                &:hover {
                  background: $fontcolor1H;
                }

                &:focus {
                  background: $fontcolor1H;
                }
              }

            }
          }
        }
        p {
          margin: 20px 0;
          text-align: center;
          font-size: 1.5rem;
        }

        table {
          width: 95%;
          margin: 20px auto;
          color: $black;
          background: $fontcolor1;

          &, th, td {
            border-collapse: collapse;
            border: 2px solid $white;
            padding: 5px;

            &.contain-btn {
              text-align: center;

              button:disabled {
                &:hover {
                  color: red;
                }
              }
            }

            img {
              max-height: 70px;
              max-width: 200px;
            }

            .city-logo {
              filter: contrast(0%);
            }
          }

          button {
            width: 100%;
            max-width: 200px;
            background-color: $fontcolor1;
            border: 1px solid $white;
            border-radius: 10px;
            cursor: pointer;

            &:hover {
              background-color: $fontcolor1H;
              color: $white;
            }
          }

          tr.deleted {
            background-color: $red;
          }
        }

        button.btn-refresh {
          display: flex;
          align-items: center;
          position: absolute;
          right: 7%;
          transform: translate(0, -110%);
          padding: 7px;
          height: 50px;
          background: $fontcolor1;
          border: 2px solid $white;
          border-radius: 10px;
          color: $black;
          cursor: pointer;
          transition: 0.2s ease;

          &:hover {
            background: $fontcolor1H;
          }

          i {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}