/* Styles pour le popup de mise à jour des utilisateurs */
.popup.update-user {
  .popup-update-container {
    background: $fontcolor1;
    border: $borderWhite;
    border-radius: 10px;
    padding: 20px;
    width: 70%;
    max-height: 95vh;
    overflow-y: scroll;

    i {
      font-size: 2rem;
      color: $red;
      cursor: pointer;
      transition: 0.2s ease;

      &:hover {
        color: $red2;
      }
    }

    .popup-update-user-header {
      display: flex;
      justify-content: space-between;
      align-items: start;
      margin-bottom: 20px;

      h2 {
        font-size: 1.8rem;
      }

      .cancel-button {
        display: flex;
        align-items: center;
        color: $red;
        cursor: pointer;

        p {
          transition: 0.2s ease;
        }

        &:hover {
          p, i {
            color: $red2;
          }
        }
      }
    }

    form {
      .form-row {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .form-group {
          flex: 1 1 45%;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 20px;

          label {
            font-weight: bold;
            margin-bottom: 5px;
          }

          input, textarea {
            width: 90%;
            padding: 10px;
            font-size: 1rem;
            border: $borderBlack;
            border-radius: 10px;
            transition: border-color 0.3s;
            background-color: $white;

            &:focus {
              border-color: #007bff;
              outline: none;
            }
          }

          textarea {
            resize: vertical;
          }
        }
      }
      .update-partner-content-container {
        width: 80%;
        margin: auto;
        display: flex;
        justify-content: space-around;
        align-items: center;

        label {
          display: flex;
          align-items: center;
          padding: 7px;
          height: 50px;
          background: $white;
          border: $borderBlack;
          border-radius: 10px;
          color: $black;
          cursor: pointer;
          transition: 0.2s ease;

          &:hover {
            transform: scale(1.1);
          }
        }
      }



      input[type="submit"] {
        display: block;
        width: 50%;
        margin: 20px auto;
        background-color: $white;
        color: $black;
        font-size: large;
        border: $borderBlack;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
        transition: 0.2s ease;

        &:hover {
          transform: scale(1.1);
        }

        &#invalid {
          background-color: $black;
          color: $white;
          cursor: not-allowed;
        }
      }
    }

    section {
      margin-bottom: 30px;

      h3 {
        margin-bottom: 15px;
        text-align: center;
        font-size: 1.5rem;
      }

      .form-row {
        margin-bottom: 20px;
      }
    }
  }
}