.partner-profile-container {
  @include set-background-neutral;
  color: $white;
  .partner-infos {
    text-align: center;
    img {
      display: block;
      max-width: 90%;
      max-height: 30vh;
      margin: 0 auto;
      padding: 10px;
      border: 2px solid $fontcolor1;
      border-radius: 20px;
      background-color: $white;
    }
    .text-content-partner-container {
      margin: 20px auto;
      padding: 20px;
      background-color: $bgiconscolor;
      border-top: 2px solid $fontcolor1;
      border-bottom: 2px solid $fontcolor1;
      @include respond-to(small) {
        padding: 10px;
      }
      h1 {
        font-size: 4rem;
        @include respond-to(small) {
          font-size: 1.5rem;
        }
      }
      a {
        color: $fontcolor1;
        text-decoration: underline;
        font-style: italic;
        &:hover {
          color: $fontcolor1H;
        }
      }
      p {
        margin: 20px auto;
        max-width: 70%;
        @include respond-to(small) {
          max-width: 90%;
        }
      }
      .btn-interview {
        display: block;
        width:70%;
        margin: 20px auto;
        padding: 10px 20px;
        background-color: $fontcolor1;
        color: black;
        border: none;
        border-radius: 5px;
        text-align: center;
        text-decoration: none;
        font-size: 1rem;
        cursor: pointer;
        &:hover {
          background-color: red;
        }
      }
    }
    .content-uploads-partner-container {
      img {
        max-width: 90%;
        height: auto;
        max-height: 50vh;
        margin: 20px auto;
        padding: 0;
        border: 2px solid $fontcolor1;
        border-radius: 20px;
        background-color: $white;
      }
      video {
        max-width: 90%;
        height: auto;
        max-height: 50vh;
        margin: 20px auto;
        padding: 0;
        border: 2px solid $fontcolor1;
        border-radius: 20px;
        background-color: $white;
      }
    }
    .contact-partner-container {
      background-color: $bgiconscolor;
      padding: 10px;
      border-top: 2px solid $fontcolor1;
      .info-contact-partner-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-left: 10%;

        a {
          flex: 0 1 calc(16.66% - 20px);
          margin: 10px;
          box-sizing: border-box;
          color: $fontcolor1;
          font-size: 3rem;
          display: flex;
          align-items: center;
          transition: 0.2s ease;
          &:hover {
            color: $fontcolor1H;
          }

          @include respond-to(small) {
            font-size: 1.9rem;
            flex: 0 1 calc(33.33% - 20px);
          }

          img {
            border: none;
            background: none;
            min-width: 40px;
            max-width: 40px;
            min-height: 40px;
            max-height: 40px;
            margin: 0;
            padding: 0;

            @include respond-to(small) {
              max-width: 25px;
              min-width: 25px;
              min-height: 25px;
              max-height: 25px;
            }
          }
        }
      }

    }
  }
}