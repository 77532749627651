.popup-magazine {
  .popup-content-magazine-container {
    background: $fontcolor1;
    border: $borderWhite;
    border-radius: 10px;
    padding: 20px;
    width: 80%;
    max-height: 95vh;
    overflow-y: auto;

    i {
      font-size: 2rem;
      color: $red;
      cursor: pointer;
      transition: 0.2s ease;

      &:hover {
        color: $red2;
      }
    }

    .popup-magazine-header {
      display: flex;
      justify-content: space-between;
      align-items: start;
      margin-bottom: 20px;

      h2 {
        font-size: 1.8rem;
      }

      .cancel-button {
        display: flex;
        align-items: center;
        color: $red;
        cursor: pointer;

        p {
          transition: 0.2s ease;
        }

        &:hover {
          p, i {
            color: $red2;
          }
        }
      }
    }

    form {
      .label-info-magazine-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        max-height: 60px;
        margin-bottom: 40px;

        input {
          width: 23%;
          height: 30px;
          padding: 3px;
          background-color: $white;
          border: $borderBlack;
          border-radius: 10px;

          &:focus {
            outline: none;
          }

        }
      }

      .magazine-image {
        h3 {
          font-size: 1.5rem;
          margin: 23px 0 15px 0;
          text-align: center;
        }

        .magazine-content-container {
          width: 50%;
          margin: 0 auto;
          display: flex;
          justify-content: space-around;
          align-items: center;

          label, .button-pictures {
            display: flex;
            align-items: center;
            padding: 7px;
            height: 50px;
            background: $white;
            border: $borderBlack;
            border-radius: 10px;
            color: $black;
            cursor: pointer;
            transition: 0.2s ease;

            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }

      .uploaded-files-container {
        width: 95%;
        margin: 0 auto;
        border: 2px solid $white;
        border-radius: 10px;

        legend {
          margin-left: 20px;
          color: $black;
          font-size: 1.3rem;
        }

        .uploaded-files {
          display: flex;
          flex-wrap: wrap;

          i {
            color: $red;
            font-size: 2rem;
            cursor: pointer;
            transition: 0.2s ease;

            &:hover {
              color: $red2;
              transform: scale(1.1);
            }
          }

          .picture-new-partner-container {
            display: flex;
            align-items: start;
            margin: 5px 25px 5px 0;

            img {
              border-radius: 10px;
              border: 2px solid $fontcolor1;
              margin: 5px 10px;
            }
          }
        }
      }

      .magazine-footer {
        display: flex;

        input[type="submit"] {
          width: 50%;
          margin: 20px auto auto 25%;
          background-color: $white;
          color: $black;
          font-size: large;
          border: 2px solid $black;
          border-radius: 10px;
          padding: 5px;
          cursor: pointer;
          transition: 0.2s ease;

          &:hover {
            transform: scale(1.1);
          }
        }

        .delete-button {
          margin: 20px 0 20px 0;
          background-color: $red;
          color: $white;
          font-size: large;
          border: 2px solid $red;
          border-radius: 10px;
          padding: 5px;
          cursor: pointer;
          transition: 0.2s ease;

          &:hover {
            background-color: $red2;
            border-color: $red2;
            transform: scale(1.1);
          }
        }
      }
    }
  }
}