.update-message {
  .popup-update-container {
    background: $fontcolor1;
    border: $borderWhite;
    border-radius: 10px;
    padding: 20px;
    width: 70%;
    max-height: 95vh;
    overflow-y: scroll;

    i {
      font-size: 2rem;
      color: $red;
      cursor: pointer;
      transition: 0.2s ease;

      &:hover {
        color: $red2;
      }
    }

    .popup-update-message-header {
      display: flex;
      justify-content: space-between;
      align-items: start;
      margin-bottom: 20px;

      h2 {
        font-size: 1.8rem;
      }

      .cancel-button {
        display: flex;
        align-items: center;
        color: $red;
        cursor: pointer;

        p {
          transition: 0.2s ease;
        }

        &:hover {
          p, i {
            color: $red2;
          }
        }
      }
    }

    form {
      .update-message-setup {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        h3 {
          text-align: center;
        }

        .info-update-message,
        .city-update-message,
        .links-section {
          flex: 1 1 30%;
          min-width: 300px;
        }

        .info-update-message {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          label {
            display: flex;
            align-items: flex-end;
            width: 80%;
            min-height: 30px;
            height: 15%;
            margin: 5px auto 0;
          }

          p {
            text-align: center;
          }

          input {
            width: 80%;
            min-height: 30px;
            height: 15%;
            margin: 5px auto;
            padding: 5px;
            background: $white;
            border: $borderBlack;
            border-radius: 5px;
            transition: 0.2s ease;

            &::placeholder {
              color: $black2;
            }
          }
        }

        .links-section {
          margin-left: 20px;

          label {
            display: flex;
            align-items: flex-end;
            width: 90%;
            min-height: 30px;
            height: 15%;
            margin: 5px auto 0;
          }

          input {
            width: 90%;
            min-height: 30px;
            height: 15%;
            margin: 5px auto;
            padding: 5px;
            background: white;
            border: $borderBlack;
            border-radius: 5px;
            transition: 0.2s ease;

            &::placeholder {
              color: $black2;
            }
          }
        }
      }

      .description-update-message {
        width: 90%;
        height:200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px auto;

        label {
          margin-bottom: 5px;
          cursor: default;
          background: none;
          border: none;
          color: $black;

          &:hover {
            transform: none;
          }
        }

        textarea {
          flex: 1;
          width: 100%;
          height: 100%;
          resize: none;
          padding: 10px;
          background: $white;
          border: $borderBlack;
          border-radius: 5px;
          transition: 0.2s ease;

          &::placeholder {
            color: $black2;
          }

          &:focus {
            outline: none;
          }
        }
      }

      .update-message-content {
        h3 {
          text-align: center;
        }

        .update-message-content-container {
          width: 95%;
          margin: 0 auto;
          display: flex;
          justify-content: space-around;
          align-items: center;

          label {
            display: flex;
            align-items: center;
            padding: 7px;
            height: 50px;
            background: $fontcolor1;
            border: 2px solid $white;
            border-radius: 10px;
            color: $black;
            cursor: pointer;
            transition: 0.2s ease;

            &:hover {
              background: $fontcolor1H;
              transform: scale(1.1);
            }
          }
        }
      }

      .uploaded-logo {
        margin-left: 35px;

        img {
          border-radius: 10px;
          border: 2px solid $fontcolor1;
          margin: 5px 0;
        }
      }

      .uploaded-files-container {
        width: 95%;
        margin: 0 auto;
        border: 2px solid $white;
        border-radius: 10px;

        legend {
          margin-left: 20px;
          color: $black;
          font-size: 1.3rem;
        }

        .uploaded-files {
          display: flex;
          flex-wrap: wrap;

          i {
            color: $red;
            font-size: 2rem;
            cursor: pointer;
            transition: 0.2s ease;

            &:hover {
              color: $red2;
              transform: scale(1.1);
            }
          }

          .picture-new-partner-container {
            display: flex;
            align-items: start;
            margin: 5px 25px 5px 0;

            img {
              border-radius: 10px;
              border: 2px solid $fontcolor1;
              margin: 5px 10px;
            }
          }

          .video-new-partner-container {
            display: flex;
            align-items: start;
            margin: 5px 25px 5px 0;

            video {
              border-radius: 10px;
              border: 2px solid $fontcolor1;
              cursor: pointer;
            }
          }
        }
      }

      .update-message-footer {
        display: flex;
        input[type="submit"]#valid {
          display: block;
          width: 50%;
          margin: 20px auto;
          background-color: $white;
          color: $black;
          font-size: large;
          border: $borderBlack;
          border-radius: 10px;
          padding: 5px;
          cursor: pointer;
          transition: 0.2s ease;

          &:hover {
            transform: scale(1.1);
          }
        }

        input[type="submit"]#invalid {
          display: block;
          width: 50%;
          margin: 20px auto;
          background-color: $black;
          color: $white;
          font-size: large;
          border: $borderBlack;
          border-radius: 10px;
          padding: 5px;
          cursor: not-allowed;
          transition: 0.2s ease;
        }

        .delete-button {
          margin: 20px 0 20px 0 ;
          background-color: $red;
          color: $white;
          font-size: large;
          border: 2px solid $red;
          border-radius: 10px;
          padding: 5px;
          cursor: pointer;
          transition: 0.2s ease;

          &:hover {
            background-color: $red2;
            border-color: $red2;
            transform: scale(1.1);
          }
        }
      }
    }
  }
}
