.partners-list-container {
  scrollbar-color: $fontcolor1;
  @include set-background-neutral;
  .partners-list {
    width: 70%;
    margin: 0 auto;
    @include respond-to(medium) {
      width: 80%;
    }
    @include respond-to(small) {
      width: 95%;
    }
  }

  h1{
    padding: 5px;
    text-align: center;
    margin: 0 30% 2rem 30%;
    color: $fontcolor1;
    border: 2px solid #e2b259;
    border-radius: 20px;
    background-color: rgba(0,0,0,75);

    @include respond-to(small) {
      font-size: 1.5rem;
    }

    @include respond-to(medium) {
      margin: 0 10% 2rem 10%;
    }
  }

  .load-more-button {
    display: block;
    width: 200px;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 1rem;
    color: $fontcolor1;
    background-color: $bgiconscolor;
    border: 2px solid $fontcolor1;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    transition: 0.2s ease;

    &:hover {
      background-color: $bgiconscolorH;
      transform: scale(1.05);
    }

    @include respond-to(small) {
      width: 150px;
      font-size: 0.875rem;
    }
  }
}