.back-button {
  position: fixed;
  left: 2.5%;
  top: 2.5%;
  @include respond-to(small) {
    left: 0;
    top: 0;
    width: 100vw;
  }
  button {
    background-color: $fontcolor1;
    font-size: 1rem;
    padding: 20px;
    border: 2px solid $white;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.2s ease;
    &:hover {
      background-color: $fontcolor1H;
      transform: scale(1.1);
    }
    @include respond-to(small) {
      padding: 10px 0;
      border: none;
      border-radius: 0;
      width: 100%;
    }
  }
}