.cities-list-container {
  @include set-background-neutral;
  background: url("../assets/images/background-earth.jpg") center/cover;
  color: $fontcolor1;

  section.header-container {
    section.header-container-btn {
      position: sticky;
      top: 0;
      z-index: 1000;
    }

    button {
      margin-top: 40px;
      margin-left: 10%;
      width: 110%;
      @include respond-to(small) {
        margin-left: 0;
        margin-top: 0;
      }
    }

    img {
      width: 100%;
      max-width: 800px;
      margin: 10px auto;
      display: block;
      border-radius: 20px;
      border: 2px solid $white;
      background-color: $bgiconscolor;
      @include respond-to(small) {
        margin-top: 30px;
        width: 80%;
      }
    }
  }

  section.city-selector {
    .city-searchbar-container {
      color: $black;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 30px;
      padding: 20px;
      width: 100%;
      max-width: 800px;
      background-color: $fontcolor1;
      border-radius: 20px;
      border: 2px solid $white;
      @include respond-to(small) {
        padding: 5px;
        width: 80%;
      }

      h1 {
        font-size: 2rem;
        text-transform: uppercase;
        @include respond-to(small) {
          font-size: 1.5rem;
        }
      }

      form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        @include respond-to(small) {
          flex-direction: column;
        }

        input {
          flex: 1;
          height: 40px;
          font-size: large;
          @include respond-to(small) {
            width: 90%;
            font-size: 0.8rem;
          }
        }
      }
    }

    .city-display {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
      height: 20vh;
      margin: 30px;
      gap: 20px;
      @include respond-to(small) {
        grid-template-columns: 1fr;
        height: 40vh;
      }

      .load-more-cities {
        margin-bottom: 40px;
        .load-more-cities-button {
          padding: 10px;
          margin: auto;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 50%;
          height: 90%;
          background-color: $bgiconscolor;
          border: 2px solid $fontcolor1;
          border-radius: 20px;
          color: $fontcolor1;
          font-size: 1.5rem;
          transition: 0.3s ease;

          @include respond-to(small) {
            width: 80%;
            margin-bottom: 30px;
          }

          i {
            font-size: 4rem;
            margin-bottom: 10px;
            color: $white;
            @include respond-to(small) {
              margin-top: 10px;
              font-size: 3rem;
            }
          }

          &:hover {
            border: 2px solid $fontcolor1H;
            background-color: $bgiconscolorH;
            color: $fontcolor1H;
            transform: scale(1.05);
          }
        }
      }
    }
  }


}