.news-admin-container {
  @include setup-admin;

  .admin-content-container {
    h2 {
      font-size: 2rem;
      margin: 20px auto;
      padding: 10px;
      width: fit-content;
      color: $fontcolor1;
      border: 2px solid $white;
      border-radius: 10px;
    }

    .selected{
      margin-left:20%;
    }

    h3 {
      margin-top: 20px;
      text-align: center;
    }
    margin-bottom: 40px;


    .news-admin-container {
      display: flex;
      flex-direction: column;
      height: 50vh;
    }

    .admin-content-container {
      display: flex;
      flex: 1;
    }

    .admin-content {
      display: flex;
      flex: 1;
      padding: 20px;
    }

    .form-and-news-list {
      display: flex;
      width: 100%;
    }

    .form-container {
      width: 100%;
      padding-left: 20px;
    }
    form {
      .new-news-setup {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 10px;

        .info-new-news {
          height: 100%;
          display: flex;
          justify-content: space-around;
          width: auto;
          .input-group {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin: 0 10px;

            label {
              display: flex;
              align-items: flex-end;
              width: 80%;
              min-height: 30px;
              height: 15%;
              margin: 5px auto 0;
            }

            p {
              text-align: center;
            }

            input, textarea {
              width: 80%;
              min-height: 30px;
              margin: 5px auto;
              padding: 5px;
              background: $fontcolor1;
              border: 1px solid $white;
              border-radius: 5px;
              transition: 0.2s ease;

              &::placeholder {
                color: $black2;
              }

              &:hover {
                background: $fontcolor1H;
              }

              &:focus {
                background: $fontcolor1H;
              }
            }
          }
        }
      }

      .description-new-news {
        width: 90%;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px auto;

        label {
          margin-bottom: 5px;
          cursor: default;
          background: none;
          border: none;
          color: $white;
          &:hover {
            transform: none;
          }
        }
        textarea {
          flex: 1;
          width: 100%;
          height: 100%;
          resize: none;
          padding: 10px;
          background: $fontcolor1;
          border: 1px solid $white;
          border-radius: 5px;
          transition: 0.2s ease;
          &::placeholder {
            color: $black2;
          }
          &:hover {
            background: $fontcolor1H;
          }
          &:focus {
            background: $fontcolor1H;
          }
        }
      }

      .new-news-content {
        h3 {
          text-align: center;
        }
        .new-news-content-container {
          width: 90%;
          margin: 0 auto;
          display: flex;
          justify-content: space-around;
          align-items: center;
          label {
            display: flex;
            align-items: center;
            padding: 7px;
            height: 50px;
            background: $fontcolor1;
            border: 2px solid $white;
            border-radius: 10px;
            color: $black;
            cursor: pointer;
            transition: 0.2s ease;
            &:hover {
              background: $fontcolor1H;
              transform: scale(1.1);
            }
          }
          .links-section {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            .link-input {
              display: flex;
              flex-direction: column;
              margin: 2px;

              label {
                background: none;
                color: $white;
                border: none;
                display: flex;
                align-items: flex-end;
                width: 100%;
                height: 15%;
                margin: 20px auto 0;
                pointer-events: none;
                transition: none;
              }

              input {
                width: 150%;
                height: 30%;
                margin: 5px auto;
                padding: 10px;
                background: $fontcolor1;
                border: 1px solid $white;
                border-radius: 5px;
                transition: 0.2s ease;

                &::placeholder {
                  color: $black2;
                }

                &:hover {
                  background: $fontcolor1H;
                }

                &:focus {
                  background: $fontcolor1H;
                }
              }
            }
          }
        }
      }

      .uploaded-logo-photo {
        margin-left: 35px;
        display: flex;
        gap: 30px;
        .uploaded-logo-photo-contain{
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          max-width: 70%;
          margin-left: 15%;

          .link-preview-img-date{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 10px 0;
            padding: 10px;
          }
        }
        img {
          border-radius: 10px;
          border: 2px solid $fontcolor1;
          margin: 5px 0;
        }
        .link-preview {
          display: flex;
          flex-direction: row;
          align-items: center;
          border: $borderWhite;
          border-radius: 20px;
          padding: 10px;
          margin-right: 20px;

          img {
            width: 100px;
            border-radius: 10px;
            border: none;
            margin: 5px 0;
          }

          .link-preview-text {
            display: flex;
            flex-direction: column;
            margin-left: 10px;

            h3 {
              margin: 0;
              font-size: 1.2rem;
              color: $fontcolor1;
            }

            p {
              margin: 5px 0 0;
              color: $fontcolor1;
            }
          }
        }
      }

      section.preview-video {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px auto;
        padding: 20px;
        border: 2px solid $fontcolor1;
        border-radius: 10px;
        background-color: $white;
        width: 80%;

        iframe {
          width: 100%;
          max-width: 600px;
          height: 340px;
          border: none;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
      }

      input[type="submit"]#valid {
        display: block;
        width: 50%;
        margin: 20px auto;
        background-color: $fontcolor1;
        color: $black;
        font-size: large;
        border: 2px solid $white;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
        transition: 0.2s ease;
        &:hover {
          background-color: $fontcolor1H;
          transform: scale(1.1);
        }
      }
      input[type="submit"]#invalid {
        display: block;
        width: 50%;
        margin: 20px auto;
        background-color: $black;
        color: $white;
        font-size: large;
        border: 2px solid $white;
        border-radius: 10px;
        padding: 5px;
        cursor: not-allowed;
        transition: 0.2s ease;
      }
    }
  }
}