.categories-list-container {
  h1 {
    color: $white;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px;
  }
  @include set-background-neutral;
  .categories-list {
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 1rem;
    @include respond-to(medium) {
      width: 80%;
    }
    @include respond-to(small) {
      width: 90%;
    }
  }

  .category-searchbar-container {
    width: 70%;
    margin: 20px auto;
    padding: 10px;
    border: 2px solid $white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $fontcolor1;
    @include respond-to(medium) {
      width: 80%;
    }
    @include respond-to(small) {
      width: 90%;
    }
    input {
      width: 90%;
      height: 30px;
      padding: 5px;
      background: $fontcolor1;
      border: 1px solid $white;
      border-radius: 5px;
      transition: 0.2s ease;
      &::placeholder {
        color: $black2;
      }
      &:hover {
        background: $fontcolor1H;
      }
      &:focus {
        background: $fontcolor1H;
      }
    }
  }
}