.message-list-container {
  h1 {
    color: $white;
    font-size: 2rem;
    text-align: center;
    @include respond-to(small) {
      font-size: 1.5rem;
    }
  }

  @include set-background-neutral;

  .message {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 95%;
    margin: 0 auto;
    @include respond-to(small) {
      grid-template-columns: 1fr;
    }

    .message-entry {
      a {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 2px solid $fontcolor1;
        border-radius: 10px;
        margin: 10px;
        padding: 10px;
        background-color: $bgiconscolor;
        cursor: pointer;
        transition: 0.2s ease;

        .publication-date {
          position: absolute;
          bottom: 5px;
          left: 10px;
          color: $white;
          font-size: 0.8rem;
        }

        .read-more {
          position: absolute;
          bottom: 10px;
          right: 10px;
          display: flex;
          align-items: center;
          color: $white;
          font-size: 0.9rem;
          text-decoration: none;

          .arrow {
            margin-left: 5px;
            border: solid $white;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;
            transform: rotate(45deg);
          }
        }

        h3 {
          color: $white;
          margin-left: 10%;
          @include respond-to(medium) {
            font-size: 1rem;
          }
        }

        .message-content {
          display: grid;
          grid-template-columns: 30% 70%;
          align-items: center;
          margin-bottom: 20px;

          img {
            width: 100%;
            height: auto;
          }

          p{
            margin-left:20px;
            color: $white;
            @media (max-width: 400px) {
              font-size: 0.8rem;
            }
          }
        }

        &:hover {
          background-color: $bgiconscolorH;
          transform: scale(1.03);
        }

        h2 {
          color: $white;
          margin-left: 10%;
          @include respond-to(medium) {
            font-size: 1rem;
          }
        }

        img {
          width: 200px;
          height: 75px;
          object-fit: cover;
          border-radius: 10px;
          margin: 0 auto;
          @include respond-to(small) {
            width: 140px;
            height: 60px;
          }
        }
      }
    }
  }
  .load-more-button {
    display: block;
    width: 200px;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 1rem;
    color: $fontcolor1;
    background-color: $bgiconscolor;
    border: 2px solid $fontcolor1;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    transition: 0.2s ease;

    &:hover {
      background-color: $bgiconscolorH;
      transform: scale(1.05);
    }

    @include respond-to(small) {
      width: 150px;
      font-size: 0.875rem;
    }
  }
}