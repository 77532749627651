.partner-profile-container {
  section.logo-container-partner-link {
    img {
      width: 100%;
      max-width: 600px;
      margin: 10px auto;
      display: block;
      border-radius: 20px;
      border: 2px solid $white;
      background-color: $bgiconscolor;
      @include respond-to(small) {
        width: 80%;
      }
    }
  }
}