.popup-new-guided-tour {
  .popup-content-new-guided-tour-container {
    background: $fontcolor1;
    border: $borderWhite;
    border-radius: 10px;
    padding: 20px;
    width: 80%;
    max-height: 95vh;
    overflow-y: auto;
    i {
      font-size: 2rem;
      color: $red;
      cursor: pointer;
      transition: 0.2s ease;
      &:hover {
        color: $red2;
      }
    }
    .popup-guided-tour-header {
      display: flex;
      justify-content: space-between;
      align-items: start;
      margin-bottom: 20px;
      h2 {
        font-size: 1.8rem;
      }
      .cancel-button {
        display: flex;
        align-items: center;
        color: $red;
        cursor: pointer;
        p {
          transition: 0.2s ease;
        }
        &:hover {
          p, i {
            color: $red2;
          }
        }
      }
    }
    form {
      section.guided-tour-infos {
        .label-info-guided-tour-container {

          display: flex;
          flex-direction: column;
          align-items: center;
          flex-wrap: wrap;
          max-height: 60px;
          margin-bottom: 40px;
          input {
            width: 23%;
            height: 30px;
            padding: 3px;
            background-color: $white;
            border: $borderBlack;
            border-radius: 10px;
            &:focus {
              outline: none;
            }
          }
          .label-btn {
            display: flex;
            align-items: center;
            padding: 7px;
            height: 50px;
            background: $white;
            border: $borderBlack;
            border-radius: 10px;
            color: $black;
            cursor: pointer;
            transition: 0.2s ease;
            &:hover {
              transform: scale(1.1);
            }      
          }
        }
        img {
          display: block;
          max-width: 600px;
          max-height: 300px;
          margin: 20px auto;
          border: $borderWhite;
          border-radius: 10px;
        }
      }
      section.guided-tour-audio {
        text-align: center;
        .searchbar-country {
          margin-bottom: 20px;
          label {
            font-size: 1.3rem;
            margin-right: 20px;
          }
          input {
            width: 23%;
            height: 30px;
            padding: 5px;
            font-size: 1rem;
            background-color: $white;
            border: $borderBlack;
            border-radius: 10px;
            &:focus {
              outline: none;
            }
          }
        }
        .flags-container {
          border: $borderWhite;
          border-radius: 20px;
          padding: 20px;
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          justify-content: center;
          align-items: center;
          div {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              width: 50px;
            }
          }
        }
        .audio-each-language {
          display: flex;
          justify-content: space-evenly;
          flex-wrap: wrap;
          div {
            text-align: right;
            i {
              font-size: 2rem;
              width: fit-content;
              text-align: end;
              color: $red;
              cursor: pointer;
              transition: 0.2s ease;
              &:hover {
                color: $red2;
                transform: scale(1.1);
              }
            }
            .center-audio-country {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 10px;
              label {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 1.5rem;
                cursor: pointer;
                img {
                  width: 100px;
                }
              }
              audio {
                min-width: 250px;
              }
            }
          }
        }
      }
      input[type="submit"] {
        display: block;
        width: 50%;
        margin: 20px auto;
        background-color: $white;
        color: $black;
        font-size: large;
        border: 2px solid $black;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
        transition: 0.2s ease;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}