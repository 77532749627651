@charset "UTF-8";
@font-face {
  font-family: "PoppinsMedium";
  src: url(../assets/fonts/Poppins-Medium.ttf);
}
@font-face {
  font-family: "Open Sans";
  src: url("../assets/fonts/OpenSans-Medium.woff2") format("woff2"), url("../assets/fonts/OpenSans-Medium.woff") format("woff"), url("../assets/fonts/OpenSans-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans Regular";
  src: url("../assets/fonts/OpenSans-Regular.woff2") format("woff2"), url("../assets/fonts/OpenSans-Regular.woff") format("woff"), url("../assets/fonts/OpenSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans Bold";
  src: url("../assets/fonts/OpenSans-Bold.woff2") format("woff2"), url("../assets/fonts/OpenSans-Bold.woff") format("woff"), url("../assets/fonts/OpenSans-Bold.ttf") format("truetype");
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: "PoppinsMedium", sans-serif;
  scrollbar-color: #e2b259 black;
  scrollbar-width: thin;
}

body {
  background: black;
}

.logo {
  max-width: 350px;
  max-height: 100%;
  padding-top: 20px;
  margin: 0 auto 20px;
}
@media (max-width: 768px) {
  .logo {
    padding-top: 70px;
  }
}
.logo img {
  width: 100%;
  max-width: 100%;
}

.back-button {
  position: fixed;
  left: 2.5%;
  top: 2.5%;
}
@media (max-width: 768px) {
  .back-button {
    left: 0;
    top: 0;
    width: 100vw;
  }
}
.back-button button {
  background-color: #e2b259;
  font-size: 1rem;
  padding: 20px;
  border: 2px solid linen;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.2s ease;
}
.back-button button:hover {
  background-color: #b1852d;
  transform: scale(1.1);
}
@media (max-width: 768px) {
  .back-button button {
    padding: 10px 0;
    border: none;
    border-radius: 0;
    width: 100%;
  }
}

.partner-entry a {
  color: #e2b259;
  display: flex;
  height: auto;
  margin: 20px 0;
  padding: 30px;
  border: 2px solid #e2b259;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.75);
  align-items: center;
  justify-content: space-around;
  text-align: center;
  transition: 0.2s ease;
}
.partner-entry a:hover {
  background-color: rgba(0, 0, 0, 0.85);
  transform: scale(1.03);
}
@media (max-width: 768px) {
  .partner-entry a {
    padding: 10px;
    margin: 10px 10% 0 10%;
    flex-direction: column;
  }
}
.partner-entry a img {
  padding: 5px;
  min-width: 200px;
  max-width: 200px;
  height: 75px;
  object-fit: contain;
  background-color: linen;
  border-radius: 10px;
}
@media (max-width: 768px) {
  .partner-entry a img {
    min-width: 150px;
    max-width: 150px;
    height: auto;
  }
}
.partner-entry a .text-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
@media (max-width: 768px) {
  .partner-entry a .text-container {
    flex-direction: column;
    align-items: center;
  }
}
.partner-entry a .text-container h2 {
  font-size: 1.5rem;
}
@media (max-width: 768px) {
  .partner-entry a .text-container h2 {
    font-size: 1.3rem;
  }
}
.partner-entry a .text-container p {
  align-self: center;
  font-style: italic;
}
@media (max-width: 768px) {
  .partner-entry a .text-container p span {
    display: none;
  }
}

.city-list-entry-container {
  margin-bottom: 40px;
  margin-top: 20px;
}
.city-list-entry-container a {
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 500px;
  height: 100%;
  margin: 0 auto;
  background-color: black;
  border: 2px solid linen;
  border-radius: 20px;
  transition: 0.3s ease;
  position: relative;
}
@media (max-width: 768px) {
  .city-list-entry-container a {
    width: 80%;
    height: fit-content;
  }
}
.city-list-entry-container a img {
  border-radius: 20px 20px 0 0;
  width: 100%;
  height: 90%;
  max-height: 250px;
  object-fit: cover;
  margin: 0 auto;
  display: block;
}
.city-list-entry-container a .nameDistance {
  color: black;
  width: 100%;
  flex-grow: 1;
  margin: 0 auto;
  font-size: 1.5rem;
  background-color: #e2b259;
  border: hidden;
  align-items: center;
  border-radius: 0 0 17px 17px;
  transition: 0.3s ease;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-end;
}
.city-list-entry-container a .name {
  margin-right: 20%;
}
@media (max-width: 768px) {
  .city-list-entry-container a .name {
    margin-right: 0;
  }
}
.city-list-entry-container a .distance {
  position: absolute;
  bottom: 1px;
  right: 15px;
  font-size: 1rem;
  color: black;
}
@media (max-width: 768px) {
  .city-list-entry-container a .distance {
    bottom: 5px;
    right: 10px;
  }
}
@media (max-width: 1024px) {
  .city-list-entry-container a .distance {
    position: static;
  }
}
.city-list-entry-container a:hover {
  transform: scale(1.05);
}
.city-list-entry-container a:hover button {
  background-color: #b1852d;
}

.nav-admin-container {
  margin: 0 auto;
  padding: 0 20px;
  height: 60px;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: black;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
  border-bottom: 2px solid linen;
  z-index: 1000;
}
.nav-admin-container ul {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}
.nav-admin-container ul li {
  height: 100%;
  width: 100%;
}
.nav-admin-container ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: linen;
  font-size: 1.2rem;
  text-transform: uppercase;
  transition: 0.2s ease;
}
.nav-admin-container ul li a:hover {
  background-color: #b1852d;
  color: linen;
}
.nav-admin-container ul li .nav-active {
  background-color: #e2b259;
  color: black;
}

.header-admin-container h1 {
  position: absolute;
  color: #e2b259;
}
.header-admin-container img {
  width: 100%;
  max-width: 700px;
  margin: 10px auto;
  display: block;
  border-radius: 20px;
  border: 2px solid linen;
  background-color: rgba(0, 0, 0, 0.75);
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.category-entry {
  width: 30%;
  height: 100px;
  border: 2px solid #e2b259;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  text-align: center;
  transition: 0.2s ease;
}
@media (max-width: 1024px) {
  .category-entry {
    width: 45%;
  }
}
@media (max-width: 768px) {
  .category-entry {
    width: 100%;
  }
}
.category-entry:hover {
  background-color: rgba(0, 0, 0, 0.85);
  transform: scale(1.1);
}
.category-entry a {
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.category-entry a h2 {
  font-size: 1.5rem;
  margin: 0;
  color: linen;
}
@media (max-width: 1024px) {
  .category-entry a h2 {
    font-size: 1.4rem;
  }
}
@media (max-width: 768px) {
  .category-entry a h2 {
    font-size: 1.2rem;
  }
}

.install-pwa .install-btn,
.install-pwa .install-text {
  display: block;
  margin: 0 auto 30px;
  padding: 15px;
  width: 100%;
  font-size: 1.2rem;
  color: black;
  max-width: 800px;
  background-color: #e2b259;
  border: 2px solid linen;
  border-radius: 20px;
  text-transform: uppercase;
  text-align: center;
}
@media (max-width: 768px) {
  .install-pwa .install-btn,
  .install-pwa .install-text {
    font-size: 0.5rem;
    padding: 5px;
    width: 80%;
    border-radius: 0 0 20px 20px;
  }
}
.install-pwa .install-btn-geo-info {
  background-color: transparent;
  border: none;
  display: contents;
}
.install-pwa .install-btn-geo-info .geo-info {
  color: white;
  text-align: center;
  margin: 20px auto;
  padding: 10px;
  background-color: red;
  border-radius: 10px;
  border: 2px solid linen;
  max-width: 600px;
}
@media (max-width: 768px) {
  .install-pwa .install-btn-geo-info .geo-info {
    padding: 5px;
    width: 80%;
  }
}
.install-pwa .install-btn {
  cursor: pointer;
}
.install-pwa .install-text {
  font-size: 0.6rem;
}
@media (max-width: 768px) {
  .install-pwa .install-text {
    font-size: 0.45rem;
  }
}
.install-pwa .chrome-btn {
  margin: 15px 0;
  position: relative;
  background-color: linen;
  font-size: 1.2rem;
  padding: 10px;
  border: 2px solid black;
  border-radius: 20px;
  cursor: pointer;
  width: 50%;
}
.install-pwa .chrome-btn:hover {
  background-color: #b1852d;
  transform: scale(1.1);
}
@media (max-width: 768px) {
  .install-pwa .chrome-btn {
    font-size: 1rem;
    width: 60%;
  }
}
.install-pwa .popup-content {
  position: relative;
  background-color: black;
  border: 2px solid rgb(175, 33, 33);
  border-radius: 10px;
  padding: 30px;
  width: 60vw;
  height: 60vh;
  max-width: 700px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media (max-width: 768px) {
  .install-pwa .popup-content {
    width: 90vw;
    height: 70vh;
    max-width: 500px;
    padding: 20px;
  }
}
.install-pwa .popup-content .apple_choose {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  gap: 20px;
}
.install-pwa .popup-welcome {
  height: auto;
}
@media (max-width: 768px) {
  .install-pwa .popup-welcome {
    height: auto;
  }
}
.install-pwa .popup-welcome h1 {
  margin-bottom: 10px;
}
.install-pwa .popup-instruction {
  background-color: linen;
  justify-content: flex-start;
  max-height: 1000px;
  height: 80vh;
  overflow-y: auto;
  padding: 30px;
}
.install-pwa .popup-instruction p {
  margin: 15px 0 25px 0;
  font-size: 1rem;
}
.install-pwa .popup-instruction #majuscule {
  margin-bottom: 5px;
}
.install-pwa .popup-instruction #minuscume {
  margin-top: 5px;
  text-transform: none;
}
.install-pwa .popup-instruction img {
  max-width: 100%;
  max-height: 300px;
  object-fit: contain;
  margin-bottom: 25px;
}
.install-pwa .popup-instruction .close-button-instruction {
  top: 10px;
  bottom: auto;
  right: 10px;
  font-size: 1.2rem;
}
.install-pwa .popup-instruction .img-chrome-safari p {
  margin: 5px 0;
}
.install-pwa .popup-iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.install-pwa .close-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: rgb(175, 33, 33);
  cursor: pointer;
}
.install-pwa .close-button:hover {
  color: rgb(255, 0, 0);
}

.image-gallery {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.9);
  padding: 50px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.6);
  width: 80%;
  height: 90%;
}
@media (max-width: 1024px) {
  .image-gallery {
    width: 95%;
    height: 70%;
    padding: 10px;
  }
}
.image-gallery .bottom-controls {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.image-gallery .bottom-controls button {
  background: rgba(255, 0, 0, 0.8);
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  transition: background 0.3s;
  margin: 0 10px;
}
.image-gallery .bottom-controls button:hover {
  background: rgba(255, 0, 0, 0.8);
}

.image-gallery img {
  max-width: 100%;
  max-height: 80vh;
  border-radius: 8px;
  margin: 10px 0;
}

.image-gallery .controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin-top: 10px;
}

.image-gallery button {
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  transition: background 0.3s;
}

.image-gallery button:hover {
  background: rgba(255, 255, 255, 0.5);
}

.image-gallery .close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(255, 0, 0, 0.8);
  color: white;
  font-size: 1.5rem;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s;
}

.image-gallery .close-button:hover {
  background: rgb(255, 0, 0);
}

.image-gallery .prev-button,
.image-gallery .next-button {
  opacity: 0;
  pointer-events: auto;
  display: block;
  position: absolute;
  height: 40%;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 2rem;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.3s;
}

.image-gallery .prev-button {
  left: 10px;
}

.image-gallery .next-button {
  right: 10px;
}

.image-gallery .prev-button:hover,
.image-gallery .next-button:hover {
  background: rgba(0, 0, 0, 0.8);
  opacity: 1;
}

@media (max-width: 1024px) {
  .image-gallery .prev-button:hover,
  .image-gallery .next-button:hover {
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
  }
}
.news-list-container {
  width: 25%;
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  overflow-y: auto;
}
.news-list-container.link {
  max-height: 50vh;
}
.news-list-container.manual {
  max-height: 80vh;
}

.news-item {
  background: #e2b259;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.news-item img {
  border-radius: 4px;
  margin-bottom: 10px;
}

.news-item h4 {
  margin: 0;
  font-size: 16px;
  color: black;
}

.news-item p {
  margin-top: 10px;
  color: white;
}

.popup-info-partner .popup-info-partner-container {
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 30px;
  width: 30%;
}
.popup-info-partner .popup-info-partner-container .popup-info-partner-header {
  display: flex;
  justify-content: right;
  align-items: start;
  margin-bottom: 20px;
  margin-left: 32px;
}
.popup-info-partner .popup-info-partner-container .popup-info-partner-header img {
  max-width: 80%;
  max-height: 200px;
  border-radius: 20px;
  margin: 0 auto;
}
.popup-info-partner .popup-info-partner-container .popup-info-partner-header i {
  font-size: 2rem;
  color: rgb(175, 33, 33);
  cursor: pointer;
  transition: 0.2s ease;
}
.popup-info-partner .popup-info-partner-container .popup-info-partner-header i:hover {
  color: rgb(255, 0, 0);
}
.popup-info-partner .popup-info-partner-container .popup-info-partner-content {
  text-align: center;
}
.popup-info-partner .popup-info-partner-container .popup-info-partner-content h3 {
  font-size: 2rem;
}
.popup-info-partner .popup-info-partner-container .popup-info-partner-content .social-media-partner a i {
  cursor: pointer;
  color: black;
  font-size: 3rem;
  transition: 0.2s ease;
}
.popup-info-partner .popup-info-partner-container .popup-info-partner-content .social-media-partner a i:hover {
  transform: scale(1.1);
}

.popup-content-partner .popup-content-partner-container {
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 30px;
  width: 40%;
  text-align: center;
  max-height: 95vh;
  overflow-y: scroll;
}
@media (max-width: 1340px) {
  .popup-content-partner .popup-content-partner-container {
    width: 50%;
  }
}
@media (max-width: 1024px) {
  .popup-content-partner .popup-content-partner-container {
    width: 60%;
  }
}
@media (max-width: 768px) {
  .popup-content-partner .popup-content-partner-container {
    width: 80%;
  }
}
.popup-content-partner .popup-content-partner-container .popup-content-partner-header {
  display: flex;
  justify-content: right;
  align-items: start;
  margin-bottom: 20px;
  margin-left: 32px;
}
.popup-content-partner .popup-content-partner-container .popup-content-partner-header img {
  max-width: 80%;
  max-height: 200px;
  border-radius: 20px;
  margin: 0 auto;
}
.popup-content-partner .popup-content-partner-container .popup-content-partner-header i {
  font-size: 2rem;
  color: rgb(175, 33, 33);
  cursor: pointer;
  transition: 0.2s ease;
}
.popup-content-partner .popup-content-partner-container .popup-content-partner-header i:hover {
  color: rgb(255, 0, 0);
}
.popup-content-partner .popup-content-partner-container .popup-content-partner-text h3 {
  font-size: 2rem;
}
.popup-content-partner .popup-content-partner-container .popup-content-partner-text p {
  font-size: 1.3rem;
}
.popup-content-partner .popup-content-partner-container .popup-content-partner-uploads {
  margin: 20px 0;
}
.popup-content-partner .popup-content-partner-container .popup-content-partner-uploads .popup-content-uploads-container {
  padding: 5px;
}
.popup-content-partner .popup-content-partner-container .popup-content-partner-uploads .popup-content-uploads-container img {
  max-width: 100%;
  max-height: 150px;
  border-radius: 10px;
  margin-right: 20px;
}
.popup-content-partner .popup-content-partner-container .popup-content-partner-uploads .popup-content-uploads-container video {
  max-width: 100%;
  max-height: 150px;
  border-radius: 10px;
  margin-right: 20px;
}

.update-partner .popup-update-container {
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 20px;
  width: 70%;
  max-height: 95vh;
  overflow-y: scroll;
}
.update-partner .popup-update-container i {
  font-size: 2rem;
  color: rgb(175, 33, 33);
  cursor: pointer;
  transition: 0.2s ease;
}
.update-partner .popup-update-container i:hover {
  color: rgb(255, 0, 0);
}
.update-partner .popup-update-container .popup-update-partner-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 20px;
}
.update-partner .popup-update-container .popup-update-partner-header h2 {
  font-size: 1.8rem;
}
.update-partner .popup-update-container .popup-update-partner-header .cancel-button {
  display: flex;
  align-items: center;
  color: rgb(175, 33, 33);
  cursor: pointer;
}
.update-partner .popup-update-container .popup-update-partner-header .cancel-button p {
  transition: 0.2s ease;
}
.update-partner .popup-update-container .popup-update-partner-header .cancel-button:hover p, .update-partner .popup-update-container .popup-update-partner-header .cancel-button:hover i {
  color: rgb(255, 0, 0);
}
.update-partner .popup-update-container form .update-partner-setup .info-update-partner {
  text-align: center;
}
.update-partner .popup-update-container form .update-partner-setup .info-update-partner h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}
.update-partner .popup-update-container form .update-partner-setup .info-update-partner .info-update-partner-container, .update-partner .popup-update-container form .update-partner-setup .info-update-partner .link-update-partner {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  max-height: 60px;
  margin-bottom: 20px;
}
.update-partner .popup-update-container form .update-partner-setup .info-update-partner .info-update-partner-container input, .update-partner .popup-update-container form .update-partner-setup .info-update-partner .link-update-partner input {
  width: 23%;
  height: 30px;
  padding: 3px;
  background-color: linen;
  border: 2px solid black;
  border-radius: 10px;
}
.update-partner .popup-update-container form .update-partner-setup .info-update-partner .info-update-partner-container input:focus, .update-partner .popup-update-container form .update-partner-setup .info-update-partner .link-update-partner input:focus {
  outline: none;
}
.update-partner .popup-update-container form .update-partner-setup .info-update-partner .adress-update, .update-partner .popup-update-container form .update-partner-setup .info-update-partner .url-update, .update-partner .popup-update-container form .update-partner-setup .info-update-partner .coordinates-update {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  max-height: 60px;
  margin-bottom: 20px;
}
.update-partner .popup-update-container form .update-partner-setup .info-update-partner .adress-update .city-select-container, .update-partner .popup-update-container form .update-partner-setup .info-update-partner .url-update .city-select-container, .update-partner .popup-update-container form .update-partner-setup .info-update-partner .coordinates-update .city-select-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 23%;
  margin-bottom: 20px;
}
.update-partner .popup-update-container form .update-partner-setup .info-update-partner .adress-update .city-select-container label, .update-partner .popup-update-container form .update-partner-setup .info-update-partner .url-update .city-select-container label, .update-partner .popup-update-container form .update-partner-setup .info-update-partner .coordinates-update .city-select-container label {
  margin-bottom: 5px;
}
.update-partner .popup-update-container form .update-partner-setup .info-update-partner .adress-update input, .update-partner .popup-update-container form .update-partner-setup .info-update-partner .url-update input, .update-partner .popup-update-container form .update-partner-setup .info-update-partner .coordinates-update input {
  width: 23%;
  height: 30px;
  padding: 3px;
  background-color: linen;
  border: 2px solid black;
  border-radius: 10px;
}
.update-partner .popup-update-container form .update-partner-setup .info-update-partner .adress-update input:focus, .update-partner .popup-update-container form .update-partner-setup .info-update-partner .url-update input:focus, .update-partner .popup-update-container form .update-partner-setup .info-update-partner .coordinates-update input:focus {
  outline: none;
}
.update-partner .popup-update-container form .update-partner-setup .info-update-partner .url-update {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.update-partner .popup-update-container form .update-partner-setup .info-update-partner .url-update input {
  width: 50%;
  height: 30px;
  padding: 3px;
  background-color: linen;
  border: 2px solid black;
  border-radius: 10px;
}
.update-partner .popup-update-container form .update-partner-setup .info-update-partner .url-update input:focus {
  outline: none;
}
.update-partner .popup-update-container form .update-partner-setup .info-update-partner .url-update #subDomain {
  width: 20%;
}
.update-partner .popup-update-container form .update-partner-setup .info-update-partner .url-update button {
  position: absolute;
  background-color: linen;
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
  top: 20px;
  right: 3%;
}
.update-partner .popup-update-container form .update-partner-setup .info-update-partner .url-update button:hover {
  transform: scale(1.1);
}
.update-partner .popup-update-container form .update-partner-setup .description-update-partner {
  margin: 10px;
}
.update-partner .popup-update-container form .update-partner-setup .description-update-partner textarea {
  width: 100%;
  height: 150px;
  padding: 10px;
  background-color: linen;
  border: 2px solid black;
  border-radius: 10px;
  resize: none;
}
.update-partner .popup-update-container form .update-partner-setup .description-update-partner textarea:focus {
  outline: none;
}
.update-partner .popup-update-container form .update-partner-setup .categories-update-partner {
  text-align: center;
}
.update-partner .popup-update-container form .update-partner-setup .categories-update-partner h3 {
  font-size: 1.5rem;
  margin: 23px 0 15px 0;
}
.update-partner .popup-update-container form .update-partner-setup .social-media-update-partner {
  text-align: center;
}
.update-partner .popup-update-container form .update-partner-setup .social-media-update-partner h3 {
  font-size: 1.5rem;
  margin: 23px 0 15px 0;
}
.update-partner .popup-update-container form .update-partner-setup .social-media-update-partner .social-media-update-partner-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.update-partner .popup-update-container form .update-partner-setup .social-media-update-partner .social-media-update-partner-container input {
  width: 95%;
  height: 30px;
  padding: 3px;
  background-color: linen;
  border: 2px solid black;
  border-radius: 10px;
}
.update-partner .popup-update-container form .update-partner-setup .social-media-update-partner .social-media-update-partner-container input:focus {
  outline: none;
}
.update-partner .popup-update-container form .update-partner-setup .social-media-update-partner .social-media-update-partner-container .social-media-update {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.update-partner .popup-update-container form .update-partner-content h3 {
  font-size: 1.5rem;
  margin: 23px 0 15px 0;
  text-align: center;
}
.update-partner .popup-update-container form .update-partner-content .update-partner-content-container {
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.update-partner .popup-update-container form .update-partner-content .update-partner-content-container label {
  display: flex;
  align-items: center;
  padding: 7px;
  height: 50px;
  background: linen;
  border: 2px solid black;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
.update-partner .popup-update-container form .update-partner-content .update-partner-content-container label:hover {
  transform: scale(1.1);
}
.update-partner .popup-update-container form .uploaded-logo {
  margin-left: 35px;
}
.update-partner .popup-update-container form .uploaded-logo img {
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 5px 0;
}
.update-partner .popup-update-container form .uploaded-files-container {
  width: 95%;
  margin: 0 auto;
  border: 2px solid linen;
  border-radius: 10px;
}
.update-partner .popup-update-container form .uploaded-files-container legend {
  margin-left: 20px;
  color: black;
  font-size: 1.3rem;
}
.update-partner .popup-update-container form .uploaded-files-container .uploaded-files {
  display: flex;
  flex-wrap: wrap;
}
.update-partner .popup-update-container form .uploaded-files-container .uploaded-files i {
  color: rgb(175, 33, 33);
  font-size: 2rem;
  cursor: pointer;
  transition: 0.2s ease;
}
.update-partner .popup-update-container form .uploaded-files-container .uploaded-files i:hover {
  color: rgb(255, 0, 0);
  transform: scale(1.1);
}
.update-partner .popup-update-container form .uploaded-files-container .uploaded-files .picture-new-partner-container {
  display: flex;
  align-items: start;
  margin: 5px 25px 5px 0;
}
.update-partner .popup-update-container form .uploaded-files-container .uploaded-files .picture-new-partner-container img {
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 5px 10px;
}
.update-partner .popup-update-container form .uploaded-files-container .uploaded-files .video-new-partner-container {
  display: flex;
  align-items: start;
  margin: 5px 25px 5px 0;
}
.update-partner .popup-update-container form .uploaded-files-container .uploaded-files .video-new-partner-container video {
  border-radius: 10px;
  border: 2px solid #e2b259;
  cursor: pointer;
}
.update-partner .popup-update-container form input[type=submit]#valid {
  display: block;
  width: 50%;
  margin: 20px auto;
  background-color: linen;
  color: black;
  font-size: large;
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
.update-partner .popup-update-container form input[type=submit]#valid:hover {
  transform: scale(1.1);
}
.update-partner .popup-update-container form input[type=submit]#invalid {
  display: block;
  width: 50%;
  margin: 20px auto;
  background-color: black;
  color: linen;
  font-size: large;
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
  cursor: not-allowed;
  transition: 0.2s ease;
}
.update-partner .popup-update-container form .logo-interview {
  display: flex;
}
.update-partner .popup-update-container form .logo-interview .uploaded-logo {
  margin-left: 35px;
}
.update-partner .popup-update-container form .logo-interview .uploaded-logo img {
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 5px 0;
}
.update-partner .popup-update-container .interview-update-partner {
  text-align: center;
  margin: 20px 0;
}
.update-partner .popup-update-container .interview-update-partner h3 {
  font-size: 1.5rem;
  margin: 23px 0 15px 0;
}
.update-partner .popup-update-container .interview-update-partner .interview-informaton-update {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.update-partner .popup-update-container .interview-update-partner .interview-informaton-update .interview-update {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.update-partner .popup-update-container .interview-update-partner .interview-informaton-update label {
  margin: 10px 0 5px;
}
.update-partner .popup-update-container .interview-update-partner .interview-informaton-update input, .update-partner .popup-update-container .interview-update-partner .interview-informaton-update textarea {
  width: 50%;
  height: 30px;
  padding: 3px;
  background-color: linen;
  border: 2px solid black;
  border-radius: 10px;
  margin-bottom: 10px;
}
.update-partner .popup-update-container .interview-update-partner .interview-informaton-update input:focus, .update-partner .popup-update-container .interview-update-partner .interview-informaton-update textarea:focus {
  outline: none;
}
.update-partner .popup-update-container .interview-update-partner .interview-text-update {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
.update-partner .popup-update-container .interview-update-partner .interview-text-update .text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30%;
}
.update-partner .popup-update-container .interview-update-partner .interview-text-update label {
  margin-bottom: 5px;
}
.update-partner .popup-update-container .interview-update-partner .interview-text-update textarea {
  width: 100%;
  height: 70px;
  padding: 10px;
  background-color: linen;
  border: 2px solid black;
  border-radius: 10px;
  resize: none;
}
.update-partner .popup-update-container .interview-update-partner .interview-text-update textarea:focus {
  outline: none;
}
.update-partner .popup-update-container .interview-update-partner .interview-text-update button {
  width: 80%;
  padding: 10px;
  background-color: white;
  color: black;
  border: 2px solid black;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.update-partner .popup-update-container .interview-update-partner .interview-text-update button:hover {
  background-color: #e2b259;
}
.update-partner .popup-update-container .interview-update-partner .interview-text-update .modal-content {
  width: 80%;
  max-width: 800px;
}
.update-partner .popup-update-container .interview-update-partner .interview-text-update .modal-content .textarea-container {
  position: relative;
}
.update-partner .popup-update-container .interview-update-partner .interview-text-update .modal-content .textarea-container .modal-close {
  position: absolute;
  width: 30px;
  top: 2px;
  right: 10px;
  font-size: 1rem;
  background-color: #e2b259;
  border: none;
  cursor: pointer;
  color: rgb(175, 33, 33);
}
.update-partner .popup-update-container .interview-update-partner .interview-text-update .modal-content .textarea-container .modal-close:hover {
  color: rgb(255, 0, 0);
  background-color: white;
}

.popup-city-social-media .popup-content-city-container {
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 30px;
  width: 30%;
}
.popup-city-social-media .popup-content-city-container .popup-content-city-header {
  display: flex;
  justify-content: right;
  align-items: start;
  margin-bottom: 20px;
  margin-left: 32px;
}
.popup-city-social-media .popup-content-city-container .popup-content-city-header img {
  max-width: 80%;
  max-height: 200px;
  border-radius: 10px;
  margin: 0 auto;
  padding: 5px;
  filter: contrast(0%);
}
.popup-city-social-media .popup-content-city-container .popup-content-city-header i {
  font-size: 2rem;
  color: rgb(175, 33, 33);
  cursor: pointer;
  transition: 0.2s ease;
}
.popup-city-social-media .popup-content-city-container .popup-content-city-header i:hover {
  color: rgb(255, 0, 0);
}
.popup-city-social-media .popup-content-city-container .popup-content-city-social-media {
  text-align: center;
}
.popup-city-social-media .popup-content-city-container .popup-content-city-social-media h3 {
  font-size: 2rem;
}
.popup-city-social-media .popup-content-city-container .popup-content-city-social-media a i {
  cursor: pointer;
  color: black;
  font-size: 3rem;
  transition: 0.2s ease;
}
.popup-city-social-media .popup-content-city-container .popup-content-city-social-media a i:hover {
  transform: scale(1.1);
}

.popup-city-update .popup-content-city-update-container {
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 20px;
  width: 70%;
  max-height: 95vh;
  overflow-y: auto;
}
.popup-city-update .popup-content-city-update-container i {
  font-size: 2rem;
  color: rgb(175, 33, 33);
  cursor: pointer;
  transition: 0.2s ease;
}
.popup-city-update .popup-content-city-update-container i:hover {
  color: rgb(255, 0, 0);
}
.popup-city-update .popup-content-city-update-container .popup-city-update-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 20px;
}
.popup-city-update .popup-content-city-update-container .popup-city-update-header h2 {
  font-size: 1.8rem;
}
.popup-city-update .popup-content-city-update-container .popup-city-update-header .cancel-button {
  display: flex;
  align-items: center;
  color: rgb(175, 33, 33);
  cursor: pointer;
}
.popup-city-update .popup-content-city-update-container .popup-city-update-header .cancel-button p {
  transition: 0.2s ease;
}
.popup-city-update .popup-content-city-update-container .popup-city-update-header .cancel-button:hover p, .popup-city-update .popup-content-city-update-container .popup-city-update-header .cancel-button:hover i {
  color: rgb(255, 0, 0);
}
.popup-city-update .popup-content-city-update-container form .update-city-setup .info-update-city {
  text-align: center;
}
.popup-city-update .popup-content-city-update-container form .update-city-setup .info-update-city h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}
.popup-city-update .popup-content-city-update-container form .update-city-setup .info-update-city .info-update-city-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  max-height: 60px;
}
.popup-city-update .popup-content-city-update-container form .update-city-setup .info-update-city .info-update-city-container input {
  width: 23%;
  height: 30px;
  padding: 3px;
  background-color: linen;
  border: 2px solid black;
  border-radius: 10px;
}
.popup-city-update .popup-content-city-update-container form .update-city-setup .info-update-city .info-update-city-container input:focus {
  outline: none;
}
.popup-city-update .popup-content-city-update-container form .update-city-setup .info-update-city .social-media-update-city {
  text-align: center;
}
.popup-city-update .popup-content-city-update-container form .update-city-setup .info-update-city .social-media-update-city h3 {
  font-size: 1.5rem;
  margin: 23px 0 15px 0;
}
.popup-city-update .popup-content-city-update-container form .update-city-setup .info-update-city .social-media-update-city .social-media-update-city-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.popup-city-update .popup-content-city-update-container form .update-city-setup .info-update-city .social-media-update-city .social-media-update-city-container input {
  width: 95%;
  height: 30px;
  padding: 3px;
  background-color: linen;
  border: 2px solid black;
  border-radius: 10px;
}
.popup-city-update .popup-content-city-update-container form .update-city-setup .info-update-city .social-media-update-city .social-media-update-city-container input:focus {
  outline: none;
}
.popup-city-update .popup-content-city-update-container form .update-city-setup .info-update-city .social-media-update-city .social-media-update-city-container .social-media-update {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popup-city-update .popup-content-city-update-container form .uploads-update-city {
  margin-top: 30px;
}
.popup-city-update .popup-content-city-update-container form .uploads-update-city .uploads-update-city-container {
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.popup-city-update .popup-content-city-update-container form .uploads-update-city .uploads-update-city-container .button-uploads-container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
.popup-city-update .popup-content-city-update-container form .uploads-update-city .uploads-update-city-container .button-uploads-container label {
  display: flex;
  align-items: center;
  padding: 7px;
  height: 50px;
  background: linen;
  border: 2px solid black;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
.popup-city-update .popup-content-city-update-container form .uploads-update-city .uploads-update-city-container .button-uploads-container label:hover {
  transform: scale(1.1);
}
.popup-city-update .popup-content-city-update-container form .uploads-update-city .uploads-update-city-container .render-uploads-update-city {
  display: flex;
  align-items: center;
}
.popup-city-update .popup-content-city-update-container form .uploads-update-city .uploads-update-city-container .render-uploads-update-city img {
  margin: 20px;
  max-width: 300px;
}
.popup-city-update .popup-content-city-update-container form .uploads-update-city .uploads-update-city-container .render-uploads-update-city .city-logo {
  filter: contrast(0%);
}
.popup-city-update .popup-content-city-update-container form input[type=submit]#valid {
  display: block;
  width: 50%;
  margin: 20px auto;
  background-color: linen;
  color: black;
  font-size: large;
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
.popup-city-update .popup-content-city-update-container form input[type=submit]#valid:hover {
  transform: scale(1.1);
}
.popup-city-update .popup-content-city-update-container form input[type=submit]#invalid {
  display: block;
  width: 50%;
  margin: 20px auto;
  background-color: black;
  color: linen;
  font-size: large;
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
  cursor: not-allowed;
  transition: 0.2s ease;
}

.popup-new-guided-tour .popup-content-new-guided-tour-container {
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 20px;
  width: 80%;
  max-height: 95vh;
  overflow-y: auto;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container i {
  font-size: 2rem;
  color: rgb(175, 33, 33);
  cursor: pointer;
  transition: 0.2s ease;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container i:hover {
  color: rgb(255, 0, 0);
}
.popup-new-guided-tour .popup-content-new-guided-tour-container .popup-guided-tour-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 20px;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container .popup-guided-tour-header h2 {
  font-size: 1.8rem;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container .popup-guided-tour-header .cancel-button {
  display: flex;
  align-items: center;
  color: rgb(175, 33, 33);
  cursor: pointer;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container .popup-guided-tour-header .cancel-button p {
  transition: 0.2s ease;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container .popup-guided-tour-header .cancel-button:hover p, .popup-new-guided-tour .popup-content-new-guided-tour-container .popup-guided-tour-header .cancel-button:hover i {
  color: rgb(255, 0, 0);
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form section.guided-tour-infos .label-info-guided-tour-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  max-height: 60px;
  margin-bottom: 40px;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form section.guided-tour-infos .label-info-guided-tour-container input {
  width: 23%;
  height: 30px;
  padding: 3px;
  background-color: linen;
  border: 2px solid black;
  border-radius: 10px;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form section.guided-tour-infos .label-info-guided-tour-container input:focus {
  outline: none;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form section.guided-tour-infos .label-info-guided-tour-container .label-btn {
  display: flex;
  align-items: center;
  padding: 7px;
  height: 50px;
  background: linen;
  border: 2px solid black;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form section.guided-tour-infos .label-info-guided-tour-container .label-btn:hover {
  transform: scale(1.1);
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form section.guided-tour-infos img {
  display: block;
  max-width: 600px;
  max-height: 300px;
  margin: 20px auto;
  border: 2px solid linen;
  border-radius: 10px;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form section.guided-tour-audio {
  text-align: center;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form section.guided-tour-audio .searchbar-country {
  margin-bottom: 20px;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form section.guided-tour-audio .searchbar-country label {
  font-size: 1.3rem;
  margin-right: 20px;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form section.guided-tour-audio .searchbar-country input {
  width: 23%;
  height: 30px;
  padding: 5px;
  font-size: 1rem;
  background-color: linen;
  border: 2px solid black;
  border-radius: 10px;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form section.guided-tour-audio .searchbar-country input:focus {
  outline: none;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form section.guided-tour-audio .flags-container {
  border: 2px solid linen;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form section.guided-tour-audio .flags-container div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form section.guided-tour-audio .flags-container div img {
  width: 50px;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form section.guided-tour-audio .audio-each-language {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form section.guided-tour-audio .audio-each-language div {
  text-align: right;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form section.guided-tour-audio .audio-each-language div i {
  font-size: 2rem;
  width: fit-content;
  text-align: end;
  color: rgb(175, 33, 33);
  cursor: pointer;
  transition: 0.2s ease;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form section.guided-tour-audio .audio-each-language div i:hover {
  color: rgb(255, 0, 0);
  transform: scale(1.1);
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form section.guided-tour-audio .audio-each-language div .center-audio-country {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form section.guided-tour-audio .audio-each-language div .center-audio-country label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form section.guided-tour-audio .audio-each-language div .center-audio-country label img {
  width: 100px;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form section.guided-tour-audio .audio-each-language div .center-audio-country audio {
  min-width: 250px;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form input[type=submit] {
  display: block;
  width: 50%;
  margin: 20px auto;
  background-color: linen;
  color: black;
  font-size: large;
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
.popup-new-guided-tour .popup-content-new-guided-tour-container form input[type=submit]:hover {
  transform: scale(1.1);
}

.update-guided-tour .popup-update-container {
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 20px;
  width: 70%;
  max-height: 95vh;
  overflow-y: scroll;
}
.update-guided-tour .popup-update-container i {
  font-size: 2rem;
  color: rgb(175, 33, 33);
  cursor: pointer;
  transition: 0.2s ease;
}
.update-guided-tour .popup-update-container i:hover {
  color: rgb(255, 0, 0);
}
.update-guided-tour .popup-update-container .popup-update-guided-tour-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 20px;
}
.update-guided-tour .popup-update-container .popup-update-guided-tour-header h2 {
  font-size: 1.8rem;
}
.update-guided-tour .popup-update-container .popup-update-guided-tour-header .cancel-button {
  display: flex;
  align-items: center;
  color: rgb(175, 33, 33);
  cursor: pointer;
}
.update-guided-tour .popup-update-container .popup-update-guided-tour-header .cancel-button p {
  transition: 0.2s ease;
}
.update-guided-tour .popup-update-container .popup-update-guided-tour-header .cancel-button:hover p, .update-guided-tour .popup-update-container .popup-update-guided-tour-header .cancel-button:hover i {
  color: rgb(255, 0, 0);
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour {
  text-align: center;
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour .info-update-guided-tour-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  max-height: 60px;
  margin-bottom: 20px;
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour .info-update-guided-tour-container input {
  width: 23%;
  height: 30px;
  padding: 3px;
  background-color: linen;
  border: 2px solid black;
  border-radius: 10px;
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour .info-update-guided-tour-container input:focus {
  outline: none;
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour .update-guided-tour-content {
  display: flex;
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour .update-guided-tour-content img {
  display: block;
  max-width: 600px;
  max-height: 300px;
  margin: 20px auto;
  border: 2px solid linen;
  border-radius: 10px;
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour .update-guided-tour-content .label-btn {
  align-items: center;
  padding: 7px;
  height: 50px;
  background: linen;
  border: 2px solid black;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour .update-guided-tour-content .label-btn:hover {
  transform: scale(1.1);
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio {
  text-align: center;
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .searchbar-country {
  margin-bottom: 20px;
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .searchbar-country label {
  font-size: 1.3rem;
  margin-right: 20px;
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .searchbar-country input {
  width: 23%;
  height: 30px;
  padding: 5px;
  font-size: 1rem;
  background-color: linen;
  border: 2px solid black;
  border-radius: 10px;
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .searchbar-country input:focus {
  outline: none;
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .flags-container {
  border: 2px solid linen;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .flags-container div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .flags-container div img {
  width: 50px;
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .audio-each-language {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .audio-each-language div {
  text-align: right;
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .audio-each-language div i {
  font-size: 2rem;
  width: fit-content;
  text-align: end;
  color: rgb(175, 33, 33);
  cursor: pointer;
  transition: 0.2s ease;
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .audio-each-language div i:hover {
  color: rgb(255, 0, 0);
  transform: scale(1.1);
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .audio-each-language div .center-audio-country {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .audio-each-language div .center-audio-country label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .audio-each-language div .center-audio-country label img {
  width: 100px;
}
.update-guided-tour .popup-update-container form .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .audio-each-language div .center-audio-country audio {
  min-width: 250px;
}
.update-guided-tour .popup-update-container form .update-guided-tour-footer {
  display: flex;
}
.update-guided-tour .popup-update-container form .update-guided-tour-footer input[type=submit] {
  width: 50%;
  margin: 20px auto auto 25%;
  background-color: linen;
  color: black;
  font-size: large;
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
.update-guided-tour .popup-update-container form .update-guided-tour-footer input[type=submit]:hover {
  transform: scale(1.1);
}
.update-guided-tour .popup-update-container form .update-guided-tour-footer .delete-button {
  margin: 20px 0 20px 0;
  background-color: rgb(175, 33, 33);
  color: linen;
  font-size: large;
  border: 2px solid rgb(175, 33, 33);
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
.update-guided-tour .popup-update-container form .update-guided-tour-footer .delete-button:hover {
  background-color: rgb(255, 0, 0);
  border-color: rgb(255, 0, 0);
  transform: scale(1.1);
}

.popup-Admin-Suppression {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: black;
}
.popup-Admin-Suppression .popup-content {
  background: linen;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  position: relative;
}
.popup-Admin-Suppression .popup-content .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.2s ease;
}
.popup-Admin-Suppression .popup-content .close-button:hover {
  color: rgb(175, 33, 33);
}
.popup-Admin-Suppression .popup-content h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #e2b259;
}
.popup-Admin-Suppression .popup-content p {
  margin-bottom: 20px;
  font-size: 1rem;
}
.popup-Admin-Suppression .popup-content .confirm-button,
.popup-Admin-Suppression .popup-content .cancel-button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.2s ease;
}
.popup-Admin-Suppression .popup-content .confirm-button.confirm-button,
.popup-Admin-Suppression .popup-content .cancel-button.confirm-button {
  background: red;
  color: linen;
}
.popup-Admin-Suppression .popup-content .confirm-button.confirm-button:hover,
.popup-Admin-Suppression .popup-content .cancel-button.confirm-button:hover {
  background: #cc0000;
}
.popup-Admin-Suppression .popup-content .confirm-button.cancel-button,
.popup-Admin-Suppression .popup-content .cancel-button.cancel-button {
  background: gray;
  color: linen;
}
.popup-Admin-Suppression .popup-content .confirm-button.cancel-button:hover,
.popup-Admin-Suppression .popup-content .cancel-button.cancel-button:hover {
  background: rgb(102.5, 102.5, 102.5);
}

/* Styles pour le popup de mise à jour des utilisateurs */
.popup.update-user .popup-update-container {
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 20px;
  width: 70%;
  max-height: 95vh;
  overflow-y: scroll;
}
.popup.update-user .popup-update-container i {
  font-size: 2rem;
  color: rgb(175, 33, 33);
  cursor: pointer;
  transition: 0.2s ease;
}
.popup.update-user .popup-update-container i:hover {
  color: rgb(255, 0, 0);
}
.popup.update-user .popup-update-container .popup-update-user-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 20px;
}
.popup.update-user .popup-update-container .popup-update-user-header h2 {
  font-size: 1.8rem;
}
.popup.update-user .popup-update-container .popup-update-user-header .cancel-button {
  display: flex;
  align-items: center;
  color: rgb(175, 33, 33);
  cursor: pointer;
}
.popup.update-user .popup-update-container .popup-update-user-header .cancel-button p {
  transition: 0.2s ease;
}
.popup.update-user .popup-update-container .popup-update-user-header .cancel-button:hover p, .popup.update-user .popup-update-container .popup-update-user-header .cancel-button:hover i {
  color: rgb(255, 0, 0);
}
.popup.update-user .popup-update-container form .form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.popup.update-user .popup-update-container form .form-row .form-group {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.popup.update-user .popup-update-container form .form-row .form-group label {
  font-weight: bold;
  margin-bottom: 5px;
}
.popup.update-user .popup-update-container form .form-row .form-group input, .popup.update-user .popup-update-container form .form-row .form-group textarea {
  width: 90%;
  padding: 10px;
  font-size: 1rem;
  border: 2px solid black;
  border-radius: 10px;
  transition: border-color 0.3s;
  background-color: linen;
}
.popup.update-user .popup-update-container form .form-row .form-group input:focus, .popup.update-user .popup-update-container form .form-row .form-group textarea:focus {
  border-color: #007bff;
  outline: none;
}
.popup.update-user .popup-update-container form .form-row .form-group textarea {
  resize: vertical;
}
.popup.update-user .popup-update-container form .update-partner-content-container {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.popup.update-user .popup-update-container form .update-partner-content-container label {
  display: flex;
  align-items: center;
  padding: 7px;
  height: 50px;
  background: linen;
  border: 2px solid black;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
.popup.update-user .popup-update-container form .update-partner-content-container label:hover {
  transform: scale(1.1);
}
.popup.update-user .popup-update-container form input[type=submit] {
  display: block;
  width: 50%;
  margin: 20px auto;
  background-color: linen;
  color: black;
  font-size: large;
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
.popup.update-user .popup-update-container form input[type=submit]:hover {
  transform: scale(1.1);
}
.popup.update-user .popup-update-container form input[type=submit]#invalid {
  background-color: black;
  color: linen;
  cursor: not-allowed;
}
.popup.update-user .popup-update-container section {
  margin-bottom: 30px;
}
.popup.update-user .popup-update-container section h3 {
  margin-bottom: 15px;
  text-align: center;
  font-size: 1.5rem;
}
.popup.update-user .popup-update-container section .form-row {
  margin-bottom: 20px;
}

.popup-magazine .popup-content-magazine-container {
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 20px;
  width: 80%;
  max-height: 95vh;
  overflow-y: auto;
}
.popup-magazine .popup-content-magazine-container i {
  font-size: 2rem;
  color: rgb(175, 33, 33);
  cursor: pointer;
  transition: 0.2s ease;
}
.popup-magazine .popup-content-magazine-container i:hover {
  color: rgb(255, 0, 0);
}
.popup-magazine .popup-content-magazine-container .popup-magazine-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 20px;
}
.popup-magazine .popup-content-magazine-container .popup-magazine-header h2 {
  font-size: 1.8rem;
}
.popup-magazine .popup-content-magazine-container .popup-magazine-header .cancel-button {
  display: flex;
  align-items: center;
  color: rgb(175, 33, 33);
  cursor: pointer;
}
.popup-magazine .popup-content-magazine-container .popup-magazine-header .cancel-button p {
  transition: 0.2s ease;
}
.popup-magazine .popup-content-magazine-container .popup-magazine-header .cancel-button:hover p, .popup-magazine .popup-content-magazine-container .popup-magazine-header .cancel-button:hover i {
  color: rgb(255, 0, 0);
}
.popup-magazine .popup-content-magazine-container form .label-info-magazine-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  max-height: 60px;
  margin-bottom: 40px;
}
.popup-magazine .popup-content-magazine-container form .label-info-magazine-container input {
  width: 23%;
  height: 30px;
  padding: 3px;
  background-color: linen;
  border: 2px solid black;
  border-radius: 10px;
}
.popup-magazine .popup-content-magazine-container form .label-info-magazine-container input:focus {
  outline: none;
}
.popup-magazine .popup-content-magazine-container form .magazine-image h3 {
  font-size: 1.5rem;
  margin: 23px 0 15px 0;
  text-align: center;
}
.popup-magazine .popup-content-magazine-container form .magazine-image .magazine-content-container {
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.popup-magazine .popup-content-magazine-container form .magazine-image .magazine-content-container label, .popup-magazine .popup-content-magazine-container form .magazine-image .magazine-content-container .button-pictures {
  display: flex;
  align-items: center;
  padding: 7px;
  height: 50px;
  background: linen;
  border: 2px solid black;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
.popup-magazine .popup-content-magazine-container form .magazine-image .magazine-content-container label:hover, .popup-magazine .popup-content-magazine-container form .magazine-image .magazine-content-container .button-pictures:hover {
  transform: scale(1.1);
}
.popup-magazine .popup-content-magazine-container form .uploaded-files-container {
  width: 95%;
  margin: 0 auto;
  border: 2px solid linen;
  border-radius: 10px;
}
.popup-magazine .popup-content-magazine-container form .uploaded-files-container legend {
  margin-left: 20px;
  color: black;
  font-size: 1.3rem;
}
.popup-magazine .popup-content-magazine-container form .uploaded-files-container .uploaded-files {
  display: flex;
  flex-wrap: wrap;
}
.popup-magazine .popup-content-magazine-container form .uploaded-files-container .uploaded-files i {
  color: rgb(175, 33, 33);
  font-size: 2rem;
  cursor: pointer;
  transition: 0.2s ease;
}
.popup-magazine .popup-content-magazine-container form .uploaded-files-container .uploaded-files i:hover {
  color: rgb(255, 0, 0);
  transform: scale(1.1);
}
.popup-magazine .popup-content-magazine-container form .uploaded-files-container .uploaded-files .picture-new-partner-container {
  display: flex;
  align-items: start;
  margin: 5px 25px 5px 0;
}
.popup-magazine .popup-content-magazine-container form .uploaded-files-container .uploaded-files .picture-new-partner-container img {
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 5px 10px;
}
.popup-magazine .popup-content-magazine-container form .magazine-footer {
  display: flex;
}
.popup-magazine .popup-content-magazine-container form .magazine-footer input[type=submit] {
  width: 50%;
  margin: 20px auto auto 25%;
  background-color: linen;
  color: black;
  font-size: large;
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
.popup-magazine .popup-content-magazine-container form .magazine-footer input[type=submit]:hover {
  transform: scale(1.1);
}
.popup-magazine .popup-content-magazine-container form .magazine-footer .delete-button {
  margin: 20px 0 20px 0;
  background-color: rgb(175, 33, 33);
  color: linen;
  font-size: large;
  border: 2px solid rgb(175, 33, 33);
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
.popup-magazine .popup-content-magazine-container form .magazine-footer .delete-button:hover {
  background-color: rgb(255, 0, 0);
  border-color: rgb(255, 0, 0);
  transform: scale(1.1);
}

.update-message .popup-update-container {
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 20px;
  width: 70%;
  max-height: 95vh;
  overflow-y: scroll;
}
.update-message .popup-update-container i {
  font-size: 2rem;
  color: rgb(175, 33, 33);
  cursor: pointer;
  transition: 0.2s ease;
}
.update-message .popup-update-container i:hover {
  color: rgb(255, 0, 0);
}
.update-message .popup-update-container .popup-update-message-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 20px;
}
.update-message .popup-update-container .popup-update-message-header h2 {
  font-size: 1.8rem;
}
.update-message .popup-update-container .popup-update-message-header .cancel-button {
  display: flex;
  align-items: center;
  color: rgb(175, 33, 33);
  cursor: pointer;
}
.update-message .popup-update-container .popup-update-message-header .cancel-button p {
  transition: 0.2s ease;
}
.update-message .popup-update-container .popup-update-message-header .cancel-button:hover p, .update-message .popup-update-container .popup-update-message-header .cancel-button:hover i {
  color: rgb(255, 0, 0);
}
.update-message .popup-update-container form .update-message-setup {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.update-message .popup-update-container form .update-message-setup h3 {
  text-align: center;
}
.update-message .popup-update-container form .update-message-setup .info-update-message,
.update-message .popup-update-container form .update-message-setup .city-update-message,
.update-message .popup-update-container form .update-message-setup .links-section {
  flex: 1 1 30%;
  min-width: 300px;
}
.update-message .popup-update-container form .update-message-setup .info-update-message {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.update-message .popup-update-container form .update-message-setup .info-update-message label {
  display: flex;
  align-items: flex-end;
  width: 80%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto 0;
}
.update-message .popup-update-container form .update-message-setup .info-update-message p {
  text-align: center;
}
.update-message .popup-update-container form .update-message-setup .info-update-message input {
  width: 80%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto;
  padding: 5px;
  background: linen;
  border: 2px solid black;
  border-radius: 5px;
  transition: 0.2s ease;
}
.update-message .popup-update-container form .update-message-setup .info-update-message input::placeholder {
  color: rgb(57, 57, 57);
}
.update-message .popup-update-container form .update-message-setup .links-section {
  margin-left: 20px;
}
.update-message .popup-update-container form .update-message-setup .links-section label {
  display: flex;
  align-items: flex-end;
  width: 90%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto 0;
}
.update-message .popup-update-container form .update-message-setup .links-section input {
  width: 90%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto;
  padding: 5px;
  background: white;
  border: 2px solid black;
  border-radius: 5px;
  transition: 0.2s ease;
}
.update-message .popup-update-container form .update-message-setup .links-section input::placeholder {
  color: rgb(57, 57, 57);
}
.update-message .popup-update-container form .description-update-message {
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}
.update-message .popup-update-container form .description-update-message label {
  margin-bottom: 5px;
  cursor: default;
  background: none;
  border: none;
  color: black;
}
.update-message .popup-update-container form .description-update-message label:hover {
  transform: none;
}
.update-message .popup-update-container form .description-update-message textarea {
  flex: 1;
  width: 100%;
  height: 100%;
  resize: none;
  padding: 10px;
  background: linen;
  border: 2px solid black;
  border-radius: 5px;
  transition: 0.2s ease;
}
.update-message .popup-update-container form .description-update-message textarea::placeholder {
  color: rgb(57, 57, 57);
}
.update-message .popup-update-container form .description-update-message textarea:focus {
  outline: none;
}
.update-message .popup-update-container form .update-message-content h3 {
  text-align: center;
}
.update-message .popup-update-container form .update-message-content .update-message-content-container {
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.update-message .popup-update-container form .update-message-content .update-message-content-container label {
  display: flex;
  align-items: center;
  padding: 7px;
  height: 50px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
.update-message .popup-update-container form .update-message-content .update-message-content-container label:hover {
  background: #b1852d;
  transform: scale(1.1);
}
.update-message .popup-update-container form .uploaded-logo {
  margin-left: 35px;
}
.update-message .popup-update-container form .uploaded-logo img {
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 5px 0;
}
.update-message .popup-update-container form .uploaded-files-container {
  width: 95%;
  margin: 0 auto;
  border: 2px solid linen;
  border-radius: 10px;
}
.update-message .popup-update-container form .uploaded-files-container legend {
  margin-left: 20px;
  color: black;
  font-size: 1.3rem;
}
.update-message .popup-update-container form .uploaded-files-container .uploaded-files {
  display: flex;
  flex-wrap: wrap;
}
.update-message .popup-update-container form .uploaded-files-container .uploaded-files i {
  color: rgb(175, 33, 33);
  font-size: 2rem;
  cursor: pointer;
  transition: 0.2s ease;
}
.update-message .popup-update-container form .uploaded-files-container .uploaded-files i:hover {
  color: rgb(255, 0, 0);
  transform: scale(1.1);
}
.update-message .popup-update-container form .uploaded-files-container .uploaded-files .picture-new-partner-container {
  display: flex;
  align-items: start;
  margin: 5px 25px 5px 0;
}
.update-message .popup-update-container form .uploaded-files-container .uploaded-files .picture-new-partner-container img {
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 5px 10px;
}
.update-message .popup-update-container form .uploaded-files-container .uploaded-files .video-new-partner-container {
  display: flex;
  align-items: start;
  margin: 5px 25px 5px 0;
}
.update-message .popup-update-container form .uploaded-files-container .uploaded-files .video-new-partner-container video {
  border-radius: 10px;
  border: 2px solid #e2b259;
  cursor: pointer;
}
.update-message .popup-update-container form .update-message-footer {
  display: flex;
}
.update-message .popup-update-container form .update-message-footer input[type=submit]#valid {
  display: block;
  width: 50%;
  margin: 20px auto;
  background-color: linen;
  color: black;
  font-size: large;
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
.update-message .popup-update-container form .update-message-footer input[type=submit]#valid:hover {
  transform: scale(1.1);
}
.update-message .popup-update-container form .update-message-footer input[type=submit]#invalid {
  display: block;
  width: 50%;
  margin: 20px auto;
  background-color: black;
  color: linen;
  font-size: large;
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
  cursor: not-allowed;
  transition: 0.2s ease;
}
.update-message .popup-update-container form .update-message-footer .delete-button {
  margin: 20px 0 20px 0;
  background-color: rgb(175, 33, 33);
  color: linen;
  font-size: large;
  border: 2px solid rgb(175, 33, 33);
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
.update-message .popup-update-container form .update-message-footer .delete-button:hover {
  background-color: rgb(255, 0, 0);
  border-color: rgb(255, 0, 0);
  transform: scale(1.1);
}

.pj-widget-container .back-button {
  z-index: 99;
}
.pj-widget-container h1 {
  color: linen;
  font-size: 2rem;
  text-align: center;
}
.pj-widget-container section.logo-container-partner-link img {
  width: 100%;
  max-width: 600px;
  margin: 25px auto;
  display: block;
  border-radius: 20px;
  border: 2px solid linen;
  background-color: rgba(0, 0, 0, 0.75);
}
@media (max-width: 768px) {
  .pj-widget-container section.logo-container-partner-link img {
    width: 80%;
    margin-top: 8vh;
  }
}

#pj-partner {
  font-size: 10px !important;
  font-family: "Open Sans";
  background-color: linen;
  width: 93%;
  margin: 10px auto;
  border-radius: 20px;
  padding: 1em 0.7em 8em 0.7em;
}
#pj-partner * {
  box-sizing: border-box;
}
#pj-partner h1, #pj-partner h2, #pj-partner h3 {
  font-family: "Open Sans Bold";
  margin: 0;
  padding: 0;
}
#pj-partner h1 {
  font-size: 3.2em;
  margin-bottom: 25px;
}
#pj-partner .pj_title_sub {
  font-size: 2em;
  margin-bottom: 48px;
}
#pj-partner .title-form {
  font-size: 2.6em;
  padding: 20px;
}
#pj-partner p {
  font-size: 1.6em;
}
#pj-partner > p {
  margin-bottom: 45px;
}
#pj-partner .logo-top {
  margin-bottom: 60px;
}
#pj-partner .logo-top img {
  width: 373px;
  max-width: 100%;
}
#pj-partner .logo-bottom {
  margin-top: 5em;
}
#pj-partner .logo-bottom img {
  width: 373px;
}
#pj-partner .pj-search-wrapper {
  margin-bottom: 48px;
}
#pj-partner .pj-search {
  box-shadow: 0 0 0.4em rgba(0, 0, 0, 0.15);
  border-radius: 0.8em;
  overflow: hidden;
  margin-bottom: 28px;
}
#pj-partner .pj-search form {
  background-color: #FFDE07;
  padding: 38px 20px 28px 20px;
  position: relative;
}
#pj-partner .pj-search input[type=text],
#pj-partner .pj-search button {
  vertical-align: bottom;
}
#pj-partner .pj-search input[type=text] {
  border-radius: 42px;
  background-color: linen;
  line-height: 40px;
  max-height: 42px;
  width: calc(100% - 2px);
  border: solid 1px #D1D4D7;
  margin-right: -45px;
  font-size: 11px;
  padding: 10px 20px;
}
#pj-partner .pj-search input[type=text].what, #pj-partner .pj-search input[type=text].where {
  border-radius: 42px;
}
#pj-partner .pj-search input[type=text].what {
  margin-right: 0;
  margin-bottom: 10px;
}
#pj-partner .pj-search input[type=text].where {
  padding-right: 50px;
}
#pj-partner .pj-search input[type=text].pj-inputText {
  padding-right: 50px;
}
#pj-partner .pj-search button {
  background-color: #64D6DF;
  border-radius: 100%;
  border: none;
  overflow: hidden;
  height: calc(42px - 2px);
  width: calc(42px - 2px);
  position: absolute;
  right: 23px;
  bottom: 29px;
  padding: 0;
  transition: 0.2s;
  box-shadow: -1.86364px 0px 3.72727px rgba(0, 0, 0, 0.12);
}
#pj-partner .pj-search button .value {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}
#pj-partner .pj-search button:before {
  content: "";
  height: calc(42px - 2px);
  width: calc(42px - 2px);
  text-indent: 0px;
  background-image: url("../assets/images/loupe.svg");
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}

#pj-partner {
  padding: 1em 3em 8em 4em;
}
#pj-partner .title-form {
  font-size: 2.6em;
  padding: 20px 32px;
}
#pj-partner .pj-search form {
  padding: 38px 32px 28px 32px;
}
#pj-partner .pj-search form:after {
  content: "";
  clear: both;
  display: table;
}
#pj-partner .pj-search input[type=text] {
  padding: 10px 20px;
  float: left;
}
#pj-partner .pj-search input[type=text].what, #pj-partner .pj-search input[type=text].where {
  border-radius: 42px 0 0 42px;
}
#pj-partner .pj-search input[type=text].what {
  width: calc(40% - 2px);
  margin-bottom: 0;
}
#pj-partner .pj-search input[type=text].where {
  width: calc(60% - 2px);
  border-radius: 0 42px 42px 0;
}
#pj-partner .pj-search button {
  right: 36px;
  top: 39px;
  bottom: inherit;
}
#pj-partner .pj-search button:hover {
  background-color: rgb(42.1818181818, 192.4545454545, 204.3181818182);
  transition: 0.2s;
}

.home-container {
  min-height: 97vh;
}
.home-container .logo-container {
  padding-top: 5vh;
  display: block;
}
.home-container .logo-container .logo {
  max-width: 700px;
}
.home-container .nav-container {
  margin-top: 100px;
}
@media (max-width: 768px) {
  .home-container .nav-container {
    margin-top: 8vh;
  }
}
.home-container .nav-container nav.nav-categories ul {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: fit-content;
}
@media (max-width: 768px) {
  .home-container .nav-container nav.nav-categories ul {
    grid-template-columns: 1fr 1fr;
  }
}
.home-container .nav-container nav.nav-categories ul li {
  margin: 20px 80px;
  text-align: center;
}
@media (max-width: 768px) {
  .home-container .nav-container nav.nav-categories ul li {
    margin: 10px 20px;
  }
}
.home-container .nav-container nav.nav-categories ul li a {
  padding: 10px;
  font-size: 30px;
  font-family: "PoppinsMedium", sans-serif;
  height: 100%;
  color: #e2b259;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 30px;
  border: 2px solid #e2b259;
  transition: 0.2s ease;
}
@media (max-width: 768px) {
  .home-container .nav-container nav.nav-categories ul li a {
    font-size: 20px;
  }
}
.home-container .nav-container nav.nav-categories ul li a:hover {
  color: #b1852d;
  border-color: #b1852d;
  background-color: rgba(0, 0, 0, 0.85);
  transform: scale(1.1);
}
.home-container .nav-container nav.nav-categories ul li a i {
  color: linen;
  font-size: 5rem;
}
@media (max-width: 768px) {
  .home-container .nav-container nav.nav-categories ul li a i {
    font-size: 3rem;
  }
}
.home-container .nav-container nav.nav-categories ul li a .bi {
  font-size: 3.5rem;
}
@media (max-width: 768px) {
  .home-container .nav-container nav.nav-categories ul li a .bi {
    font-size: 2rem;
  }
}

.magazines-container, .calendar-container, .iframe-link-container, .mag-city-container {
  background: black;
  min-height: 97vh;
}
.magazines-container h1, .calendar-container h1, .iframe-link-container h1, .mag-city-container h1 {
  color: linen;
  font-size: 1.5rem;
  text-align: center;
}
.magazines-container .button-container, .calendar-container .button-container, .iframe-link-container .button-container, .mag-city-container .button-container {
  display: flex;
  justify-content: center;
  margin: 10px;
}
.magazines-container .btn-link, .calendar-container .btn-link, .iframe-link-container .btn-link, .mag-city-container .btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e2b259;
  font-size: 1rem;
  padding: 10px;
  border: 2px solid linen;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.2s ease;
}
.magazines-container .iframe-container, .calendar-container .iframe-container, .iframe-link-container .iframe-container, .mag-city-container .iframe-container {
  width: 80%;
  margin: 10px auto 0;
}
@media (max-width: 768px) {
  .magazines-container .iframe-container, .calendar-container .iframe-container, .iframe-link-container .iframe-container, .mag-city-container .iframe-container {
    width: 95%;
  }
}
.magazines-container .iframe-container iframe, .calendar-container .iframe-container iframe, .iframe-link-container .iframe-container iframe, .mag-city-container .iframe-container iframe {
  width: 100%;
  height: 85vh;
  border-radius: 20px;
  scrollbar-color: #e2b259;
}

.social-media-container {
  background: black;
  min-height: 97vh;
}
.social-media-container h1 {
  color: linen;
  font-size: 3rem;
  text-align: center;
}
@media (max-width: 768px) {
  .social-media-container h1 {
    font-size: 2rem;
  }
}
.social-media-container .logo-container {
  padding-top: 5vh;
  display: block;
}
.social-media-container .logo-container .logo {
  max-width: 700px;
}
.social-media-container .links-container ul {
  width: 90%;
  margin: 100px auto 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: fit-content;
}
@media (max-width: 768px) {
  .social-media-container .links-container ul {
    margin-top: 8vh;
    grid-template-columns: 1fr 1fr;
  }
}
.social-media-container .links-container ul li {
  margin: 20px 80px;
  text-align: center;
}
@media (max-width: 768px) {
  .social-media-container .links-container ul li {
    margin: 10px 20px;
  }
}
.social-media-container .links-container ul li a {
  padding: 10px;
  font-size: 30px;
  font-family: "PoppinsMedium", sans-serif;
  height: 100%;
  color: #e2b259;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 30px;
  border: 2px solid #e2b259;
  transition: 0.2s ease;
}
@media (max-width: 768px) {
  .social-media-container .links-container ul li a {
    font-size: 20px;
  }
}
.social-media-container .links-container ul li a:hover {
  color: #b1852d;
  border-color: #b1852d;
  background-color: rgba(0, 0, 0, 0.85);
  transform: scale(1.1);
}
.social-media-container .links-container ul li a i {
  color: linen;
  font-size: 5rem;
}
@media (max-width: 768px) {
  .social-media-container .links-container ul li a i {
    font-size: 3rem;
  }
}
.social-media-container .links-container p {
  text-align: center;
  font-size: 1.6rem;
  color: linen;
  padding: 20px;
  margin-top: 300px;
  background: rgba(0, 0, 0, 0.75);
}

.partners-list-container {
  scrollbar-color: #e2b259;
  background: black;
  min-height: 97vh;
}
.partners-list-container .partners-list {
  width: 70%;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .partners-list-container .partners-list {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .partners-list-container .partners-list {
    width: 95%;
  }
}
.partners-list-container h1 {
  padding: 5px;
  text-align: center;
  margin: 0 30% 2rem 30%;
  color: #e2b259;
  border: 2px solid #e2b259;
  border-radius: 20px;
  background-color: rgb(0, 0, 0);
}
@media (max-width: 768px) {
  .partners-list-container h1 {
    font-size: 1.5rem;
  }
}
@media (max-width: 1024px) {
  .partners-list-container h1 {
    margin: 0 10% 2rem 10%;
  }
}
.partners-list-container .load-more-button {
  display: block;
  width: 200px;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 1rem;
  color: #e2b259;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid #e2b259;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  transition: 0.2s ease;
}
.partners-list-container .load-more-button:hover {
  background-color: rgba(0, 0, 0, 0.85);
  transform: scale(1.05);
}
@media (max-width: 768px) {
  .partners-list-container .load-more-button {
    width: 150px;
    font-size: 0.875rem;
  }
}

.partner-profile-container {
  background: black;
  min-height: 97vh;
  color: linen;
}
.partner-profile-container .partner-infos {
  text-align: center;
}
.partner-profile-container .partner-infos img {
  display: block;
  max-width: 90%;
  max-height: 30vh;
  margin: 0 auto;
  padding: 10px;
  border: 2px solid #e2b259;
  border-radius: 20px;
  background-color: linen;
}
.partner-profile-container .partner-infos .text-content-partner-container {
  margin: 20px auto;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.75);
  border-top: 2px solid #e2b259;
  border-bottom: 2px solid #e2b259;
}
@media (max-width: 768px) {
  .partner-profile-container .partner-infos .text-content-partner-container {
    padding: 10px;
  }
}
.partner-profile-container .partner-infos .text-content-partner-container h1 {
  font-size: 4rem;
}
@media (max-width: 768px) {
  .partner-profile-container .partner-infos .text-content-partner-container h1 {
    font-size: 1.5rem;
  }
}
.partner-profile-container .partner-infos .text-content-partner-container a {
  color: #e2b259;
  text-decoration: underline;
  font-style: italic;
}
.partner-profile-container .partner-infos .text-content-partner-container a:hover {
  color: #b1852d;
}
.partner-profile-container .partner-infos .text-content-partner-container p {
  margin: 20px auto;
  max-width: 70%;
}
@media (max-width: 768px) {
  .partner-profile-container .partner-infos .text-content-partner-container p {
    max-width: 90%;
  }
}
.partner-profile-container .partner-infos .text-content-partner-container .btn-interview {
  display: block;
  width: 70%;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #e2b259;
  color: black;
  border: none;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
}
.partner-profile-container .partner-infos .text-content-partner-container .btn-interview:hover {
  background-color: red;
}
.partner-profile-container .partner-infos .content-uploads-partner-container img {
  max-width: 90%;
  height: auto;
  max-height: 50vh;
  margin: 20px auto;
  padding: 0;
  border: 2px solid #e2b259;
  border-radius: 20px;
  background-color: linen;
}
.partner-profile-container .partner-infos .content-uploads-partner-container video {
  max-width: 90%;
  height: auto;
  max-height: 50vh;
  margin: 20px auto;
  padding: 0;
  border: 2px solid #e2b259;
  border-radius: 20px;
  background-color: linen;
}
.partner-profile-container .partner-infos .contact-partner-container {
  background-color: rgba(0, 0, 0, 0.75);
  padding: 10px;
  border-top: 2px solid #e2b259;
}
.partner-profile-container .partner-infos .contact-partner-container .info-contact-partner-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-left: 10%;
}
.partner-profile-container .partner-infos .contact-partner-container .info-contact-partner-container a {
  flex: 0 1 calc(16.66% - 20px);
  margin: 10px;
  box-sizing: border-box;
  color: #e2b259;
  font-size: 3rem;
  display: flex;
  align-items: center;
  transition: 0.2s ease;
}
.partner-profile-container .partner-infos .contact-partner-container .info-contact-partner-container a:hover {
  color: #b1852d;
}
@media (max-width: 768px) {
  .partner-profile-container .partner-infos .contact-partner-container .info-contact-partner-container a {
    font-size: 1.9rem;
    flex: 0 1 calc(33.33% - 20px);
  }
}
.partner-profile-container .partner-infos .contact-partner-container .info-contact-partner-container a img {
  border: none;
  background: none;
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  margin: 0;
  padding: 0;
}
@media (max-width: 768px) {
  .partner-profile-container .partner-infos .contact-partner-container .info-contact-partner-container a img {
    max-width: 25px;
    min-width: 25px;
    min-height: 25px;
    max-height: 25px;
  }
}

.partner-profile-container section.logo-container-partner-link img {
  width: 100%;
  max-width: 600px;
  margin: 10px auto;
  display: block;
  border-radius: 20px;
  border: 2px solid linen;
  background-color: rgba(0, 0, 0, 0.75);
}
@media (max-width: 768px) {
  .partner-profile-container section.logo-container-partner-link img {
    width: 80%;
  }
}

.home-redirect-container {
  background: black;
  min-height: 97vh;
  background: url("../assets/images/background-earth.jpg") center/cover;
  color: #e2b259;
}
.home-redirect-container section.logo-container img {
  width: 100%;
  max-width: 800px;
  margin: 10px auto;
  display: block;
  border-radius: 20px;
  border: 2px solid linen;
  background-color: rgba(0, 0, 0, 0.75);
}
@media (max-width: 768px) {
  .home-redirect-container section.logo-container img {
    width: 80%;
  }
}
.home-redirect-container section.city-selector .city-display {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 20vh;
}
@media (max-width: 768px) {
  .home-redirect-container section.city-selector .city-display {
    grid-template-columns: 1fr;
    height: 40vh;
  }
}
.home-redirect-container section.city-selector .city-display .button-cities-container .errorPosition {
  color: white;
  background-color: red;
}
.home-redirect-container section.city-selector .city-display .button-cities-container .problemPosition {
  color: white;
  background-color: orange;
}
.home-redirect-container section.city-selector .city-display .button-cities-container p {
  margin: 20px auto;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 88%;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid white;
  border-radius: 20px;
  color: #e2b259;
  font-size: 1.5rem;
  transition: 0.3s ease;
  text-align: center;
}
@media (max-width: 768px) {
  .home-redirect-container section.city-selector .city-display .button-cities-container p {
    width: 70%;
  }
}
.home-redirect-container section.city-selector .city-display .other-cities {
  margin-top: 20px;
}
.home-redirect-container section.city-selector .city-display .other-cities .button-cities-container {
  display: block;
  margin: 0 auto;
  width: 50%;
  height: 88%;
}
@media (max-width: 768px) {
  .home-redirect-container section.city-selector .city-display .other-cities .button-cities-container {
    width: 70%;
  }
}
.home-redirect-container section.city-selector .city-display .other-cities .button-cities-container button {
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid #e2b259;
  border-radius: 20px;
  color: #e2b259;
  font-size: 1.5rem;
  transition: 0.3s ease;
}
.home-redirect-container section.city-selector .city-display .other-cities .button-cities-container button i {
  font-size: 4rem;
  margin-bottom: 10px;
  color: linen;
}
@media (max-width: 768px) {
  .home-redirect-container section.city-selector .city-display .other-cities .button-cities-container button i {
    margin-top: 10px;
    font-size: 3rem;
  }
}
.home-redirect-container section.city-selector .city-display .other-cities .button-cities-container button:hover {
  border: 2px solid #b1852d;
  background-color: rgba(0, 0, 0, 0.85);
  color: #b1852d;
  transform: scale(1.05);
}

.cities-list-container {
  background: black;
  min-height: 97vh;
  background: url("../assets/images/background-earth.jpg") center/cover;
  color: #e2b259;
}
.cities-list-container section.header-container section.header-container-btn {
  position: sticky;
  top: 0;
  z-index: 1000;
}
.cities-list-container section.header-container button {
  margin-top: 40px;
  margin-left: 10%;
  width: 110%;
}
@media (max-width: 768px) {
  .cities-list-container section.header-container button {
    margin-left: 0;
    margin-top: 0;
  }
}
.cities-list-container section.header-container img {
  width: 100%;
  max-width: 800px;
  margin: 10px auto;
  display: block;
  border-radius: 20px;
  border: 2px solid linen;
  background-color: rgba(0, 0, 0, 0.75);
}
@media (max-width: 768px) {
  .cities-list-container section.header-container img {
    margin-top: 30px;
    width: 80%;
  }
}
.cities-list-container section.city-selector .city-searchbar-container {
  color: black;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 30px;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  background-color: #e2b259;
  border-radius: 20px;
  border: 2px solid linen;
}
@media (max-width: 768px) {
  .cities-list-container section.city-selector .city-searchbar-container {
    padding: 5px;
    width: 80%;
  }
}
.cities-list-container section.city-selector .city-searchbar-container h1 {
  font-size: 2rem;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .cities-list-container section.city-selector .city-searchbar-container h1 {
    font-size: 1.5rem;
  }
}
.cities-list-container section.city-selector .city-searchbar-container form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
@media (max-width: 768px) {
  .cities-list-container section.city-selector .city-searchbar-container form {
    flex-direction: column;
  }
}
.cities-list-container section.city-selector .city-searchbar-container form input {
  flex: 1;
  height: 40px;
  font-size: large;
}
@media (max-width: 768px) {
  .cities-list-container section.city-selector .city-searchbar-container form input {
    width: 90%;
    font-size: 0.8rem;
  }
}
.cities-list-container section.city-selector .city-display {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  height: 20vh;
  margin: 30px;
  gap: 20px;
}
@media (max-width: 768px) {
  .cities-list-container section.city-selector .city-display {
    grid-template-columns: 1fr;
    height: 40vh;
  }
}
.cities-list-container section.city-selector .city-display .load-more-cities {
  margin-bottom: 40px;
}
.cities-list-container section.city-selector .city-display .load-more-cities .load-more-cities-button {
  padding: 10px;
  margin: auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 90%;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid #e2b259;
  border-radius: 20px;
  color: #e2b259;
  font-size: 1.5rem;
  transition: 0.3s ease;
}
@media (max-width: 768px) {
  .cities-list-container section.city-selector .city-display .load-more-cities .load-more-cities-button {
    width: 80%;
    margin-bottom: 30px;
  }
}
.cities-list-container section.city-selector .city-display .load-more-cities .load-more-cities-button i {
  font-size: 4rem;
  margin-bottom: 10px;
  color: linen;
}
@media (max-width: 768px) {
  .cities-list-container section.city-selector .city-display .load-more-cities .load-more-cities-button i {
    margin-top: 10px;
    font-size: 3rem;
  }
}
.cities-list-container section.city-selector .city-display .load-more-cities .load-more-cities-button:hover {
  border: 2px solid #b1852d;
  background-color: rgba(0, 0, 0, 0.85);
  color: #b1852d;
  transform: scale(1.05);
}

.connection-container {
  background: black;
  min-height: 97vh;
  background: url("../assets/images/background-earth.jpg") center/cover;
}
.connection-container .logo-container img {
  width: 100%;
  max-width: 800px;
  margin: 10px auto;
  display: block;
  border-radius: 20px;
  border: 2px solid linen;
  background-color: rgba(0, 0, 0, 0.75);
}
.connection-container .content-container {
  width: fit-content;
  margin: 0 auto;
  padding: 30px;
  border: 2px solid linen;
  border-radius: 20px;
  background-color: #e2b259;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.connection-container .content-container h1 {
  font-size: 4rem;
}
.connection-container .content-container .connect-form-container {
  width: 100%;
}
.connection-container .content-container .connect-form-container form {
  display: flex;
  flex-direction: column;
}
.connection-container .content-container .connect-form-container form input {
  font-size: large;
  margin: 20px 0;
  padding: 5px;
  width: 100%;
  height: 40px;
  background-color: linen;
  border: 2px solid #b1852d;
  border-radius: 10px;
}
.connection-container .content-container .connect-form-container form input::placeholder {
  color: black;
}
.connection-container .content-container .connect-form-container form input:focus {
  outline: none;
  border: 2px solid black;
}
.connection-container .content-container .connect-form-container form input[type=submit] {
  transition: 0.2s ease;
  cursor: pointer;
}
.connection-container .content-container .connect-form-container form input[type=submit]:hover {
  background-color: #b1852d;
  border: 2px solid black;
  transform: scale(1.05);
}

.home-admin-container {
  background: url("../assets/images/background-earth.jpg") fixed center/cover;
  min-height: 95vh;
}
.home-admin-container .admin-content-container {
  background-color: rgba(0, 0, 0, 0.85);
  color: linen;
  border: 2px solid linen;
  border-radius: 20px;
  width: 90%;
  margin: 10px auto;
}
.home-admin-container .admin-content-container h2 {
  margin-top: 50px;
  font-size: 4rem;
  text-align: center;
  color: linen;
}

.streets-index-container {
  color: linen;
  height: 100vh;
}
.streets-index-container .streets-index-page-container {
  position: absolute;
  top: 0;
  min-height: 97vh;
  width: 100%;
}
.streets-index-container .streets-index-page-container .streets-index-content {
  background: rgba(0, 0, 0, 0.75);
  width: 90%;
  margin: 3vh auto;
  padding: 20px;
  border-radius: 30px;
  border: 2px solid linen;
}
@media (max-width: 768px) {
  .streets-index-container .streets-index-page-container .streets-index-content {
    width: 95%;
    padding: 5px;
  }
}
.streets-index-container .streets-index-page-container .streets-index-content h1 {
  text-align: center;
  font-size: 3rem;
}
@media (max-width: 768px) {
  .streets-index-container .streets-index-page-container .streets-index-content h1 {
    font-size: 1.5rem;
  }
}
.streets-index-container .streets-index-page-container .streets-index-content form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin: 20px 0;
}
@media (max-width: 768px) {
  .streets-index-container .streets-index-page-container .streets-index-content form {
    flex-direction: column;
    gap: 10px;
  }
}
.streets-index-container .streets-index-page-container .streets-index-content form label {
  font-size: 1.5rem;
  color: #e2b259;
}
@media (max-width: 768px) {
  .streets-index-container .streets-index-page-container .streets-index-content form label {
    font-size: 1.2rem;
  }
}
.streets-index-container .streets-index-page-container .streets-index-content form input {
  width: 300px;
  height: 50px;
  font-size: 1.5rem;
  padding: 0 10px;
  border: 2px solid linen;
  border-radius: 10px;
  background: #e2b259;
  color: linen;
  transition: 0.3s ease;
}
.streets-index-container .streets-index-page-container .streets-index-content form input::placeholder {
  color: linen;
}
.streets-index-container .streets-index-page-container .streets-index-content form input:focus {
  outline: none;
  background: #b1852d;
}
@media (max-width: 768px) {
  .streets-index-container .streets-index-page-container .streets-index-content form input {
    width: 85%;
    height: 40px;
    font-size: 1.2rem;
  }
}
.streets-index-container .streets-index-page-container .streets-index-content .results-streets {
  text-align: center;
}
.streets-index-container .streets-index-page-container .streets-index-content .results-streets h2 {
  font-size: 2rem;
}
@media (max-width: 768px) {
  .streets-index-container .streets-index-page-container .streets-index-content .results-streets h2 {
    font-size: 1.5rem;
  }
}
.streets-index-container .streets-index-page-container .streets-index-content .results-streets ul {
  width: 90%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
@media (max-width: 768px) {
  .streets-index-container .streets-index-page-container .streets-index-content .results-streets ul {
    width: 97%;
    gap: 10px;
  }
}
.streets-index-container .streets-index-page-container .streets-index-content .results-streets ul li {
  text-align: center;
  width: 50%;
}
@media (max-width: 768px) {
  .streets-index-container .streets-index-page-container .streets-index-content .results-streets ul li {
    width: 90%;
  }
}
.streets-index-container .streets-index-page-container .streets-index-content .results-streets ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e2b259;
  color: black;
  height: 100%;
  width: 100%;
  min-height: 60px;
  border: 2px solid linen;
  border-radius: 20px;
  transition: 0.2s ease;
}
.streets-index-container .streets-index-page-container .streets-index-content .results-streets ul li a:hover {
  background: #b1852d;
  transform: scale(1.1);
}
.streets-index-container .streets-index-page-container .streets-index-content .results-streets ul li p {
  font-size: 1.5rem;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 20px;
  color: black;
}
@media (max-width: 768px) {
  .streets-index-container .streets-index-page-container .streets-index-content .results-streets ul li p {
    font-size: 1.2rem;
  }
}
.streets-index-container .streets-index-page-container section.iframe-container {
  text-align: center;
}
.streets-index-container .streets-index-page-container section.iframe-container iframe {
  width: 90%;
  height: 500px;
  border: 2px solid linen;
  border-radius: 20px;
}

.user-admin-container {
  background: url("../assets/images/background-earth.jpg") fixed center/cover;
  min-height: 95vh;
}
.user-admin-container .admin-content-container {
  background-color: rgba(0, 0, 0, 0.85);
  color: linen;
  border: 2px solid linen;
  border-radius: 20px;
  width: 90%;
  margin: 10px auto;
}
.user-admin-container .admin-content-container .admin-content h2 {
  font-size: 2rem;
  margin: 20px auto;
  padding: 10px;
  width: fit-content;
  color: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
}
.user-admin-container .admin-content-container .admin-content section.new-user {
  margin-bottom: 40px;
}
.user-admin-container .admin-content-container .admin-content section.new-user form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.user-admin-container .admin-content-container .admin-content section.new-user form h3 {
  text-align: center;
  margin-bottom: 20px;
  color: #e2b259;
}
.user-admin-container .admin-content-container .admin-content section.new-user form .info-new-user {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.user-admin-container .admin-content-container .admin-content section.new-user form .info-new-user .info-personnels-new-user,
.user-admin-container .admin-content-container .admin-content section.new-user form .info-new-user .info-professionels-new-user {
  width: 48%;
  display: flex;
  flex-direction: column;
}
.user-admin-container .admin-content-container .admin-content section.new-user form .info-new-user .info-personnels-new-user input,
.user-admin-container .admin-content-container .admin-content section.new-user form .info-new-user .info-professionels-new-user input {
  width: 100%;
  min-height: 40px;
  margin: 10px 0;
  padding: 10px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
.user-admin-container .admin-content-container .admin-content section.new-user form .info-new-user .info-personnels-new-user input::placeholder,
.user-admin-container .admin-content-container .admin-content section.new-user form .info-new-user .info-professionels-new-user input::placeholder {
  color: black;
}
.user-admin-container .admin-content-container .admin-content section.new-user form .info-new-user .info-personnels-new-user input:hover,
.user-admin-container .admin-content-container .admin-content section.new-user form .info-new-user .info-professionels-new-user input:hover {
  background: #e2b259;
}
.user-admin-container .admin-content-container .admin-content section.new-user form .info-new-user .info-personnels-new-user input:focus,
.user-admin-container .admin-content-container .admin-content section.new-user form .info-new-user .info-professionels-new-user input:focus {
  background: #e2b259;
  outline: none;
}
.user-admin-container .admin-content-container .admin-content section.new-user form .info-new-user .info-personnels-new-user .uploaded-logo,
.user-admin-container .admin-content-container .admin-content section.new-user form .info-new-user .info-professionels-new-user .uploaded-logo {
  margin-top: 20px;
  text-align: center;
}
.user-admin-container .admin-content-container .admin-content section.new-user form .info-new-user .info-personnels-new-user .uploaded-logo img,
.user-admin-container .admin-content-container .admin-content section.new-user form .info-new-user .info-professionels-new-user .uploaded-logo img {
  max-width: 100%;
  height: auto;
  border: 2px solid #ccc;
  border-radius: 4px;
}
.user-admin-container .admin-content-container .admin-content section.new-user form .info-new-user .info-personnels-new-user .button-label,
.user-admin-container .admin-content-container .admin-content section.new-user form .info-new-user .info-professionels-new-user .button-label {
  display: flex;
  padding: 10px;
  margin: 10px auto;
  background-color: #e2b259;
  color: black;
  border: 2px solid linen;
  border-radius: 10px;
  width: 50%;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.user-admin-container .admin-content-container .admin-content section.new-user form .info-new-user .info-personnels-new-user .button-label:hover,
.user-admin-container .admin-content-container .admin-content section.new-user form .info-new-user .info-professionels-new-user .button-label:hover {
  background-color: #b1852d;
}
.user-admin-container .admin-content-container .admin-content section.new-user form input[type=submit] {
  background-color: #e2b259;
  color: black;
  font-size: large;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  transition: 0.2s ease;
}
.user-admin-container .admin-content-container .admin-content section.new-user form input[type=submit]:hover {
  background-color: #b1852d;
  transform: scale(1.1);
}
.user-admin-container .admin-content-container .admin-content section.user-management table {
  width: 95%;
  margin: 20px auto;
}
.user-admin-container .admin-content-container .admin-content section.user-management table, .user-admin-container .admin-content-container .admin-content section.user-management table th, .user-admin-container .admin-content-container .admin-content section.user-management table td {
  color: #e2b259;
  border-collapse: collapse;
  border: 2px solid linen;
  padding: 5px;
}
.user-admin-container .admin-content-container .admin-content section.user-management table .deleted, .user-admin-container .admin-content-container .admin-content section.user-management table th .deleted, .user-admin-container .admin-content-container .admin-content section.user-management table td .deleted {
  background-color: red;
}
.user-admin-container .admin-content-container .admin-content section.user-management table button, .user-admin-container .admin-content-container .admin-content section.user-management table th button, .user-admin-container .admin-content-container .admin-content section.user-management table td button {
  width: 100%;
  max-width: 200px;
  background-color: #e2b259;
  border: 1px solid linen;
  border-radius: 10px;
  cursor: pointer;
}
.user-admin-container .admin-content-container .admin-content section.user-management table button:hover, .user-admin-container .admin-content-container .admin-content section.user-management table th button:hover, .user-admin-container .admin-content-container .admin-content section.user-management table td button:hover {
  background-color: #b1852d;
  color: linen;
}
.user-admin-container .admin-content-container .admin-content button.btn-refresh {
  display: flex;
  align-items: center;
  position: absolute;
  right: 7%;
  transform: translate(0, -110%);
  padding: 7px;
  height: 50px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
.user-admin-container .admin-content-container .admin-content button.btn-refresh:hover {
  background: #b1852d;
}
.user-admin-container .admin-content-container .admin-content button.btn-refresh i {
  font-size: 1.5rem;
}

.city-admin-container {
  background: url("../assets/images/background-earth.jpg") fixed center/cover;
  min-height: 95vh;
}
.city-admin-container .admin-content-container {
  background-color: rgba(0, 0, 0, 0.85);
  color: linen;
  border: 2px solid linen;
  border-radius: 20px;
  width: 90%;
  margin: 10px auto;
}
.city-admin-container .admin-content-container .admin-content h2 {
  font-size: 2rem;
  margin: 20px auto;
  padding: 10px;
  width: fit-content;
  color: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
}
.city-admin-container .admin-content-container .admin-content section.new-city {
  margin-bottom: 40px;
}
.city-admin-container .admin-content-container .admin-content section.new-city form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.city-admin-container .admin-content-container .admin-content section.new-city form h3 {
  text-align: center;
}
.city-admin-container .admin-content-container .admin-content section.new-city form .info-new-city {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.city-admin-container .admin-content-container .admin-content section.new-city form .info-new-city label {
  display: flex;
  align-items: flex-end;
  width: 80%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto 0;
}
.city-admin-container .admin-content-container .admin-content section.new-city form .info-new-city input {
  width: 80%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
.city-admin-container .admin-content-container .admin-content section.new-city form .info-new-city input::placeholder {
  color: rgb(57, 57, 57);
}
.city-admin-container .admin-content-container .admin-content section.new-city form .info-new-city input:hover {
  background: #b1852d;
}
.city-admin-container .admin-content-container .admin-content section.new-city form .info-new-city input:focus {
  background: #b1852d;
}
.city-admin-container .admin-content-container .admin-content section.new-city form .files-upload .input-container-uploads {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 50%;
}
.city-admin-container .admin-content-container .admin-content section.new-city form .files-upload .input-container-uploads div {
  display: flex;
  flex-direction: column;
}
.city-admin-container .admin-content-container .admin-content section.new-city form .files-upload .input-container-uploads div label {
  display: flex;
  align-items: center;
  padding: 7px;
  height: 50px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
.city-admin-container .admin-content-container .admin-content section.new-city form .files-upload .input-container-uploads div label:hover {
  background: #b1852d;
  transform: scale(1.1);
}
.city-admin-container .admin-content-container .admin-content section.new-city form .files-upload .render-uploads-new-city {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 50%;
}
.city-admin-container .admin-content-container .admin-content section.new-city form .files-upload .render-uploads-new-city img {
  max-height: 100px;
  max-width: 200px;
  margin: 5px;
}
.city-admin-container .admin-content-container .admin-content section.new-city form .social-media-new-city {
  display: grid;
}
.city-admin-container .admin-content-container .admin-content section.new-city form .social-media-new-city label {
  display: flex;
  align-items: flex-end;
  width: 90%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto 0;
}
.city-admin-container .admin-content-container .admin-content section.new-city form .social-media-new-city input {
  width: 90%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
.city-admin-container .admin-content-container .admin-content section.new-city form .social-media-new-city input::placeholder {
  color: rgb(57, 57, 57);
}
.city-admin-container .admin-content-container .admin-content section.new-city form .social-media-new-city input:hover {
  background: #b1852d;
}
.city-admin-container .admin-content-container .admin-content section.new-city form .social-media-new-city input:focus {
  background: #b1852d;
}
.city-admin-container .admin-content-container .admin-content section.new-city form input[type=submit] {
  grid-column: 2/3;
  background-color: #e2b259;
  color: black;
  font-size: large;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
.city-admin-container .admin-content-container .admin-content section.new-city form input[type=submit]:hover {
  background-color: #b1852d;
  transform: scale(1.1);
}
.city-admin-container .admin-content-container .admin-content section.city-management form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin: 30px auto;
  padding: 20px;
  border: 2px solid linen;
  border-radius: 20px;
}
.city-admin-container .admin-content-container .admin-content section.city-management form .name-select-city {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.city-admin-container .admin-content-container .admin-content section.city-management form .name-select-city input {
  margin-left: 30px;
  width: 70%;
  height: 30px;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
.city-admin-container .admin-content-container .admin-content section.city-management form .name-select-city input::placeholder {
  color: rgb(57, 57, 57);
}
.city-admin-container .admin-content-container .admin-content section.city-management form .name-select-city input:hover {
  background: #b1852d;
}
.city-admin-container .admin-content-container .admin-content section.city-management form .name-select-city input:focus {
  background: #b1852d;
}
.city-admin-container .admin-content-container .admin-content section.city-management form .position-select {
  display: flex;
}
.city-admin-container .admin-content-container .admin-content section.city-management form .position-select .country-select-city {
  margin-top: 20px;
  height: 120px;
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 20px;
}
.city-admin-container .admin-content-container .admin-content section.city-management form .position-select .country-select-city select {
  flex: 1;
  margin: 20px 0;
  padding: 5px;
  font-size: 1rem;
  background-color: #e2b259;
  border: 2px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
  color: black;
}
.city-admin-container .admin-content-container .admin-content section.city-management form .position-select .country-select-city select:hover {
  border: 2px solid rgb(186, 186, 186);
}
.city-admin-container .admin-content-container .admin-content section.city-management form .position-select .postalcode-select-city {
  margin: 20px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.city-admin-container .admin-content-container .admin-content section.city-management form .position-select .postalcode-select-city input {
  margin: 30px;
  width: 100%;
  height: 30px;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
.city-admin-container .admin-content-container .admin-content section.city-management form .position-select .postalcode-select-city input::placeholder {
  color: rgb(57, 57, 57);
}
.city-admin-container .admin-content-container .admin-content section.city-management form .position-select .postalcode-select-city input:hover {
  background: #b1852d;
}
.city-admin-container .admin-content-container .admin-content section.city-management form .position-select .postalcode-select-city input:focus {
  background: #b1852d;
}
.city-admin-container .admin-content-container .admin-content section.city-management p {
  margin: 20px 0;
  text-align: center;
  font-size: 1.5rem;
}
.city-admin-container .admin-content-container .admin-content section.city-management table {
  width: 95%;
  margin: 20px auto;
  color: black;
  background: #e2b259;
}
.city-admin-container .admin-content-container .admin-content section.city-management table, .city-admin-container .admin-content-container .admin-content section.city-management table th, .city-admin-container .admin-content-container .admin-content section.city-management table td {
  border-collapse: collapse;
  border: 2px solid linen;
  padding: 5px;
}
.city-admin-container .admin-content-container .admin-content section.city-management table.contain-btn, .city-admin-container .admin-content-container .admin-content section.city-management table th.contain-btn, .city-admin-container .admin-content-container .admin-content section.city-management table td.contain-btn {
  text-align: center;
}
.city-admin-container .admin-content-container .admin-content section.city-management table.contain-btn button:disabled:hover, .city-admin-container .admin-content-container .admin-content section.city-management table th.contain-btn button:disabled:hover, .city-admin-container .admin-content-container .admin-content section.city-management table td.contain-btn button:disabled:hover {
  color: red;
}
.city-admin-container .admin-content-container .admin-content section.city-management table img, .city-admin-container .admin-content-container .admin-content section.city-management table th img, .city-admin-container .admin-content-container .admin-content section.city-management table td img {
  max-height: 70px;
  max-width: 200px;
}
.city-admin-container .admin-content-container .admin-content section.city-management table .city-logo, .city-admin-container .admin-content-container .admin-content section.city-management table th .city-logo, .city-admin-container .admin-content-container .admin-content section.city-management table td .city-logo {
  filter: contrast(0%);
}
.city-admin-container .admin-content-container .admin-content section.city-management table button {
  width: 100%;
  max-width: 200px;
  background-color: #e2b259;
  border: 1px solid linen;
  border-radius: 10px;
  cursor: pointer;
}
.city-admin-container .admin-content-container .admin-content section.city-management table button:hover {
  background-color: #b1852d;
  color: linen;
}
.city-admin-container .admin-content-container .admin-content section.city-management table tr.deleted {
  background-color: rgb(175, 33, 33);
}
.city-admin-container .admin-content-container .admin-content section.city-management button.btn-refresh {
  display: flex;
  align-items: center;
  position: absolute;
  right: 7%;
  transform: translate(0, -110%);
  padding: 7px;
  height: 50px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
.city-admin-container .admin-content-container .admin-content section.city-management button.btn-refresh:hover {
  background: #b1852d;
}
.city-admin-container .admin-content-container .admin-content section.city-management button.btn-refresh i {
  font-size: 1.5rem;
}

.partner-admin-container {
  background: url("../assets/images/background-earth.jpg") fixed center/cover;
  min-height: 95vh;
}
.partner-admin-container .admin-content-container {
  background-color: rgba(0, 0, 0, 0.85);
  color: linen;
  border: 2px solid linen;
  border-radius: 20px;
  width: 90%;
  margin: 10px auto;
}
.partner-admin-container .admin-content-container .admin-content h2 {
  font-size: 2rem;
  margin: 20px auto;
  padding: 10px;
  width: fit-content;
  color: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner {
  margin-bottom: 40px;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup h3 {
  text-align: center;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .info-new-partner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .info-new-partner label {
  display: flex;
  align-items: flex-end;
  width: 80%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto 0;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .info-new-partner p {
  text-align: center;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .info-new-partner input {
  width: 80%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .info-new-partner input::placeholder {
  color: rgb(57, 57, 57);
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .info-new-partner input:hover {
  background: #b1852d;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .info-new-partner input:focus {
  background: #b1852d;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .city-new-partner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .city-new-partner h3 {
  margin-bottom: 20px;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .city-new-partner section.location-info input {
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .city-new-partner section.location-info input:hover {
  background: #b1852d;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .city-new-partner section.location-info input:focus {
  background: #b1852d;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .city-new-partner section.location-info .without-coordinates {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 300px;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .city-new-partner section.location-info .without-coordinates #btn-coordinates-activaction {
  text-align: center;
  background-color: #e2b259;
  color: black;
  font-size: large;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .city-new-partner section.location-info .without-coordinates #btn-coordinates-activaction:hover {
  background-color: #b1852d;
  transform: scale(0.97);
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .city-new-partner section.location-info .with-coordinates .coordinates-city {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 10px;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .city-new-partner section.location-info .with-coordinates .coordinates-city input {
  grid-column: 2/4;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .interview-new-partner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .interview-new-partner h3 {
  margin: 40px 0 10px 0;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .interview-new-partner label {
  display: flex;
  align-items: flex-end;
  width: 80%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto 0;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .interview-new-partner label[for=interview-photo-content] {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  padding: 7px;
  height: 50px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .interview-new-partner label[for=interview-photo-content]:hover {
  background: #b1852d;
  transform: scale(1.1);
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .interview-new-partner input, .partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .interview-new-partner textarea {
  width: 80%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .interview-new-partner input::placeholder, .partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .interview-new-partner textarea::placeholder {
  color: rgb(57, 57, 57);
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .interview-new-partner input:hover, .partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .interview-new-partner textarea:hover {
  background: #b1852d;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .interview-new-partner input:focus, .partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .interview-new-partner textarea:focus {
  background: #b1852d;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .interview-new-partner textarea {
  resize: none;
  height: auto;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .categories-new-partner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .categories-new-partner h3 {
  margin-bottom: 20px;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .categories-new-partner .flash-message {
  background: red;
  padding: 5px;
  border-radius: 5px;
  border: 2px solid linen;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .categories-new-partner .description-new-partner {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .categories-new-partner .description-new-partner label {
  cursor: default;
  background: none;
  border: none;
  color: linen;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .categories-new-partner .description-new-partner label:hover {
  transform: none;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .categories-new-partner .description-new-partner textarea {
  flex: 1;
  width: 100%;
  height: 100%;
  resize: none;
  padding: 10px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .categories-new-partner .description-new-partner textarea::placeholder {
  color: rgb(57, 57, 57);
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .categories-new-partner .description-new-partner textarea:hover {
  background: #b1852d;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .categories-new-partner .description-new-partner textarea:focus {
  background: #b1852d;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .social-media-new-partner {
  display: grid;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .social-media-new-partner label {
  display: flex;
  align-items: flex-end;
  width: 90%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto 0;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .social-media-new-partner input {
  width: 90%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .social-media-new-partner input::placeholder {
  color: rgb(57, 57, 57);
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .social-media-new-partner input:hover {
  background: #b1852d;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-setup .social-media-new-partner input:focus {
  background: #b1852d;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-content h3 {
  text-align: center;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-content .new-partner-content-container {
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-content .new-partner-content-container label {
  display: flex;
  align-items: center;
  padding: 7px;
  height: 50px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .new-partner-content .new-partner-content-container label:hover {
  background: #b1852d;
  transform: scale(1.1);
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .logo-interview {
  display: flex;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .logo-interview .uploaded-logo {
  margin-left: 35px;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .logo-interview .uploaded-logo img {
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 5px 0;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .uploaded-files-container {
  width: 95%;
  margin: 0 auto;
  border: 2px solid linen;
  border-radius: 10px;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .uploaded-files-container legend {
  margin-left: 20px;
  color: #e2b259;
  font-size: 1.3rem;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .uploaded-files-container .uploaded-files {
  display: flex;
  flex-wrap: wrap;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .uploaded-files-container .uploaded-files i {
  color: rgb(175, 33, 33);
  font-size: 2rem;
  cursor: pointer;
  transition: 0.2s ease;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .uploaded-files-container .uploaded-files i:hover {
  color: rgb(255, 0, 0);
  transform: scale(1.1);
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .uploaded-files-container .uploaded-files .picture-new-partner-container {
  display: flex;
  align-items: start;
  margin: 5px 25px 5px 0;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .uploaded-files-container .uploaded-files .picture-new-partner-container img {
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 5px 10px;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .uploaded-files-container .uploaded-files .video-new-partner-container {
  display: flex;
  align-items: start;
  margin: 5px 25px 5px 0;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form .uploaded-files-container .uploaded-files .video-new-partner-container video {
  border-radius: 10px;
  border: 2px solid #e2b259;
  cursor: pointer;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form input[type=submit]#valid {
  display: block;
  width: 50%;
  margin: 20px auto;
  background-color: #e2b259;
  color: black;
  font-size: large;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form input[type=submit]#valid:hover {
  background-color: #b1852d;
  transform: scale(1.1);
}
.partner-admin-container .admin-content-container .admin-content section.new-partner form input[type=submit]#invalid {
  display: block;
  width: 50%;
  margin: 20px auto;
  background-color: black;
  color: linen;
  font-size: large;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 5px;
  cursor: not-allowed;
  transition: 0.2s ease;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner .modal-content {
  background: #e2b259;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 80%;
  max-width: 800px;
  border: 2px solid linen;
  color: black;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner .modal-content button {
  padding: 10px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner .modal-content button:hover {
  background: #b1852d;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner .modal-content .modal-close {
  position: absolute;
  padding: 5px;
  top: 5px;
  right: 10px;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
.partner-admin-container .admin-content-container .admin-content section.new-partner .modal-content .modal-button-container {
  display: flex;
  justify-content: center;
}
.partner-admin-container .admin-content-container .admin-content section.category-management table {
  width: 70%;
  margin: 20px auto;
  color: #e2b259;
}
.partner-admin-container .admin-content-container .admin-content section.category-management table, .partner-admin-container .admin-content-container .admin-content section.category-management table th, .partner-admin-container .admin-content-container .admin-content section.category-management table td {
  border-collapse: collapse;
  border: 2px solid linen;
  padding: 5px;
}
.partner-admin-container .admin-content-container .admin-content section.category-management table.contain-btn, .partner-admin-container .admin-content-container .admin-content section.category-management table th.contain-btn, .partner-admin-container .admin-content-container .admin-content section.category-management table td.contain-btn {
  text-align: center;
  max-width: 100px;
}
.partner-admin-container .admin-content-container .admin-content section.category-management table.contain-btn button:disabled:hover, .partner-admin-container .admin-content-container .admin-content section.category-management table th.contain-btn button:disabled:hover, .partner-admin-container .admin-content-container .admin-content section.category-management table td.contain-btn button:disabled:hover {
  color: red;
}
.partner-admin-container .admin-content-container .admin-content section.category-management table button {
  width: 100%;
  max-width: 200px;
  background-color: #e2b259;
  border: 1px solid linen;
  border-radius: 10px;
  cursor: pointer;
}
.partner-admin-container .admin-content-container .admin-content section.category-management table button:hover {
  background-color: #b1852d;
  color: linen;
}
.partner-admin-container .admin-content-container .admin-content section.category-management table tr.deleted {
  background-color: rgb(175, 33, 33);
}
.partner-admin-container .admin-content-container .admin-content section.category-management button.btn-refresh {
  display: flex;
  align-items: center;
  position: absolute;
  right: 20%;
  transform: translate(0, -110%);
  padding: 7px;
  height: 50px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
.partner-admin-container .admin-content-container .admin-content section.category-management button.btn-refresh:hover {
  background: #b1852d;
}
.partner-admin-container .admin-content-container .admin-content section.category-management button.btn-refresh i {
  font-size: 1.5rem;
}
.partner-admin-container .admin-content-container .admin-content section.category-management div {
  text-align: center;
}
.partner-admin-container .admin-content-container .admin-content section.category-management div input {
  width: 30%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
.partner-admin-container .admin-content-container .admin-content section.category-management div input::placeholder {
  color: rgb(57, 57, 57);
}
.partner-admin-container .admin-content-container .admin-content section.category-management div input:hover {
  background: #b1852d;
}
.partner-admin-container .admin-content-container .admin-content section.category-management div input:focus {
  background: #b1852d;
}
.partner-admin-container .admin-content-container .admin-content section.category-management div button {
  padding: 5px;
  margin: 5px;
  color: black;
  background-color: #e2b259;
  border: 1px solid linen;
  border-radius: 10px;
  cursor: pointer;
}
.partner-admin-container .admin-content-container .admin-content section.category-management div button:hover {
  background-color: #b1852d;
  color: linen;
}
.partner-admin-container .admin-content-container .admin-content section.category-management div button#disabledCreateCat {
  cursor: not-allowed;
  background-color: black;
  color: linen;
}
.partner-admin-container .admin-content-container .admin-content section.partner-management form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin: 30px auto;
  padding: 20px;
  border: 2px solid linen;
  border-radius: 20px;
}
.partner-admin-container .admin-content-container .admin-content section.partner-management form .name-select-partner {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.partner-admin-container .admin-content-container .admin-content section.partner-management form .name-select-partner input {
  margin-left: 30px;
  width: 70%;
  height: 30px;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
.partner-admin-container .admin-content-container .admin-content section.partner-management form .name-select-partner input::placeholder {
  color: rgb(57, 57, 57);
}
.partner-admin-container .admin-content-container .admin-content section.partner-management form .name-select-partner input:hover {
  background: #b1852d;
}
.partner-admin-container .admin-content-container .admin-content section.partner-management form .name-select-partner input:focus {
  background: #b1852d;
}
.partner-admin-container .admin-content-container .admin-content section.partner-management form .others-select-options {
  margin-top: 20px;
  height: 120px;
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 20px;
}
.partner-admin-container .admin-content-container .admin-content section.partner-management form .others-select-options select {
  flex: 1;
  margin: 20px 0;
  padding: 5px;
  font-size: 1rem;
  background-color: #e2b259;
  border: 2px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
  color: black;
}
.partner-admin-container .admin-content-container .admin-content section.partner-management form .others-select-options select:hover {
  border: 2px solid rgb(186, 186, 186);
}
.partner-admin-container .admin-content-container .admin-content section.partner-management button.btn-refresh {
  display: flex;
  align-items: center;
  position: absolute;
  right: 8%;
  transform: translate(0, -110%);
  padding: 7px;
  height: 50px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
.partner-admin-container .admin-content-container .admin-content section.partner-management button.btn-refresh:hover {
  background: #b1852d;
}
.partner-admin-container .admin-content-container .admin-content section.partner-management button.btn-refresh i {
  font-size: 1.5rem;
}
.partner-admin-container .admin-content-container .admin-content section.partner-management p {
  margin: 20px 0;
  text-align: center;
  font-size: 1.5rem;
}
.partner-admin-container .admin-content-container .admin-content section.partner-management table.partner-table {
  width: 95%;
  margin: 20px auto;
  color: #e2b259;
}
.partner-admin-container .admin-content-container .admin-content section.partner-management table.partner-table, .partner-admin-container .admin-content-container .admin-content section.partner-management table.partner-table th, .partner-admin-container .admin-content-container .admin-content section.partner-management table.partner-table td {
  border-collapse: collapse;
  border: 2px solid linen;
  padding: 5px;
}
.partner-admin-container .admin-content-container .admin-content section.partner-management table.partner-table .first-category, .partner-admin-container .admin-content-container .admin-content section.partner-management table.partner-table th .first-category, .partner-admin-container .admin-content-container .admin-content section.partner-management table.partner-table td .first-category {
  color: red;
}
.partner-admin-container .admin-content-container .admin-content section.partner-management table.partner-table.contain-btn, .partner-admin-container .admin-content-container .admin-content section.partner-management table.partner-table th.contain-btn, .partner-admin-container .admin-content-container .admin-content section.partner-management table.partner-table td.contain-btn {
  text-align: center;
}
.partner-admin-container .admin-content-container .admin-content section.partner-management table.partner-table.contain-btn button:disabled:hover, .partner-admin-container .admin-content-container .admin-content section.partner-management table.partner-table th.contain-btn button:disabled:hover, .partner-admin-container .admin-content-container .admin-content section.partner-management table.partner-table td.contain-btn button:disabled:hover {
  color: red;
}
.partner-admin-container .admin-content-container .admin-content section.partner-management table.partner-table button {
  width: 100%;
  background-color: #e2b259;
  border: 1px solid linen;
  border-radius: 10px;
  cursor: pointer;
}
.partner-admin-container .admin-content-container .admin-content section.partner-management table.partner-table button:hover {
  background-color: #b1852d;
  color: linen;
}
.partner-admin-container .admin-content-container .admin-content section.partner-management table.partner-table tr.deleted {
  background-color: rgb(175, 33, 33);
}
.partner-admin-container .admin-content-container .admin-content section.partner-management div {
  text-align: center;
}
.partner-admin-container .admin-content-container .admin-content section.partner-management div button {
  padding: 5px;
  margin: 5px;
  background-color: #e2b259;
  border: 1px solid linen;
  border-radius: 10px;
  cursor: pointer;
}
.partner-admin-container .admin-content-container .admin-content section.partner-management div button:hover {
  background-color: #b1852d;
  color: linen;
}

.message-admin-container {
  background: url("../assets/images/background-earth.jpg") fixed center/cover;
  min-height: 95vh;
}
.message-admin-container .admin-content-container {
  background-color: rgba(0, 0, 0, 0.85);
  color: linen;
  border: 2px solid linen;
  border-radius: 20px;
  width: 90%;
  margin: 10px auto;
}
.message-admin-container .admin-content-container .admin-content h2 {
  font-size: 2rem;
  margin: 20px auto;
  padding: 10px;
  width: fit-content;
  color: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
}
.message-admin-container .admin-content-container .admin-content section.new-message {
  margin-bottom: 40px;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .new-message-setup {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .new-message-setup h3 {
  text-align: center;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .new-message-setup .info-new-message,
.message-admin-container .admin-content-container .admin-content section.new-message form .new-message-setup .city-new-message,
.message-admin-container .admin-content-container .admin-content section.new-message form .new-message-setup .links-section {
  flex: 1 1 30%;
  min-width: 300px;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .new-message-setup .info-new-message {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .new-message-setup .info-new-message label {
  display: flex;
  align-items: flex-end;
  width: 80%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto 0;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .new-message-setup .info-new-message p {
  text-align: center;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .new-message-setup .info-new-message input {
  width: 80%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .new-message-setup .info-new-message input::placeholder {
  color: rgb(57, 57, 57);
}
.message-admin-container .admin-content-container .admin-content section.new-message form .new-message-setup .info-new-message input:hover {
  background: #b1852d;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .new-message-setup .info-new-message input:focus {
  background: #b1852d;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .new-message-setup .links-section {
  margin-left: 20px;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .new-message-setup .links-section label {
  display: flex;
  align-items: flex-end;
  width: 90%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto 0;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .new-message-setup .links-section input {
  width: 90%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .new-message-setup .links-section input::placeholder {
  color: rgb(57, 57, 57);
}
.message-admin-container .admin-content-container .admin-content section.new-message form .new-message-setup .links-section input:hover {
  background: #b1852d;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .new-message-setup .links-section input:focus {
  background: #b1852d;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .description-new-message {
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .description-new-message label {
  margin-bottom: 5px;
  cursor: default;
  background: none;
  border: none;
  color: linen;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .description-new-message label:hover {
  transform: none;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .description-new-message textarea {
  flex: 1;
  width: 100%;
  height: 100%;
  resize: none;
  padding: 10px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .description-new-message textarea::placeholder {
  color: rgb(57, 57, 57);
}
.message-admin-container .admin-content-container .admin-content section.new-message form .description-new-message textarea:hover {
  background: #b1852d;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .description-new-message textarea:focus {
  background: #b1852d;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .new-message-content h3 {
  text-align: center;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .new-message-content .new-message-content-container {
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .new-message-content .new-message-content-container label {
  display: flex;
  align-items: center;
  padding: 7px;
  height: 50px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .new-message-content .new-message-content-container label:hover {
  background: #b1852d;
  transform: scale(1.1);
}
.message-admin-container .admin-content-container .admin-content section.new-message form .uploaded-logo {
  margin-left: 35px;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .uploaded-logo img {
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 5px 0;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .uploaded-files-container {
  width: 95%;
  margin: 0 auto;
  border: 2px solid linen;
  border-radius: 10px;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .uploaded-files-container legend {
  margin-left: 20px;
  color: #e2b259;
  font-size: 1.3rem;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .uploaded-files-container .uploaded-files {
  display: flex;
  flex-wrap: wrap;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .uploaded-files-container .uploaded-files i {
  color: rgb(175, 33, 33);
  font-size: 2rem;
  cursor: pointer;
  transition: 0.2s ease;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .uploaded-files-container .uploaded-files i:hover {
  color: rgb(255, 0, 0);
  transform: scale(1.1);
}
.message-admin-container .admin-content-container .admin-content section.new-message form .uploaded-files-container .uploaded-files .picture-new-message-container {
  display: flex;
  align-items: start;
  margin: 5px 25px 5px 0;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .uploaded-files-container .uploaded-files .picture-new-message-container img {
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 5px 10px;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .uploaded-files-container .uploaded-files .video-new-message-container {
  display: flex;
  align-items: start;
  margin: 5px 25px 5px 0;
}
.message-admin-container .admin-content-container .admin-content section.new-message form .uploaded-files-container .uploaded-files .video-new-message-container video {
  border-radius: 10px;
  border: 2px solid #e2b259;
  cursor: pointer;
}
.message-admin-container .admin-content-container .admin-content section.new-message form input[type=submit]#valid {
  display: block;
  width: 50%;
  margin: 20px auto;
  background-color: #e2b259;
  color: black;
  font-size: large;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
.message-admin-container .admin-content-container .admin-content section.new-message form input[type=submit]#valid:hover {
  background-color: #b1852d;
  transform: scale(1.1);
}
.message-admin-container .admin-content-container .admin-content section.new-message form input[type=submit]#invalid {
  display: block;
  width: 50%;
  margin: 20px auto;
  background-color: black;
  color: linen;
  font-size: large;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 5px;
  cursor: not-allowed;
  transition: 0.2s ease;
}
.message-admin-container .admin-content-container .admin-content section.message-management form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin: 30px auto;
  padding: 20px;
  border: 2px solid linen;
  border-radius: 20px;
}
.message-admin-container .admin-content-container .admin-content section.message-management form .name-select-message {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.message-admin-container .admin-content-container .admin-content section.message-management form .name-select-message input {
  margin-left: 30px;
  width: 70%;
  height: 30px;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
.message-admin-container .admin-content-container .admin-content section.message-management form .name-select-message input::placeholder {
  color: rgb(57, 57, 57);
}
.message-admin-container .admin-content-container .admin-content section.message-management form .name-select-message input:hover {
  background: #b1852d;
}
.message-admin-container .admin-content-container .admin-content section.message-management form .name-select-message input:focus {
  background: #b1852d;
}
.message-admin-container .admin-content-container .admin-content section.message-management form .others-select-options {
  margin-top: 20px;
  height: 120px;
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 20px;
}
.message-admin-container .admin-content-container .admin-content section.message-management form .others-select-options select {
  flex: 1;
  margin: 20px 0;
  padding: 5px;
  font-size: 1rem;
  background-color: #e2b259;
  border: 2px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
  color: black;
}
.message-admin-container .admin-content-container .admin-content section.message-management form .others-select-options select:hover {
  border: 2px solid rgb(186, 186, 186);
}
.message-admin-container .admin-content-container .admin-content section.message-management form .others-select-options .date-publication {
  margin-top: 20px;
}
.message-admin-container .admin-content-container .admin-content section.message-management form .others-select-options .date-publication input {
  height: 100%;
  margin: 5px 20px 5px 10px;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
.message-admin-container .admin-content-container .admin-content section.message-management form .others-select-options .date-publication input::placeholder {
  color: rgb(57, 57, 57);
}
.message-admin-container .admin-content-container .admin-content section.message-management form .others-select-options .date-publication input:hover {
  background: #b1852d;
}
.message-admin-container .admin-content-container .admin-content section.message-management form .others-select-options .date-publication input:focus {
  background: #b1852d;
}
.message-admin-container .admin-content-container .admin-content section.message-management button.btn-refresh {
  display: flex;
  align-items: center;
  position: absolute;
  right: 8%;
  transform: translate(0, -110%);
  padding: 7px;
  height: 50px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
.message-admin-container .admin-content-container .admin-content section.message-management button.btn-refresh:hover {
  background: #b1852d;
}
.message-admin-container .admin-content-container .admin-content section.message-management button.btn-refresh i {
  font-size: 1.5rem;
}
.message-admin-container .admin-content-container .admin-content section.message-management p {
  margin: 20px 0;
  text-align: center;
  font-size: 1.5rem;
}
.message-admin-container .admin-content-container .admin-content section.message-management table.message-table {
  width: 95%;
  margin: 20px auto;
  color: #e2b259;
}
.message-admin-container .admin-content-container .admin-content section.message-management table.message-table, .message-admin-container .admin-content-container .admin-content section.message-management table.message-table th, .message-admin-container .admin-content-container .admin-content section.message-management table.message-table td {
  border-collapse: collapse;
  border: 2px solid linen;
  padding: 5px;
}
.message-admin-container .admin-content-container .admin-content section.message-management table.message-table .first-category, .message-admin-container .admin-content-container .admin-content section.message-management table.message-table th .first-category, .message-admin-container .admin-content-container .admin-content section.message-management table.message-table td .first-category {
  color: red;
}
.message-admin-container .admin-content-container .admin-content section.message-management table.message-table.contain-btn, .message-admin-container .admin-content-container .admin-content section.message-management table.message-table th.contain-btn, .message-admin-container .admin-content-container .admin-content section.message-management table.message-table td.contain-btn {
  text-align: center;
}
.message-admin-container .admin-content-container .admin-content section.message-management table.message-table.contain-btn button:disabled:hover, .message-admin-container .admin-content-container .admin-content section.message-management table.message-table th.contain-btn button:disabled:hover, .message-admin-container .admin-content-container .admin-content section.message-management table.message-table td.contain-btn button:disabled:hover {
  color: red;
}
.message-admin-container .admin-content-container .admin-content section.message-management table.message-table button {
  width: 100%;
  background-color: #e2b259;
  border: 1px solid linen;
  border-radius: 10px;
  cursor: pointer;
}
.message-admin-container .admin-content-container .admin-content section.message-management table.message-table button:hover {
  background-color: #b1852d;
  color: linen;
}
.message-admin-container .admin-content-container .admin-content section.message-management table.message-table tr.deleted {
  background-color: rgb(175, 33, 33);
}
.message-admin-container .admin-content-container .admin-content section.message-management div {
  text-align: center;
}
.message-admin-container .admin-content-container .admin-content section.message-management div button {
  padding: 5px;
  margin: 5px;
  background-color: #e2b259;
  border: 1px solid linen;
  border-radius: 10px;
  cursor: pointer;
}
.message-admin-container .admin-content-container .admin-content section.message-management div button:hover {
  background-color: #b1852d;
  color: linen;
}
.message-admin-container .admin-content-container .admin-content section.message-management section.message-overview {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.message-admin-container .admin-content-container .admin-content section.message-management section.message-overview .message {
  display: flex;
  align-items: center;
  margin: 15px;
  padding: 10px;
  background-color: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
}
.message-admin-container .admin-content-container .admin-content section.message-management section.message-overview .message img {
  width: 200px;
  height: 150px;
  object-fit: cover;
  margin-right: 5px;
}

.admin-content-city-container {
  background: url("../assets/images/background-earth.jpg") fixed center/cover;
  min-height: 95vh;
}
.admin-content-city-container .admin-content-container {
  background-color: rgba(0, 0, 0, 0.85);
  color: linen;
  border: 2px solid linen;
  border-radius: 20px;
  width: 90%;
  margin: 10px auto;
}
.admin-content-city-container .admin-content h2 {
  font-size: 2rem;
  margin: 20px auto;
  padding: 10px;
  width: fit-content;
  color: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
}
.admin-content-city-container .admin-content .selector-container {
  width: 50%;
  margin: 0 auto;
}
.admin-content-city-container .admin-content form h3 {
  font-size: 1.5rem;
  margin: 20px auto;
  padding: 10px;
  width: fit-content;
  color: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
}
.admin-content-city-container .admin-content form .link-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  margin: 20px auto;
  width: 90%;
}
.admin-content-city-container .admin-content form .link-container label {
  font-size: 1.2rem;
  color: linen;
  margin: 0 10px;
}
.admin-content-city-container .admin-content form .link-container input {
  grid-column: 2/4;
  width: 90%;
  margin: 0 auto;
  padding: 5px;
  border-radius: 5px;
  background-color: #e2b259;
  border: 2px solid linen;
  color: black;
}
.admin-content-city-container .admin-content form .label-guided-tour-magazine {
  display: flex;
}
.admin-content-city-container .admin-content form .label-guided-tour-magazine .label-btn {
  display: block;
  width: fit-content;
  margin: 20px auto;
  padding: 10px;
  background-color: #e2b259;
  color: black;
  font-size: large;
  border: 2px solid linen;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s ease;
}
.admin-content-city-container .admin-content form .label-guided-tour-magazine .label-btn:hover {
  background-color: #b1852d;
  transform: scale(1.1);
}
.admin-content-city-container .admin-content form input[type=submit] {
  display: block;
  width: 50%;
  margin: 20px auto;
  background-color: #e2b259;
  color: black;
  font-size: large;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
.admin-content-city-container .admin-content form input[type=submit]:hover {
  background-color: #b1852d;
  transform: scale(1.1);
}
.admin-content-city-container .admin-content section.guided-tour-overview {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.admin-content-city-container .admin-content section.guided-tour-overview .guided-tour {
  display: flex;
  align-items: center;
  margin: 15px;
  padding: 10px;
  background-color: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
}
.admin-content-city-container .admin-content section.guided-tour-overview .guided-tour img {
  width: 200px;
  height: 150px;
  object-fit: cover;
  margin-right: 5px;
}
.admin-content-city-container .admin-content section.magazine-overview {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.admin-content-city-container .admin-content section.magazine-overview .magazine {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 15px 0;
  padding: 10px;
  background-color: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  width: 80%;
  height: 150px;
  background-size: cover;
  background-position: center 60%;
  color: linen;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}
.admin-content-city-container .admin-content section.magazine-overview .magazine::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
.admin-content-city-container .admin-content section.magazine-overview .magazine .name-mag {
  display: flex;
  flex-direction: column;
}
.admin-content-city-container .admin-content section.magazine-overview .magazine .name-mag p {
  position: relative;
  z-index: 1;
}

.practical-guide-redirect-container {
  min-height: 97vh;
}
.practical-guide-redirect-container .logo-container {
  padding-top: 5vh;
  display: block;
}
.practical-guide-redirect-container .logo-container .logo {
  max-width: 700px;
}
.practical-guide-redirect-container h1 {
  color: linen;
  font-size: 3rem;
  text-align: center;
}
@media (max-width: 1024px) {
  .practical-guide-redirect-container h1 {
    font-size: 2rem;
  }
}
.practical-guide-redirect-container .nav-guides ul {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: fit-content;
}
@media (max-width: 768px) {
  .practical-guide-redirect-container .nav-guides ul {
    grid-template-columns: 1fr 1fr;
  }
}
.practical-guide-redirect-container .nav-guides ul li {
  margin: 20px 80px;
  text-align: center;
}
@media (max-width: 768px) {
  .practical-guide-redirect-container .nav-guides ul li {
    margin: 10px 20px;
  }
}
.practical-guide-redirect-container .nav-guides ul li a, .practical-guide-redirect-container .nav-guides ul li button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 30px;
  font-family: "PoppinsMedium", sans-serif;
  height: 100%;
  width: 100%;
  color: #e2b259;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 30px;
  border: 2px solid #e2b259;
  transition: 0.2s ease;
}
@media (max-width: 768px) {
  .practical-guide-redirect-container .nav-guides ul li a, .practical-guide-redirect-container .nav-guides ul li button {
    font-size: 20px;
  }
}
.practical-guide-redirect-container .nav-guides ul li a:hover, .practical-guide-redirect-container .nav-guides ul li button:hover {
  color: #b1852d;
  border-color: #b1852d;
  background-color: rgba(0, 0, 0, 0.85);
  transform: scale(1.1);
}
.practical-guide-redirect-container .nav-guides ul li a i, .practical-guide-redirect-container .nav-guides ul li button i {
  color: linen;
  font-size: 5rem;
}
@media (max-width: 768px) {
  .practical-guide-redirect-container .nav-guides ul li a i, .practical-guide-redirect-container .nav-guides ul li button i {
    font-size: 3rem;
  }
}
.practical-guide-redirect-container .nav-guides ul li a img, .practical-guide-redirect-container .nav-guides ul li button img {
  margin: auto;
  width: 5rem;
  border-radius: 10px;
}
@media (max-width: 768px) {
  .practical-guide-redirect-container .nav-guides ul li a img, .practical-guide-redirect-container .nav-guides ul li button img {
    width: 3rem;
  }
}

.privacy-policy-container {
  background: url("../assets/images/background-earth.jpg") fixed center bottom/cover;
}
.privacy-policy-container .privacy-content-container {
  width: 90%;
  margin: 10px auto;
  color: linen;
  background: rgba(0, 0, 0, 0.85);
  border-radius: 20px;
  padding: 20px;
  border: 2px solid #e2b259;
}
.privacy-policy-container .privacy-content-container h1 {
  text-align: center;
  font-size: 3rem;
}
@media (max-width: 1024px) {
  .privacy-policy-container .privacy-content-container h1 {
    font-size: 2.2rem;
  }
}
@media (max-width: 768px) {
  .privacy-policy-container .privacy-content-container h1 {
    font-size: 1.5rem;
  }
}
.privacy-policy-container .privacy-content-container h2 {
  font-size: 2rem;
}
@media (max-width: 1024px) {
  .privacy-policy-container .privacy-content-container h2 {
    font-size: 1.5rem;
  }
}
@media (max-width: 768px) {
  .privacy-policy-container .privacy-content-container h2 {
    font-size: 1.1rem;
  }
}
.privacy-policy-container .privacy-content-container p {
  font-size: 1.2rem;
}
@media (max-width: 1024px) {
  .privacy-policy-container .privacy-content-container p {
    font-size: 1.1rem;
  }
}
@media (max-width: 768px) {
  .privacy-policy-container .privacy-content-container p {
    font-size: 1rem;
  }
}

.categories-list-container {
  background: black;
  min-height: 97vh;
}
.categories-list-container h1 {
  color: linen;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
}
.categories-list-container .categories-list {
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
}
@media (max-width: 1024px) {
  .categories-list-container .categories-list {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .categories-list-container .categories-list {
    width: 90%;
  }
}
.categories-list-container .category-searchbar-container {
  width: 70%;
  margin: 20px auto;
  padding: 10px;
  border: 2px solid linen;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e2b259;
}
@media (max-width: 1024px) {
  .categories-list-container .category-searchbar-container {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .categories-list-container .category-searchbar-container {
    width: 90%;
  }
}
.categories-list-container .category-searchbar-container input {
  width: 90%;
  height: 30px;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
.categories-list-container .category-searchbar-container input::placeholder {
  color: rgb(57, 57, 57);
}
.categories-list-container .category-searchbar-container input:hover {
  background: #b1852d;
}
.categories-list-container .category-searchbar-container input:focus {
  background: #b1852d;
}

.guided-tour-list-container {
  background: black;
  min-height: 97vh;
}
.guided-tour-list-container header {
  z-index: 10;
  position: relative;
}
.guided-tour-list-container h1 {
  color: linen;
  font-size: 2rem;
  text-align: center;
}
@media (max-width: 768px) {
  .guided-tour-list-container h1 {
    font-size: 1.5rem;
  }
}
.guided-tour-list-container .guided-tour-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 95%;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .guided-tour-list-container .guided-tour-list {
    grid-template-columns: 1fr;
  }
}
.guided-tour-list-container .guided-tour-list .guided-tour-list-entry a {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  border: 2px solid #e2b259;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.75);
  cursor: pointer;
  transition: 0.2s ease;
  position: relative;
}
.guided-tour-list-container .guided-tour-list .guided-tour-list-entry a:hover {
  background-color: rgba(0, 0, 0, 0.85);
  transform: scale(1.03);
}
.guided-tour-list-container .guided-tour-list .guided-tour-list-entry a:hover .distance {
  bottom: 10px;
  right: 20px;
}
.guided-tour-list-container .guided-tour-list .guided-tour-list-entry a h2 {
  color: linen;
  margin-left: 10%;
}
@media (max-width: 1024px) {
  .guided-tour-list-container .guided-tour-list .guided-tour-list-entry a h2 {
    font-size: 1rem;
  }
}
.guided-tour-list-container .guided-tour-list .guided-tour-list-entry a img {
  width: 200px;
  height: 75px;
  object-fit: cover;
  border-radius: 10px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .guided-tour-list-container .guided-tour-list .guided-tour-list-entry a img {
    width: 140px;
    height: 60px;
  }
}
.guided-tour-list-container .guided-tour-list .guided-tour-list-entry a .distance {
  position: absolute;
  align-self: end;
  justify-self: end;
  right: 15px;
}
.guided-tour-list-container .guided-tour-list .guided-tour-list-entry a .distance p {
  font-style: italic;
  color: linen;
  font-size: 1rem;
}
@media (max-width: 1024px) {
  .guided-tour-list-container .guided-tour-list .guided-tour-list-entry a .distance p {
    font-size: 0.8rem;
  }
}
.guided-tour-list-container .load-more-button {
  display: block;
  width: 200px;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 1rem;
  color: #e2b259;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid #e2b259;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  transition: 0.2s ease;
}
.guided-tour-list-container .load-more-button:hover {
  background-color: rgba(0, 0, 0, 0.85);
  transform: scale(1.05);
}
@media (max-width: 768px) {
  .guided-tour-list-container .load-more-button {
    width: 150px;
    font-size: 0.875rem;
  }
}

.guided-tour-container {
  background: black;
  min-height: 97vh;
}
.guided-tour-container h1 {
  color: linen;
  font-size: 2rem;
  text-align: center;
  margin: 10px 0;
}
@media (max-width: 768px) {
  .guided-tour-container h1 {
    font-size: 1.5rem;
  }
}
.guided-tour-container .guided-tour {
  width: 90%;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid linen;
  border-radius: 20px;
  padding: 10px;
  color: linen;
  text-align: center;
}
.guided-tour-container .guided-tour .infos-guided-tour img {
  width: 500px;
  height: 300px;
  border-radius: 10px;
  border: 2px solid #e2b259;
}
@media (max-width: 768px) {
  .guided-tour-container .guided-tour .infos-guided-tour img {
    width: 300px;
    height: 200px;
  }
}
.guided-tour-container .guided-tour .infos-guided-tour h2 {
  font-size: 3rem;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .guided-tour-container .guided-tour .infos-guided-tour h2 {
    font-size: 1.5rem;
  }
}
.guided-tour-container .guided-tour .infos-guided-tour a {
  background-color: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 10px;
  color: black;
  transition: 0.2s ease;
}
.guided-tour-container .guided-tour .infos-guided-tour a:hover {
  transform: scale(1.1);
}
.guided-tour-container .guided-tour .audio-guided-tour {
  margin-top: 40px;
}
.guided-tour-container .guided-tour .audio-guided-tour h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.guided-tour-container .guided-tour .audio-guided-tour p {
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.guided-tour-container .guided-tour .audio-guided-tour .languages-list {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}
.guided-tour-container .guided-tour .audio-guided-tour .languages-list img {
  width: 70px;
  height: 50px;
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 10px 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
.guided-tour-container .guided-tour .audio-guided-tour .languages-list img:hover {
  transform: scale(1.1);
}
.guided-tour-container .guided-tour .audio-guided-tour .languages-list img.selected {
  width: 100px;
  height: 70px;
  border: 2px solid #50b33a;
}
.guided-tour-container .guided-tour .audio-guided-tour .audio-selected-language {
  margin-bottom: 20px;
}
.guided-tour-container .guided-tour .audio-guided-tour .audio-selected-language button {
  background-color: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 10px;
  color: black;
  transition: 0.2s ease;
  width: 150px;
}
.guided-tour-container .guided-tour .audio-guided-tour .audio-selected-language button:hover {
  transform: scale(1.1);
}

.magazine-redirect-container {
  background: black;
  min-height: 97vh;
}
.magazine-redirect-container .logo-container {
  padding-top: 5vh;
  display: block;
}
.magazine-redirect-container .logo-container .logo {
  max-width: 700px;
}
.magazine-redirect-container h1 {
  color: linen;
  font-size: 3rem;
  text-align: center;
}
@media (max-width: 768px) {
  .magazine-redirect-container h1 {
    font-size: 2rem;
  }
}
.magazine-redirect-container .magazine-redirect ul {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: fit-content;
}
@media (max-width: 768px) {
  .magazine-redirect-container .magazine-redirect ul {
    grid-template-columns: 1fr 1fr;
  }
}
.magazine-redirect-container .magazine-redirect ul li {
  margin: 20px 80px;
  text-align: center;
}
@media (max-width: 768px) {
  .magazine-redirect-container .magazine-redirect ul li {
    margin: 10px 20px;
  }
}
.magazine-redirect-container .magazine-redirect ul li a {
  padding: 10px;
  font-size: 30px;
  font-family: "PoppinsMedium", sans-serif;
  height: 100%;
  color: #e2b259;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 30px;
  border: 2px solid #e2b259;
  transition: 0.2s ease;
}
@media (max-width: 768px) {
  .magazine-redirect-container .magazine-redirect ul li a {
    font-size: 20px;
  }
}
.magazine-redirect-container .magazine-redirect ul li a:hover {
  color: #b1852d;
  border-color: #b1852d;
  background-color: rgba(0, 0, 0, 0.85);
  transform: scale(1.1);
}
.magazine-redirect-container .magazine-redirect ul li a i {
  color: linen;
  font-size: 5rem;
}
@media (max-width: 768px) {
  .magazine-redirect-container .magazine-redirect ul li a i {
    font-size: 3rem;
  }
}

.magazines-container header {
  z-index: 10;
  position: relative;
}
.magazines-container h1 {
  color: linen;
  font-size: 2rem;
  text-align: center;
}
@media (max-width: 768px) {
  .magazines-container h1 {
    font-size: 1.5rem;
  }
}
.magazines-container section.magazine-overview {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.magazines-container section.magazine-overview .magazine {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 15px 0;
  padding: 10px;
  background-color: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  width: 80%;
  height: 150px;
  background-size: cover;
  background-position: center 60%;
  color: linen;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}
@media (max-width: 768px) {
  .magazines-container section.magazine-overview .magazine {
    width: 90%;
    height: 100px;
    font-size: 1.2rem;
  }
}
.magazines-container section.magazine-overview .magazine::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
.magazines-container section.magazine-overview .magazine .name-mag {
  display: flex;
  flex-direction: column;
}
.magazines-container section.magazine-overview .magazine .name-mag p {
  position: relative;
  z-index: 1;
}
@media (max-width: 600px) {
  .magazines-container section.magazine-overview .magazine .name-mag p {
    font-size: 1.1rem;
  }
}
@media (max-width: 390px) {
  .magazines-container section.magazine-overview .magazine .name-mag p {
    font-size: 0.9rem;
  }
}

.message-list-container {
  background: black;
  min-height: 97vh;
}
.message-list-container h1 {
  color: linen;
  font-size: 2rem;
  text-align: center;
}
@media (max-width: 768px) {
  .message-list-container h1 {
    font-size: 1.5rem;
  }
}
.message-list-container .message {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 95%;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .message-list-container .message {
    grid-template-columns: 1fr;
  }
}
.message-list-container .message .message-entry a {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #e2b259;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.75);
  cursor: pointer;
  transition: 0.2s ease;
}
.message-list-container .message .message-entry a .publication-date {
  position: absolute;
  bottom: 5px;
  left: 10px;
  color: linen;
  font-size: 0.8rem;
}
.message-list-container .message .message-entry a .read-more {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  color: linen;
  font-size: 0.9rem;
  text-decoration: none;
}
.message-list-container .message .message-entry a .read-more .arrow {
  margin-left: 5px;
  border: solid linen;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
}
.message-list-container .message .message-entry a h3 {
  color: linen;
  margin-left: 10%;
}
@media (max-width: 1024px) {
  .message-list-container .message .message-entry a h3 {
    font-size: 1rem;
  }
}
.message-list-container .message .message-entry a .message-content {
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: center;
  margin-bottom: 20px;
}
.message-list-container .message .message-entry a .message-content img {
  width: 100%;
  height: auto;
}
.message-list-container .message .message-entry a .message-content p {
  margin-left: 20px;
  color: linen;
}
@media (max-width: 400px) {
  .message-list-container .message .message-entry a .message-content p {
    font-size: 0.8rem;
  }
}
.message-list-container .message .message-entry a:hover {
  background-color: rgba(0, 0, 0, 0.85);
  transform: scale(1.03);
}
.message-list-container .message .message-entry a h2 {
  color: linen;
  margin-left: 10%;
}
@media (max-width: 1024px) {
  .message-list-container .message .message-entry a h2 {
    font-size: 1rem;
  }
}
.message-list-container .message .message-entry a img {
  width: 200px;
  height: 75px;
  object-fit: cover;
  border-radius: 10px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .message-list-container .message .message-entry a img {
    width: 140px;
    height: 60px;
  }
}
.message-list-container .load-more-button {
  display: block;
  width: 200px;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 1rem;
  color: #e2b259;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid #e2b259;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  transition: 0.2s ease;
}
.message-list-container .load-more-button:hover {
  background-color: rgba(0, 0, 0, 0.85);
  transform: scale(1.05);
}
@media (max-width: 768px) {
  .message-list-container .load-more-button {
    width: 150px;
    font-size: 0.875rem;
  }
}

.message-container {
  background: black;
  min-height: 97vh;
}
.message-container h1 {
  color: linen;
  font-size: 2rem;
  text-align: center;
  margin: 10px 0;
}
@media (max-width: 768px) {
  .message-container h1 {
    font-size: 1.5rem;
  }
}
.message-container .message {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid linen;
  border-radius: 20px;
  padding: 10px;
  color: linen;
  text-align: center;
}
.message-container .message .infos-message {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.message-container .message .infos-message h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .message-container .message .infos-message h3 {
    font-size: 1rem;
  }
}
.message-container .message .infos-message img {
  width: 300px;
  height: auto;
  border-radius: 10px;
  border: 2px solid #e2b259;
  transition: transform 0.3s ease;
}
.message-container .message .infos-message img.enlarged {
  transform: scale(1.5);
  z-index: 1000;
}
@media (max-width: 768px) {
  .message-container .message .infos-message img {
    width: 200px;
    height: auto;
  }
}
.message-container .message .content-message {
  margin-top: 20px;
}
.message-container .message .content-message p {
  margin: 0 20px 0 20px;
  text-align: justify;
}
@media (max-width: 768px) {
  .message-container .message .content-message p {
    font-size: 0.8rem;
  }
}
.message-container .message .content-message h4 {
  margin-top: 10px;
}
@media (max-width: 768px) {
  .message-container .message .content-message h4 {
    font-size: 1rem;
  }
}
.message-container .message .content-message ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}
.message-container .message .content-message ul li {
  margin: 0 10px;
}
.message-container .message .content-message ul li button {
  background-color: #e2b259;
  color: black;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 5px 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.2s ease;
}
.message-container .message .content-message ul li button:hover {
  background-color: #e2b259;
  transform: scale(1.1);
}
.message-container .message .message-uploads {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}
.message-container .message .message-uploads img {
  margin-bottom: 20px;
  width: 300px;
  height: auto;
  border-radius: 10px;
  border: 2px solid #e2b259;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.message-container .message .message-uploads img.enlarged {
  transform: scale(1.7);
  z-index: 1000;
}
@media (max-width: 768px) {
  .message-container .message .message-uploads img {
    width: 200px;
    height: auto;
  }
}

.interview-list-container {
  background: black;
  min-height: 97vh;
}
.interview-list-container h1 {
  color: linen;
  font-size: 2rem;
  text-align: center;
}
@media (max-width: 768px) {
  .interview-list-container h1 {
    font-size: 1.5rem;
  }
}
.interview-list-container .interview-list {
  width: 70%;
  margin: 0 auto;
}
.interview-list-container .interview-list a {
  color: #e2b259;
  display: flex;
  height: auto;
  margin: 20px 0;
  padding: 30px;
  border: 2px solid #e2b259;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.75);
  align-items: center;
  justify-content: space-around;
  text-align: center;
  transition: 0.2s ease;
}
.interview-list-container .interview-list a:hover {
  background-color: rgba(0, 0, 0, 0.85);
  transform: scale(1.03);
}
@media (max-width: 768px) {
  .interview-list-container .interview-list a {
    padding: 10px;
    margin: 10px 10% 0 10%;
    flex-direction: column;
  }
}
.interview-list-container .interview-list a img {
  padding: 5px;
  min-width: 200px;
  max-width: 200px;
  height: 75px;
  object-fit: contain;
  background-color: linen;
  border-radius: 10px;
}
@media (max-width: 768px) {
  .interview-list-container .interview-list a img {
    min-width: 150px;
    max-width: 150px;
    height: auto;
  }
}
.interview-list-container .load-more-button {
  display: block;
  width: 200px;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 1rem;
  color: #e2b259;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid #e2b259;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  transition: 0.2s ease;
}
.interview-list-container .load-more-button:hover {
  background-color: rgba(0, 0, 0, 0.85);
  transform: scale(1.05);
}
@media (max-width: 768px) {
  .interview-list-container .load-more-button {
    width: 150px;
    font-size: 0.875rem;
  }
}

.interview-container {
  background: black;
  min-height: 97vh;
}
.interview-container header {
  z-index: 99;
  position: relative;
}
.interview-container h1 {
  color: linen;
  font-size: 2rem;
  text-align: center;
}
@media (max-width: 768px) {
  .interview-container h1 {
    font-size: 1.5rem;
  }
}
.interview-container .interview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  margin: 10px auto;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid linen;
  border-radius: 20px;
  padding: 10px;
  color: linen;
  text-align: center;
}
.interview-container .interview .interview-information {
  display: flex;
}
@media (max-width: 1024px) {
  .interview-container .interview .interview-information {
    flex-direction: column;
    align-items: center;
  }
}
.interview-container .interview .interview-information .image-container {
  margin: 5%;
  width: 40%;
  height: auto;
  transition: transform 0.3s ease;
}
@media (max-width: 1024px) {
  .interview-container .interview .interview-information .image-container {
    width: 60%;
    margin: 0 auto;
  }
}
.interview-container .interview .interview-information .image-container img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}
@media (max-width: 768px) {
  .interview-container .interview .interview-information .image-container {
    width: 200px;
    height: auto;
  }
}
@media (max-width: 1024px) {
  .interview-container .interview .interview-information .image-container {
    width: 60%;
    margin: 0 auto;
  }
}
.interview-container .interview .interview-information .text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
  width: 60%;
  overflow: hidden;
}
@media (max-width: 1024px) {
  .interview-container .interview .interview-information .text-container {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 1024px) {
  .interview-container .interview .interview-information .text-container .text-information-container {
    width: 100%;
    text-align: center;
  }
}
.interview-container .interview .interview-information .text-container .text-information-container .text-name-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  font-size: 40px;
}
@media (max-width: 1024px) {
  .interview-container .interview .interview-information .text-container .text-information-container .text-name-container {
    font-size: 1.5rem;
  }
}
@media (max-width: 480px) {
  .interview-container .interview .interview-information .text-container .text-information-container .text-name-container {
    font-size: 1rem;
  }
}
.interview-container .interview .interview-information .text-container .text-information-container .text-job-container {
  font-size: 30px;
}
@media (max-width: 1024px) {
  .interview-container .interview .interview-information .text-container .text-information-container .text-job-container {
    margin-top: 30px;
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .interview-container .interview .interview-information .text-container .text-information-container .text-job-container {
    font-size: 0.8rem;
  }
}
.interview-container .interview .interview-information .text-container .text-description-container {
  font-size: 20px;
  margin-top: 5%;
  max-width: 80%;
}
@media (max-width: 1024px) {
  .interview-container .interview .interview-information .text-container .text-description-container {
    max-width: 100%;
  }
}
.interview-container .interview .interview-information .text-container .text-description-container .text-description {
  z-index: 1;
  text-align: justify;
  position: relative;
  margin-top: 20px;
  border: 2px solid white;
  border-radius: 20px;
  padding: 10px 10px 40px 10px;
  cursor: pointer;
  white-space: pre-wrap;
}
.interview-container .interview .interview-information .text-container .text-description-container .text-description .read-more {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  color: linen;
  font-size: 0.9rem;
  text-decoration: none;
}
.interview-container .interview .interview-information .text-container .text-description-container .text-description .read-more .arrow {
  margin-left: 5px;
  border: solid linen;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
}
.interview-container .interview .partner-information {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid linen;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
  color: linen;
}
.interview-container .interview .partner-information .partner-information-title {
  display: flex;
  align-items: center;
  gap: 90%;
}
@media (max-width: 1024px) {
  .interview-container .interview .partner-information .partner-information-title {
    gap: 10%;
  }
}
.interview-container .interview .partner-information .partner-information-title img {
  width: 100px;
  height: auto;
  border-radius: 10px;
}
.interview-container .interview .partner-information .partner-information-title .btn-interview {
  display: block;
  width: 100%;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #e2b259;
  color: black;
  border: none;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
}
.interview-container .interview .partner-information .partner-information-title .btn-interview:hover {
  background-color: red;
}
.interview-container .interview .partner-information .partner-information-content a {
  color: #e2b259;
  text-decoration: underline;
  font-style: italic;
}
.interview-container .interview .partner-information .partner-information-content a:hover {
  color: #b1852d;
}
@media (max-width: 1024px) {
  .interview-container .interview .partner-information {
    flex-direction: column;
  }
  .interview-container .interview .partner-information .partner-information-content {
    margin-top: 10px;
  }
}
.interview-container .interview .contact-currentPartner-container {
  background-color: rgba(0, 0, 0, 0.75);
  padding-top: 20px;
  border-radius: 10px;
  margin-top: 20px;
}
.interview-container .interview .contact-currentPartner-container .info-contact-currentPartner-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.interview-container .interview .contact-currentPartner-container .info-contact-currentPartner-container a {
  flex: 0 1 calc(16.66% - 20px);
  margin: 10px;
  box-sizing: border-box;
  color: #e2b259;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease;
}
.interview-container .interview .contact-currentPartner-container .info-contact-currentPartner-container a:hover {
  color: #b1852d;
}
@media (max-width: 768px) {
  .interview-container .interview .contact-currentPartner-container .info-contact-currentPartner-container a {
    font-size: 1.9rem;
    flex: 0 1 calc(33.33% - 20px);
  }
}
.interview-container .interview .contact-currentPartner-container .info-contact-currentPartner-container a img {
  border: none;
  background: none;
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  margin: 0;
  padding: 0;
}
@media (max-width: 768px) {
  .interview-container .interview .contact-currentPartner-container .info-contact-currentPartner-container a img {
    max-width: 25px;
    min-width: 25px;
    min-height: 25px;
    max-height: 25px;
  }
}

.statistiques-admin-container {
  background: url("../assets/images/background-earth.jpg") fixed center/cover;
  min-height: 95vh;
}
.statistiques-admin-container .admin-content-container {
  background-color: rgba(0, 0, 0, 0.85);
  color: linen;
  border: 2px solid linen;
  border-radius: 20px;
  width: 90%;
  margin: 10px auto;
}
.statistiques-admin-container .admin-content-container .admin-content h2 {
  font-size: 2rem;
  margin: 20px auto;
  padding: 10px;
  width: fit-content;
  color: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
}
.statistiques-admin-container .admin-content-container .admin-content .button-container {
  display: flex;
  justify-content: center;
  margin: 10px;
}
.statistiques-admin-container .admin-content-container .admin-content .btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e2b259;
  font-size: 1rem;
  padding: 10px;
  border: 2px solid linen;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.2s ease;
}

.news-admin-container {
  background: url("../assets/images/background-earth.jpg") fixed center/cover;
  min-height: 95vh;
}
.news-admin-container .admin-content-container {
  background-color: rgba(0, 0, 0, 0.85);
  color: linen;
  border: 2px solid linen;
  border-radius: 20px;
  width: 90%;
  margin: 10px auto;
}
.news-admin-container .admin-content-container {
  margin-bottom: 40px;
}
.news-admin-container .admin-content-container h2 {
  font-size: 2rem;
  margin: 20px auto;
  padding: 10px;
  width: fit-content;
  color: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
}
.news-admin-container .admin-content-container .selected {
  margin-left: 20%;
}
.news-admin-container .admin-content-container h3 {
  margin-top: 20px;
  text-align: center;
}
.news-admin-container .admin-content-container .news-admin-container {
  display: flex;
  flex-direction: column;
  height: 50vh;
}
.news-admin-container .admin-content-container .admin-content-container {
  display: flex;
  flex: 1;
}
.news-admin-container .admin-content-container .admin-content {
  display: flex;
  flex: 1;
  padding: 20px;
}
.news-admin-container .admin-content-container .form-and-news-list {
  display: flex;
  width: 100%;
}
.news-admin-container .admin-content-container .form-container {
  width: 100%;
  padding-left: 20px;
}
.news-admin-container .admin-content-container form .new-news-setup {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}
.news-admin-container .admin-content-container form .new-news-setup .info-new-news {
  height: 100%;
  display: flex;
  justify-content: space-around;
  width: auto;
}
.news-admin-container .admin-content-container form .new-news-setup .info-new-news .input-group {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 10px;
}
.news-admin-container .admin-content-container form .new-news-setup .info-new-news .input-group label {
  display: flex;
  align-items: flex-end;
  width: 80%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto 0;
}
.news-admin-container .admin-content-container form .new-news-setup .info-new-news .input-group p {
  text-align: center;
}
.news-admin-container .admin-content-container form .new-news-setup .info-new-news .input-group input, .news-admin-container .admin-content-container form .new-news-setup .info-new-news .input-group textarea {
  width: 80%;
  min-height: 30px;
  margin: 5px auto;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
.news-admin-container .admin-content-container form .new-news-setup .info-new-news .input-group input::placeholder, .news-admin-container .admin-content-container form .new-news-setup .info-new-news .input-group textarea::placeholder {
  color: rgb(57, 57, 57);
}
.news-admin-container .admin-content-container form .new-news-setup .info-new-news .input-group input:hover, .news-admin-container .admin-content-container form .new-news-setup .info-new-news .input-group textarea:hover {
  background: #b1852d;
}
.news-admin-container .admin-content-container form .new-news-setup .info-new-news .input-group input:focus, .news-admin-container .admin-content-container form .new-news-setup .info-new-news .input-group textarea:focus {
  background: #b1852d;
}
.news-admin-container .admin-content-container form .description-new-news {
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}
.news-admin-container .admin-content-container form .description-new-news label {
  margin-bottom: 5px;
  cursor: default;
  background: none;
  border: none;
  color: linen;
}
.news-admin-container .admin-content-container form .description-new-news label:hover {
  transform: none;
}
.news-admin-container .admin-content-container form .description-new-news textarea {
  flex: 1;
  width: 100%;
  height: 100%;
  resize: none;
  padding: 10px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
.news-admin-container .admin-content-container form .description-new-news textarea::placeholder {
  color: rgb(57, 57, 57);
}
.news-admin-container .admin-content-container form .description-new-news textarea:hover {
  background: #b1852d;
}
.news-admin-container .admin-content-container form .description-new-news textarea:focus {
  background: #b1852d;
}
.news-admin-container .admin-content-container form .new-news-content h3 {
  text-align: center;
}
.news-admin-container .admin-content-container form .new-news-content .new-news-content-container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.news-admin-container .admin-content-container form .new-news-content .new-news-content-container label {
  display: flex;
  align-items: center;
  padding: 7px;
  height: 50px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
.news-admin-container .admin-content-container form .new-news-content .new-news-content-container label:hover {
  background: #b1852d;
  transform: scale(1.1);
}
.news-admin-container .admin-content-container form .new-news-content .new-news-content-container .links-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.news-admin-container .admin-content-container form .new-news-content .new-news-content-container .links-section .link-input {
  display: flex;
  flex-direction: column;
  margin: 2px;
}
.news-admin-container .admin-content-container form .new-news-content .new-news-content-container .links-section .link-input label {
  background: none;
  color: linen;
  border: none;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 15%;
  margin: 20px auto 0;
  pointer-events: none;
  transition: none;
}
.news-admin-container .admin-content-container form .new-news-content .new-news-content-container .links-section .link-input input {
  width: 150%;
  height: 30%;
  margin: 5px auto;
  padding: 10px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
.news-admin-container .admin-content-container form .new-news-content .new-news-content-container .links-section .link-input input::placeholder {
  color: rgb(57, 57, 57);
}
.news-admin-container .admin-content-container form .new-news-content .new-news-content-container .links-section .link-input input:hover {
  background: #b1852d;
}
.news-admin-container .admin-content-container form .new-news-content .new-news-content-container .links-section .link-input input:focus {
  background: #b1852d;
}
.news-admin-container .admin-content-container form .uploaded-logo-photo {
  margin-left: 35px;
  display: flex;
  gap: 30px;
}
.news-admin-container .admin-content-container form .uploaded-logo-photo .uploaded-logo-photo-contain {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  max-width: 70%;
  margin-left: 15%;
}
.news-admin-container .admin-content-container form .uploaded-logo-photo .uploaded-logo-photo-contain .link-preview-img-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
}
.news-admin-container .admin-content-container form .uploaded-logo-photo img {
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 5px 0;
}
.news-admin-container .admin-content-container form .uploaded-logo-photo .link-preview {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid linen;
  border-radius: 20px;
  padding: 10px;
  margin-right: 20px;
}
.news-admin-container .admin-content-container form .uploaded-logo-photo .link-preview img {
  width: 100px;
  border-radius: 10px;
  border: none;
  margin: 5px 0;
}
.news-admin-container .admin-content-container form .uploaded-logo-photo .link-preview .link-preview-text {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.news-admin-container .admin-content-container form .uploaded-logo-photo .link-preview .link-preview-text h3 {
  margin: 0;
  font-size: 1.2rem;
  color: #e2b259;
}
.news-admin-container .admin-content-container form .uploaded-logo-photo .link-preview .link-preview-text p {
  margin: 5px 0 0;
  color: #e2b259;
}
.news-admin-container .admin-content-container form section.preview-video {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  padding: 20px;
  border: 2px solid #e2b259;
  border-radius: 10px;
  background-color: linen;
  width: 80%;
}
.news-admin-container .admin-content-container form section.preview-video iframe {
  width: 100%;
  max-width: 600px;
  height: 340px;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.news-admin-container .admin-content-container form input[type=submit]#valid {
  display: block;
  width: 50%;
  margin: 20px auto;
  background-color: #e2b259;
  color: black;
  font-size: large;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
.news-admin-container .admin-content-container form input[type=submit]#valid:hover {
  background-color: #b1852d;
  transform: scale(1.1);
}
.news-admin-container .admin-content-container form input[type=submit]#invalid {
  display: block;
  width: 50%;
  margin: 20px auto;
  background-color: black;
  color: linen;
  font-size: large;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 5px;
  cursor: not-allowed;
  transition: 0.2s ease;
}

.contact-container {
  background: black;
  min-height: 97vh;
  color: linen;
}
.contact-container .logo-container {
  padding-top: 5vh;
  display: block;
}
.contact-container .logo-container .logo {
  max-width: 700px;
}
.contact-container .contact {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 90%;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 30px;
  padding: 20px;
  margin-top: 20px;
  border: 2px solid #e2b259;
  text-align: center;
}
.contact-container .contact h1 {
  color: linen;
  font-size: 2rem;
  text-align: center;
}
.contact-container .contact .QRCode {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.contact-container .contact .QRCode img {
  max-width: 300px;
  border: 2px solid #e2b259;
  border-radius: 10px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.75);
}
@media (max-width: 768px) {
  .contact-container .contact .QRCode img {
    max-width: 250px;
  }
}
.contact-container .contact a {
  display: block;
  color: linen;
  font-size: 1.2rem;
}
.contact-container .contact a:hover {
  color: #e2b259;
}
.contact-container .contact a i {
  font-size: 2rem;
  transform: translate(0, 5px);
}

