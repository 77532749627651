.user-admin-container {
  @include setup-admin;

  .admin-content-container {
    .admin-content {
      h2 {
        font-size: 2rem;
        margin: 20px auto;
        padding: 10px;
        width: fit-content;
        color: $fontcolor1;
        border: $borderWhite;
        border-radius: 10px;
      }

      section.new-user {
        margin-bottom: 40px;

        form {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

          h3 {
            text-align: center;
            margin-bottom: 20px;
            color: $fontcolor1;
          }

          .info-new-user {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .info-personnels-new-user,
            .info-professionels-new-user {
              width: 48%;
              display: flex;
              flex-direction: column;

              input {
                width: 100%;
                min-height: 40px;
                margin: 10px 0;
                padding: 10px;
                background: $fontcolor1;
                border: 1px solid $white;
                border-radius: 5px;
                transition: 0.2s ease;

                &::placeholder {
                  color: $black;
                }

                &:hover {
                  background: $fontcolor1;
                }

                &:focus {
                  background: $fontcolor1;
                  outline: none;
                }
              }

              .uploaded-logo {
                margin-top: 20px;
                text-align: center;
              }

              .uploaded-logo img {
                max-width: 100%;
                height: auto;
                border: 2px solid #ccc;
                border-radius: 4px;
              }

              .button-label {
                display: flex;
                padding: 10px;
                margin: 10px auto;
                background-color: $fontcolor1;
                color: $black;
                border: 2px solid $white;
                border-radius: 10px;
                width: 50%;
                cursor: pointer;
                transition: background-color 0.2s ease;

                &:hover {
                  background-color: $fontcolor1H;
                }
              }
            }
          }

          input[type="submit"] {
            background-color: $fontcolor1;
            color: $black;
            font-size: large;
            border: 2px solid $white;
            border-radius: 10px;
            padding: 10px;
            cursor: pointer;
            transition: 0.2s ease;

            &:hover {
              background-color: $fontcolor1H;
              transform: scale(1.1);
            }
          }
        }
      }

      section.user-management {
        table {
          width: 95%;
          margin: 20px auto;

          &, th, td {
            color: $fontcolor1;
            border-collapse: collapse;
            border: 2px solid $white;
            padding: 5px;
            .deleted {
              background-color: red;
            }
            button {
              width: 100%;
              max-width: 200px;
              background-color: $fontcolor1;
              border: 1px solid $white;
              border-radius: 10px;
              cursor: pointer;
              &:hover {
                background-color: $fontcolor1H;
                color: $white;
              }
            }

          }
        }
      }

      button.btn-refresh {
        display: flex;
        align-items: center;
        position: absolute;
        right: 7%;
        transform: translate(0, -110%);
        padding: 7px;
        height: 50px;
        background: $fontcolor1;
        border: 2px solid $white;
        border-radius: 10px;
        color: $black;
        cursor: pointer;
        transition: 0.2s ease;
        &:hover {
          background: $fontcolor1H;
        }
        i {
          font-size: 1.5rem;
        }
      }
    }
  }
}