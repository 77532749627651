.home-admin-container {
  @include setup-admin;
  .admin-content-container {
    h2 {
      margin-top: 50px;
      font-size: 4rem;
      text-align: center;
      color: $white;
    }
  }
}