.interview-container {

  header {
    z-index: 99;
    position: relative;
  }

  h1 {
    color: $white;
    font-size: 2rem;
    text-align: center;
    @include respond-to(small) {
      font-size: 1.5rem;
    }
  }

  @include set-background-neutral;

  .interview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    margin: 10px auto;
    background-color: $bgiconscolor;
    border: $borderWhite;
    border-radius: 20px;
    padding: 10px;
    color: $white;
    text-align: center;

    .interview-information {
      display: flex;

      @include respond-to(medium) {
        flex-direction: column;
        align-items: center;
      }

      .image-container {
        margin: 5%;
        width: 40%;
        height: auto;
        transition: transform 0.3s ease;

        @include respond-to(medium) {
          width: 60%;
          margin: 0 auto;
        }

        img {
          width: 100%;
          height: auto;
          border-radius: 10px;
        }

        @include respond-to(small) {
          width: 200px;
          height: auto;
        }

        @include respond-to(medium) {
          width: 60%;
          margin: 0 auto;
        }
      }

      .text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 5%;
        width: 60%;
        overflow: hidden;

        @include respond-to(medium) {
          width: 100%;
          text-align: center;
        }

        .text-information-container {
          @include respond-to(medium) {
            width: 100%;
            text-align: center;
          }


          .text-name-container {
            display: flex;
            gap: 20px;
            justify-content: center;
            align-items: center;
            font-size: 40px;

            @include respond-to(medium) {
              font-size: 1.5rem;
            }

            @media (max-width: 480px) {
              font-size: 1rem;
            }
          }

          .text-job-container {
            font-size: 30px;

            @include respond-to(medium) {
              margin-top: 30px;
              font-size: 1.2rem;
            }

            @media (max-width: 480px) {
              font-size: 0.8rem;
            }
          }
        }

        .text-description-container {
          font-size: 20px;
          margin-top: 5%;
          max-width: 80%;

          @include respond-to(medium) {
            max-width: 100%;
          }

          .text-description {
            z-index: 1;
            text-align: justify;
            position: relative;
            margin-top: 20px;
            border: 2px solid white;
            border-radius: 20px;
            padding: 10px 10px 40px 10px;
            cursor: pointer;
            white-space: pre-wrap;

            .read-more {
              position: absolute;
              bottom: 10px;
              right: 10px;
              display: flex;
              align-items: center;
              color: $white;
              font-size: 0.9rem;
              text-decoration: none;

              .arrow {
                margin-left: 5px;
                border: solid $white;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3px;
                transform: rotate(45deg);
              }
            }
          }
        }
      }
    }

    .partner-information {
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-color: $bgiconscolor;
      border: 2px solid $white;
      border-radius: 20px;
      padding: 20px;
      margin-top: 20px;
      text-align: center;
      color: $white;

      .partner-information-title {
        display: flex;
        align-items: center;
        gap: 90%;

        @include respond-to(medium) {
          gap: 10%;
        }
        img {
          width: 100px;
          height: auto;
          border-radius: 10px;
        }

        .btn-interview {
          display: block;
          width: 100%;
          margin: 20px auto;
          padding: 10px 20px;
          background-color: $fontcolor1;
          color: black;
          border: none;
          border-radius: 5px;
          text-align: center;
          text-decoration: none;
          font-size: 1rem;
          cursor: pointer;
          &:hover {
            background-color: red;
          }
        }
      }

      .partner-information-content {
        a {
          color: $fontcolor1;
          text-decoration: underline;
          font-style: italic;
          &:hover {
            color: $fontcolor1H;
          }
        }
      }

      @include respond-to(medium) {
        flex-direction: column;

        .partner-information-content {
          margin-top: 10px;
        }
      }
    }


    .contact-currentPartner-container {
      background-color: $bgiconscolor;
      padding-top: 20px;
      border-radius: 10px;
      margin-top: 20px;

      .info-contact-currentPartner-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        a {
          flex: 0 1 calc(16.66% - 20px);
          margin: 10px;
          box-sizing: border-box;
          color: $fontcolor1;
          font-size: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: color 0.2s ease;

          &:hover {
            color: $fontcolor1H;
          }

          @include respond-to(small) {
            font-size: 1.9rem;
            flex: 0 1 calc(33.33% - 20px);
          }

          img {
            border: none;
            background: none;
            min-width: 40px;
            max-width: 40px;
            min-height: 40px;
            max-height: 40px;
            margin: 0;
            padding: 0;

            @include respond-to(small) {
              max-width: 25px;
              min-width: 25px;
              min-height: 25px;
              max-height: 25px;
            }
          }
        }
      }
    }
  }
}
