.popup-city-update{
  .popup-content-city-update-container {
    background: $fontcolor1;
    border: $borderWhite;
    border-radius: 10px;
    padding: 20px;
    width: 70%;
    max-height: 95vh;
    overflow-y: auto;
    i {
      font-size: 2rem;
      color: $red;
      cursor: pointer;
      transition: 0.2s ease;
      &:hover {
        color: $red2;
      }
    }
    .popup-city-update-header {
      display: flex;
      justify-content: space-between;
      align-items: start;
      margin-bottom: 20px;
      h2 {
        font-size: 1.8rem;
      }
      .cancel-button {
        display: flex;
        align-items: center;
        color: $red;
        cursor: pointer;
        p {
          transition: 0.2s ease;
        }
        &:hover {
          p, i {
            color: $red2;
          }
        }
      }
    }
    form {
      .update-city-setup {
        .info-update-city {
          text-align: center;
          h3 {
            font-size: 1.5rem;
            margin-bottom: 15px;
          }
          .info-update-city-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-wrap: wrap;
            max-height: 60px;
            input {
              width: 23%;
              height: 30px;
              padding: 3px;
              background-color: $white;
              border: $borderBlack;
              border-radius: 10px;
              &:focus {
                outline: none;
              }
            }
          }
          .social-media-update-city{
            text-align: center;
            h3 {
              font-size: 1.5rem;
              margin: 23px 0 15px 0;
            }
            .social-media-update-city-container {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-around;
              align-items: center;
              input {
                width: 95%;
                height: 30px;
                padding: 3px;
                background-color: $white;
                border: $borderBlack;
                border-radius: 10px;
                &:focus {
                  outline: none;
                }
              }
              .social-media-update {
                width: 30%;
                display: flex;
                flex-direction: column;
                align-items: center;
              }
            }
          }
        }
      }
      .uploads-update-city {
        margin-top: 30px;
        .uploads-update-city-container {
          width: 95%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          .button-uploads-container {
            width: 80%;
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            label {
              display: flex;
              align-items: center;
              padding: 7px;
              height: 50px;
              background: $white;
              border: $borderBlack;
              border-radius: 10px;
              color: $black;
              cursor: pointer;
              transition: 0.2s ease;
              &:hover {
                transform: scale(1.1);
              }
            }
          }
          .render-uploads-update-city{
            display: flex;
            align-items: center;
            img {
              margin: 20px;
              max-width: 300px;
            }
            .city-logo {
              
              filter: contrast(0%);
            }
          }
        }
      }
      input[type="submit"]#valid {
        display: block;
        width: 50%;
        margin: 20px auto;
        background-color: $white;
        color: $black;
        font-size: large;
        border: $borderBlack;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
        transition: 0.2s ease;
        &:hover {
          transform: scale(1.1); 
        }
      }
      input[type="submit"]#invalid {
        display: block;
        width: 50%;
        margin: 20px auto;
        background-color: $black;
        color: $white;
        font-size: large;
        border: $borderBlack;
        border-radius: 10px;
        padding: 5px;
        cursor: not-allowed;
        transition: 0.2s ease;          
      }
    }
  }
}