.social-media-container {
  h1 {
    color: $white;
    font-size: 3rem;
    text-align: center;
    @include respond-to(small) {
      font-size: 2rem;
    }
  }
  @include set-background-neutral;
  .logo-container {
    padding-top: 5vh;
    display: block;
    .logo {
      max-width: 700px;
    }
  }
  .links-container {
    ul {
      width: 90%;
      margin: 100px auto 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      width: fit-content;
      @include respond-to(small) {
        margin-top: 8vh;
        grid-template-columns: 1fr 1fr;
      }
      li {
        margin: 20px 80px;
        text-align: center;
        @include respond-to(small) {
          margin: 10px 20px;
        }
        a{
          padding: 10px;
          font-size: 30px;
          font-family: $mainFont;
          height: 100%;
          color: $fontcolor1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          background-color: $bgiconscolor;
          border-radius: 30px;
          border: 2px solid $fontcolor1;
          transition: 0.2s ease;
          @include respond-to(small) {
            font-size: 20px;
          }
          &:hover {
            color: $fontcolor1H;
            border-color: $fontcolor1H;
            background-color: $bgiconscolorH;
            transform: scale(1.1);
          }
          i {
            color: $white;
            font-size: 5rem;
            @include respond-to(small) {
              font-size: 3rem;
            }
          }
        }
      }
    }
    p {
      text-align: center;
      font-size: 1.6rem;
      color: $white;
      padding: 20px;
      margin-top: 300px;
      background: $bgiconscolor;
    }
  }
}