.interview-list-container{
  h1 {
    color: $white;
    font-size: 2rem;
    text-align: center;
    @include respond-to(small) {
      font-size: 1.5rem;
    }
  }
  @include set-background-neutral;

  .interview-list {
    width:70%;
    margin: 0 auto;
    a {
      color: $fontcolor1;
      display: flex;
      height: auto;
      margin: 20px 0;
      padding: 30px;
      border: 2px solid $fontcolor1;
      border-radius: 20px;
      background-color: $bgiconscolor;
      align-items: center;
      justify-content: space-around;
      text-align: center;
      transition: 0.2s ease;

      &:hover {
        background-color: $bgiconscolorH;
        transform: scale(1.03);
      }

      @include respond-to(small) {
        padding: 10px;
        margin: 10px 10% 0 10%;
        flex-direction: column;
      }

      img {
        padding: 5px;
        min-width: 200px;
        max-width: 200px;
        height: 75px;
        object-fit: contain;
        background-color: $white;
        border-radius: 10px;
        @include respond-to(small) {
          min-width: 150px;
          max-width: 150px;
          height: auto;
        }
      }

    }
  }

  .load-more-button {
    display: block;
    width: 200px;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 1rem;
    color: $fontcolor1;
    background-color: $bgiconscolor;
    border: 2px solid $fontcolor1;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    transition: 0.2s ease;

    &:hover {
      background-color: $bgiconscolorH;
      transform: scale(1.05);
    }

    @include respond-to(small) {
      width: 150px;
      font-size: 0.875rem;
    }
  }
}