.statistiques-admin-container{
  @include setup-admin;
  .admin-content-container {
    .admin-content {
      h2 {
        font-size: 2rem;
        margin: 20px auto;
        padding: 10px;
        width: fit-content;
        color: $fontcolor1;
        border: 2px solid $white;
        border-radius: 10px;
      }
      .button-container {
        display: flex;
        justify-content: center;
        margin: 10px;
      }

      .btn-link {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e2b259;
        font-size: 1rem;
        padding: 10px;
        border: 2px solid linen;
        border-radius: 20px;
        cursor: pointer;
        transition: 0.2s ease;
      }
    }

  }
}